<template>
  <div id="StationRoom">
    <br />
    <h1 class="text2" style="color: #000000; margin-left: 10px; text-align: left;">
      <strong>{{ $route.query.name.toUpperCase() }}</strong>
    </h1>
    <div class="content">
      <b-card style="border: none; background-color: #F2F2F2">
        <b-row>
          <b-col md="12">
            <h4 class="my-1" style="color: #000000; text-align: left">
              <span><strong>{{ $t('actual_topic').toUpperCase() }}: </strong></span>
              <span v-if="station_room.actual_topic">{{ station_room.actual_topic }}</span>
              <span v-else>N/A</span>
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3" class="my-1">
            <b-button
              block
              pill
              @click="$router.push({ name: 'stations' })"
              style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec"
            >
              {{ $t('go_back').toUpperCase() }}
            </b-button>
          </b-col>
          <b-col md="6" class="my-1"> </b-col>
          <b-col md="3" class="my-1">
            <b-button
              block
              pill
              v-b-modal.modal-add
              style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec"
            >
              {{ $t('new_topic').toUpperCase() }}
              <font-awesome-icon
                style="text-align: right; color: white; font-size: 20px"
                icon="plus-circle"
              />
            </b-button>
          </b-col>
        </b-row>
        
        <br />

        <b-row v-if="!is_mobile">
          <b-card style="width: 100%; border-radius: 20px; background-color: #000000">
            <b-row v-if="!isBusy" align-v="center" style="height: 400px">
              <b-col lg="5" class="px-3">
                <b-row v-if="!isBusy" align-v="center" align-content="center">
                  <b-col lg="12" class="mb-2 text-center">
                    <img :src="station.logo" style="max-height: 150px" />
                  </b-col>
                  <b-col lg="12" class=" mt-2 text-center">
                    <audio
                      v-if="station.link"
                      controls
                      :src="station.link"
                      style="width: 100%"
                    >
                      {{ $t('browser_unsupport') }} <code>audio</code>.
                    </audio>
                  </b-col>
                  <br />
                </b-row>

                <b-row v-else align-v="center" align-content="center" align-h="center">
                  <b-spinner
                    class="m-1"
                    style="width: 3rem; height: 3rem; font-size: 20px; color: #6a71ec"
                  >
                  </b-spinner>
                </b-row>
              </b-col>

              <b-col lg="7" class="px-3" style="border-width: 1px; border-left-style: solid; border-color: #6a71ec">
                <b-row v-if="!isBusy2 && elements.length > 0">
                  <div id="msgs_view" class="mx-auto pr-4" style="overflow-y: auto; height: 360px" @scroll="HandleScroll">
                  <b-row v-for="(data, index) of elements" v-bind:key="data.id" align-v="center" class="ml-3 mr-2 mb-2" style="width: 100%">
                    <b-col md="2" v-if="data.id_user == id_user">
                      <font-awesome-icon
                        v-if="!data.delivered"
                        style="text-align: right; color: #6a71ec; font-size: 16px"
                        icon="clock"
                      />
                      <font-awesome-icon
                        v-else
                        style="cursor: pointer; text-align: right; color: #6a71ec; font-size: 16px"
                        icon="trash"
                        @click="Delete(data, index)"
                      />
                    </b-col>
                    <b-col md="10"
                      v-if="data.id_user == id_user"
                      style="border-radius: 10px; background-color: #E0E0E0">
                      <b-row class="py-1">
                        <b-col md="2" align-self="center">
                          <img :src="user.profile_picture" class="text-center" height="40px" style="aspect-ratio: 1; object-fit: cover; border-radius: 50%">
                        </b-col>
                        <b-col md="10" class="text-left">
                          <b-row>
                            <b-col md="4" class="text-left">
                              <p class="p-0 m-0" style="font-size: 12px">{{ $t('you') }}</p>
                            </b-col>
                            <b-col md="8" class="text-right">
                              <p v-if="language == 'es'" class="p-0 m-0" style="color: #000000; font-size: italic; font-size: 10px">
                                {{ moment.utc(data.created_at).local().format('D MMM YYYY, h:mm a') }}
                              </p>
                              <p v-else class="p-0 m-0" style="color: #000000; font-size: italic; font-size: 10px">
                                {{ moment.utc(data.created_at).local().format('MMM D YYYY, h:mm a') }}
                              </p>
                            </b-col>
                            <b-col md="12" class="text-left">
                              <span v-html="data.message"></span>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="10"
                      v-if="data.id_user != id_user"
                      style="border-radius: 10px; background-color: #E0E0E0">
                      <b-row class="py-1">
                        <b-col md="2" align-self="center">
                          <img :src="data.user.profile_picture" class="text-center" height="40px" style="aspect-ratio: 1; object-fit: cover; border-radius: 50%">
                        </b-col>
                        <b-col md="10" class="text-left">
                          <b-row>
                            <b-col md="6" class="text-left">
                              <p class="p-0 m-0" style="font-size: 12px">{{ data.user.username }}</p>
                            </b-col>
                            <b-col md="6" class="text-right">
                              <p v-if="language == 'es'" class="p-0 m-0" style="color: #000000; font-size: italic; font-size: 10px">
                                {{ moment.utc(data.created_at).local().format('D MMM YYYY, h:mm a') }}
                              </p>
                              <p v-else class="p-0 m-0" style="color: #000000; font-size: italic; font-size: 10px">
                                {{ moment.utc(data.created_at).local().format('MMM D YYYY, h:mm a') }}
                              </p>
                            </b-col>
                            <b-col md="12" class="text-left">
                              <span v-html="data.message"></span>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="2" v-if="data.id_user != id_user" class="text-left">
                      <font-awesome-icon
                        style="cursor: pointer; text-align: right; color: #6a71ec; font-size: 16px"
                        icon="reply"
                        @click="ReplyTo(data)"
                      />
                      <br>
                      <font-awesome-icon
                        style="cursor: pointer; text-align: right; color: #6a71ec; font-size: 16px"
                        icon="trash"
                        @click="Delete(data, index)"
                      />
                    </b-col>
                  </b-row>
                  </div>
                </b-row>
                
                <b-row v-if="isBusy2" align-v="center" style="height: 360px" align-h="center">
                  <b-spinner
                    class="m-1"
                    style="width: 3rem; height: 3rem; font-size: 20px; color: #6a71ec"
                  >
                  </b-spinner>
                </b-row>
                <b-row v-if="!isBusy2 && elements.length == 0" align-v="center" style="height: 360px" align-h="center">
                  <p style="color: #FFFFFF">{{ $t('no_messages_received') }}</p>
                </b-row>

                <b-row class="my-0 py-0">
                  <b-input-group class="ml-3 mr-2 px-0 my-0 py-0" style="width: 100%">
                    <b-form-input
                      id="msg_input"
                      type="text"
                      v-model="message"
                      :placeholder="$t('send_message')"
                      style="height: 40px; border-radius: 20px; color: #FFFFFF; background-color: #000000"
                      @keyup.enter="SendNewMessage"
                      required
                    >
                    </b-form-input>
                    <b-input-group-append>
                      <font-awesome-icon
                        class="mx-2"
                        style="height: 100%; text-align: right; color: #6a71ec; font-size: 25px; cursor: pointer"
                        icon="paper-plane"
                        @click="SendNewMessage"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-row>

        <b-row v-else>
          <b-card class="px-0 mx-0" style="width: 100%; border-radius: 20px; background-color: #000000">
            <b-row v-if="!isBusy" align-v="center" style="height: 700px">
              <b-col lg="5" class="px-3">
                <b-row v-if="!isBusy" align-v="center" align-content="center">
                  <b-col lg="12" class="mb-2 text-center">
                    <img :src="station.logo" style="max-height: 100px" />
                  </b-col>
                  <b-col lg="12" class=" mt-2 text-center">
                    <audio
                      v-if="station.link"
                      controls
                      :src="station.link"
                      style="width: 100%"
                    >
                      {{ $t('browser_unsupport') }} <code>audio</code>.
                    </audio>
                  </b-col>
                  <br />
                </b-row>

                <b-row v-else align-v="center" align-content="center" align-h="center">
                  <b-spinner
                    class="m-1"
                    style="width: 3rem; height: 3rem; font-size: 20px; color: #6a71ec"
                  >
                  </b-spinner>
                </b-row>
              </b-col>

              <b-col lg="7" class="px-1 mx-0 py-3" style="border-width: 1px; border-top-style: solid; border-color: #6a71ec">
                <b-row v-if="!isBusy2 && elements.length > 0" class="px-0 mx-0" style="width: 100%">
                  <div id="msgs_view" class="pr-4" style="width: 100%; overflow-y: auto; height: 475px" @scroll="HandleScroll">
                  <div v-for="(data, index) of elements" v-bind:key="data.id" class="ml-3 mr-2 mb-2" style="display: flex; width: 100%">
                    <div v-if="data.id_user == id_user" style="width: 100%">
                      <div class="mr-2" style="display: inline-block;">
                        <font-awesome-icon
                          v-if="!data.delivered"
                          style="text-align: right; color: #6a71ec; font-size: 16px"
                          icon="clock"
                        />
                        <font-awesome-icon
                          v-else
                          style="cursor: pointer; text-align: right; color: #6a71ec; font-size: 16px"
                          icon="trash"
                          @click="Delete(data, index)"
                        />
                      </div>
                      <div class="text-left py-2 px-2" style="display: inline-block; width: 90%; border-radius: 10px; background-color: #E0E0E0">
                        <div style="display: inline-block; width: 20%;">
                          <img :src="user.profile_picture" class="mr-2" height="40px" style="display: flex; aspect-ratio: 1; object-fit: cover; border-radius: 50%">
                        </div>
                        <div style="display: inline-block; width: 80%">
                          <p class="p-0 m-0 m-0 text-left" style="font-size: 12px">{{ $t('you') }}</p>
                          <span v-html="data.message"></span>
                          <p v-if="language == 'es'" class="p-0 m-0" style="color: #000000; font-size: italic; font-size: 10px">
                            {{ moment.utc(data.created_at).local().format('D MMM YYYY, h:mm a') }}
                          </p>
                          <p v-else class="p-0 m-0" style="color: #000000; font-size: italic; font-size: 10px">
                            {{ moment.utc(data.created_at).local().format('MMM D YYYY, h:mm a') }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div v-else style="width: 100%">
                      <div class="text-left py-2 px-2" style="display: inline-block; width: 90%; border-radius: 10px; background-color: #E0E0E0">
                        <div style="display: inline-block; width: 20%;">
                          <img :src="data.user.profile_picture" class="mr-2" height="40px" style="display: flex; aspect-ratio: 1; object-fit: cover; border-radius: 50%">
                        </div>
                        <div style="display: inline-block; width: 80%">
                          <p class="p-0 m-0 m-0 text-left" style="font-size: 12px">{{ data.user.username }}</p>
                          <span v-html="data.message"></span>
                          <p v-if="language == 'es'" class="p-0 m-0" style="color: #000000; font-size: italic; font-size: 10px">
                            {{ moment.utc(data.created_at).local().format('D MMM YYYY, h:mm a') }}
                          </p>
                          <p v-else class="p-0 m-0" style="color: #000000; font-size: italic; font-size: 10px">
                            {{ moment.utc(data.created_at).local().format('MMM D YYYY, h:mm a') }}
                          </p>
                        </div>
                      </div>
                      <div class="ml-2" style="display: inline-block;">
                        <font-awesome-icon
                          style="cursor: pointer; text-align: right; color: #6a71ec; font-size: 16px"
                          icon="reply"
                          @click="ReplyTo(data)"
                        />
                        <br>
                        <font-awesome-icon
                          style="cursor: pointer; text-align: right; color: #6a71ec; font-size: 16px"
                          icon="trash"
                          @click="Delete(data, index)"
                        />
                      </div>
                    </div>
                  </div>
                  </div>
                </b-row>
                
                <b-row v-if="isBusy2" align-v="center" style="height: 360px" align-h="center">
                  <b-spinner
                    class="m-1"
                    style="width: 3rem; height: 3rem; font-size: 20px; color: #6a71ec"
                  >
                  </b-spinner>
                </b-row>
                <b-row v-if="!isBusy2 && elements.length == 0" align-v="center" style="height: 360px" align-h="center">
                  <p style="color: #FFFFFF">{{ $t('no_messages_received') }}</p>
                </b-row>

                <b-row class="my-0 py-0">
                  <b-input-group class="ml-3 mr-2 px-0 my-0 py-0" style="width: 100%">
                    <b-form-input
                      id="msg_input"
                      type="text"
                      v-model="message"
                      :placeholder="$t('send_message')"
                      style="height: 40px; border-radius: 20px; color: #FFFFFF; background-color: #000000"
                      @keyup.enter="SendNewMessage"
                      required
                    >
                    </b-form-input>
                    <b-input-group-append>
                      <font-awesome-icon
                        class="mx-2"
                        style="height: 100%; text-align: right; color: #6a71ec; font-size: 25px; cursor: pointer"
                        icon="paper-plane"
                        @click="SendNewMessage"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-row>
      </b-card>
    </div>

    <b-modal
      id="modal-add"
      ref="modal"
      title-class="text-font-black"
      :title="$t('establish_new_topic')"
      modal-class="model-content"
      hide-footer
      content-class="shadow"
      @show="ResetModal"
      @hidden="ResetModal"
      size="md"
    >
      <b-container>
        <b-row align-v="center">
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('new_topic')+':'" class="mb-0">
              <b-form-input
                type="text"
                v-model="actual_topic"
                :placeholder="$t('new_topic')"
                style="border-radius: 20px; color: #000000;"
                required
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <br />
          <b-col lg="12">
            <b-button
              block
              pill
              v-show="!progress"
              class="my-3"
              :disabled="station_room.actual_topic == actual_topic || actual_topic == ''"
              @click="SetNewTopic()"
              style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec"
            >
              {{ $t('update').toUpperCase() }}
            </b-button>
          </b-col>
          <b-col>
            <div
              v-show="progress"
              class="text-center my-3"
              style="color: #6a71ec"
            >
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>

import $ from 'jquery'
import Cookies from 'js-cookie'
import { mapActions } from "vuex"
import Swal from "sweetalert2"
import moment from "moment"

export default {
  name: "StationRoom",

  data() {
    return {
      moment: moment,
      language: navigator.language.substring(0,2),
      id_role: Cookies.get('id_role'),
      id_user: Cookies.get('id_user'),
      actual_topic: '',
      message: '',
      saved_msg: '',
      station: {},
      user: {},
      station_room: {},
      element_url: "station_room_message",
      title_gender_letter: "",
      title_gender_letter2: "",
      progress: false,
      isBusy: true,
      isBusy2: true,
      scrolled: 'no',
      force: true,
      id: "",
      currentPage: 1,
      elements: [],
      screen_width: 1000,
      is_mobile: false,
    };
  },
  computed: {
    title_plural_uppercase() {
      return this.$i18n.t(this.element_url+'_plural').toUpperCase()
    },
    title_uppercase() {
      return this.$i18n.t(this.element_url).toUpperCase()
    },
    title_plural_midcase() {
      return this.$i18n.t(this.element_url+'_plural')
    },
    title_midcase() {
      return this.$i18n.t(this.element_url)
    },
    title_plural_lowcase() {
      return this.$i18n.t(this.element_url+'_plural').toLowerCase()
    },
    title_lowcase() {
      return this.$i18n.t(this.element_url).toLowerCase()
    }
  },
  mounted() {
    this.screen_width = $(window).width()

    if (this.screen_width < 500) {
      this.is_mobile = true
    }

    setInterval(() => {
      let msgs_view = document.getElementById('msgs_view')

      if (msgs_view && this.scrolled == 'no') {
        if (this.force || msgs_view.scrollTop >= msgs_view.scrollHeight) {
          msgs_view.scrollTo({ left: 0, top: msgs_view.scrollHeight, behavior: 'smooth' })
          this.force = false
        }
      }
    }, 500)

    this.moment.locale(this.language)
    this.FindElements()

    this.AllElements()

    this.sockets.subscribe('station_room_message', (data) => {
      if (data.id_user == this.id_user) {
        for (let i = this.elements.length - 1; i >= 0; i--) {
          this.elements[i].delivered = true
          break
        }
      }
      else {
        data.message = `<p class="p-0 m-0" style="font-size: 11px">${data.message}</p>`
        
        this.elements.push(data)
        this.elements[this.elements.length - 1].message = this.elements[this.elements.length - 1].message.replace(/#(\w+)/g, '<span style="color: #6a71ec">#$1</span>')
        this.elements[this.elements.length - 1].message = this.elements[this.elements.length - 1].message.replace(/@(\w+)/g, '<span style="color: #6a71ec">@$1</span>')
        this.force = true
      }
    })
  },

  sockets: {
    disconnect() {
      this.UserConnected()
    },
  },

  methods: {
    ...mapActions(["GetAll","ChangeStatus","Save","Update"]),
    HandleScroll(el) {
      if ((el.target.offsetHeight + el.target.scrollTop) >= el.target.scrollHeight - 100) {
        this.scrolled = 'no'
      }
      else {
        this.scrolled = 'yes'
      }
    },
    ResetModal() {
      this.actual_topic = this.station_room.actual_topic
    },
    FindElements() {
      this.isBusy = true
      
      let form = {
        element: 'station_room',
        query: {
          active: 1
        },
        columns: ['id_station'],
        params: {
          id_station: this.$route.params.id_station
        }
      };

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.isBusy = false
            this.station_room = response.data[0]
            this.station = response.data[0].station
            this.user = response.data[0].user_admin

            this.UserConnected()
          }
          else {
            this.isBusy = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.isBusy = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        })
    },
    AllElements() {
      this.isBusy2 = true
      
      let form = {
        element: this.element_url,
        query: {
          order_key: "id",
          order_value: "desc",
          limit: 30
        },
        columns: ['id_station_room'],
        params: {
          id_station_room: this.station_room.id
        },
      };

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.isBusy2 = false

            for (let i = response.data.length-1; i >= 0; i--) {
              response.data[i].delivered = true
              response.data[i].message = `<p class="p-0 m-0" style="font-size: 11px">${response.data[i].message}</p>`
              response.data[i].message = response.data[i].message.replace(/#(\w+)/g, '<span style="color: #6a71ec">#$1</span>')
              response.data[i].message = response.data[i].message.replace(/@(\w+)/g, '<span style="color: #6a71ec">@$1</span>')
              this.elements.push(response.data[i])
            }
          }
          else {
            this.isBusy2 = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            });
          }
        })
        .catch((error) => {
          console.log(error)
          this.isBusy2 = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    SetNewTopic() {
      const validation = this.actual_topic == ''

      if (validation) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_all_fields'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        })
        return
      }

      this.progress = true
      
      let form = {
        element: 'station_room',
        update: 'update',
        id: this.station_room.id,
        data: {
          actual_topic: this.actual_topic
        }
      }

      this.Update(form)
        .then((response) => {
          if (response.code == 200) {
            this.$nextTick(() => {
              this.$bvModal.hide("modal-add")
            })

            this.station_room.actual_topic = this.actual_topic
            this.progress = false
            
            Swal.fire({
              title: this.$i18n.t('actual_topic') + ' ' + this.$i18n.t('updated').toLowerCase(),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            })
          }
          else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    ReplyTo(data) {
      this.message = '@' + data.user.username + ' '
      document.getElementById('msg_input').focus()
      document.getElementById('msg_input').select()
    },
    SendNewMessage() {
      if (this.message == '')     return

      let utc_dt = new Date()
      let year = utc_dt.getUTCFullYear()
      let month = utc_dt.getUTCMonth() + 1 > 9 ? utc_dt.getUTCMonth() + 1 : '0' + (utc_dt.getUTCMonth() + 1)
      let day = utc_dt.getUTCDate() > 9 ? utc_dt.getUTCDate() : '0' + utc_dt.getUTCDate()
      let hour = utc_dt.getUTCHours() > 9 ? utc_dt.getUTCHours() : '0' + utc_dt.getUTCHours()
      let minute = utc_dt.getUTCMinutes() > 9 ? utc_dt.getUTCMinutes() : '0' + utc_dt.getUTCMinutes()
      let second = utc_dt.getUTCSeconds() > 9 ? utc_dt.getUTCSeconds() : '0' + utc_dt.getUTCSeconds()

      let elem_message = `<p class="p-0 m-0" style="font-size: 11px">${this.message}</p>`
      elem_message = elem_message.replace(/#(\w+)/g, '<span style="color: #6a71ec">#$1</span>')
      elem_message = elem_message.replace(/@(\w+)/g, '<span style="color: #6a71ec">@$1</span>')

      this.elements.push({
        id: this.elements.length > 0 ? this.elements[this.elements.length-1].id + 1 : 1,
        id_user: this.id_user,
        message: elem_message,
        delivered: false,
        user: {
          username: this.user.username,
          profile_picture: this.user.profile_picture
        },
        created_at: `${year}-${month}-${day} ${hour}:${minute}:${second}`
      })
      
      this.force = true
      this.saved_msg = this.message
      this.message = ''

      //Extract mentions
      let usernames = this.saved_msg.match(/\B@[a-z0-9_-]+/gi)
      if (!usernames)   usernames = []

      this.$socket.emit('message', {
        id_user: this.id_user,
        id_station_room: this.station_room.id,
        message: this.saved_msg,
        ids_tagged_users: usernames
      })
    },
    UserConnected() {
      this.$socket.emit('user_connected', { id_user: this.id_user })
      this.$socket.emit('join_room', { id_user: this.id_user, id_station_room: this.station_room.id })
    },
    Delete(data, index) {
      Swal.fire({
        title: this.$i18n.t('delete') + ' ' + this.title_lowcase,
        text: this.$i18n.t('delete_question'+this.title_gender_letter) + '?',
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: this.$i18n.t('delete'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = { element: this.element_url, id: data.id, status: 2 };

          this.ChangeStatus(form)
            .then((response) => {
              if (response.code == 200) {
                this.elements.splice(index, 1)

                Swal.fire({
                  title: this.title_midcase + ' ' + this.$i18n.t('deleted'+this.title_gender_letter).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                })
              }
              else {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                });
              }
            })
            .catch((error) => {
              console.log(error)
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              });
            });
        }
      });
    },
  },
};
</script>

<style>

.table thead th {
  cursor: pointer;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #6a71ec !important;
  border-color: #6a71ec !important;
}

.header,
.table thead th,
thead,
th {
  background-color: #6a71ec !important;
  color: #ffffff;
}

.selector-class .vs__search::placeholder,
.selector-class .vs__dropdown-toggle {
  border-radius: 20px;
  height: 40px;
}

.selector-class .vs__dropdown-menu {
  border-color: #000000;
  border-radius: 0px;
  max-height: 200px;
  overflow-y: auto;
}

.selector-class .vs__dropdown-option--highlight {
  background-color: #6a71ec;
}

</style>

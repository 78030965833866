<template>
  <div id="MasterCRUD">
    <br />
    <h1 class="text2" style="color: #000000; margin-left: 10px; text-align: left;">
      <strong>{{ title_plural_uppercase }}</strong>
    </h1>
    <div class="content">
      <b-card style="border: none; background-color: #F2F2F2">
        <b-row>
          <b-col md="12">
            <h4 class="my-1" style="color: #000000; text-align: left">
              <strong>TOTAL:</strong>
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3" class="my-1">
            <div class="py-1"
              style="box-shadow: 0px 10px 8px #B0B0B0; border-radius: 24px; width=100%; color: #FFFFFF; border-color: #6a71ec; background-color: #6a71ec">
              <span v-show="!isBusy" style="font-size: 24px;">{{ format_num(total) }}</span>
              <span v-show="isBusy" style="font-size: 24px;">--</span>
            </div>
          </b-col>
          <b-col md="3" class="my-1"> </b-col>
          <b-col md="3" class="my-1">
            <b-button block pill v-b-modal.modal-nearme
              style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              NEAR ME
              <font-awesome-icon style="text-align: right; color: white; font-size: 20px" icon="edit" />
            </b-button>
          </b-col>
          <b-col md="3" class="my-1">
            <b-button block pill v-b-modal.modal-add
              style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('add').toUpperCase() }}
              <font-awesome-icon style="text-align: right; color: white; font-size: 20px" icon="plus-circle" />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="4" class="my-1">
            <b-form-group label-for="categorySelect" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('country') + ':' }}</p>
              <v-select class="selector-class" v-model="id_country" :options="countries" label="text"
                @input="PageChanged" style="max-height: 100px; border-radius: 20px; color: #000000;">
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{ $t('no_results_for') }}: <em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group label-for="filterInput" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('searcher') + ':' }}</p>
              <b-input-group size="sm" style="box-shadow: 0px 10px 8px #B0B0B0;">
                <b-input-group-prepend>
                  <span class="input-group-text"
                    style="border-color: #000000; background-color: #E0E0E0; border-right: none;">
                    <font-awesome-icon @click="SearchInput" style="cursor: pointer; color: #000000; font-size: 17px"
                      icon="search" />
                  </span>
                </b-input-group-prepend>
                <b-form-input id="filterInput" v-model="filter" @keyup.enter="SearchInput" type="text"
                  :placeholder="$t('search')+'...'"
                  style="border-color: #000000; background-color: #E0E0E0; border-right: none; border-left: none;" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="ClearFilter" class="input-group-text"
                    style="border-color: #000000; background-color: #E0E0E0; border-left: none;">
                    <font-awesome-icon style="color: #000000; font-size: 17px" icon="times-circle" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group label-for="allPages" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('current_page') + ':' }}</p>
              <b-pagination id="allPages" v-model="currentPage" :total-rows="total" :per-page="8" size="sm" align="fill"
                @input="PageChanged" style="box-shadow: 0px 10px 8px #B0B0B0;"></b-pagination>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="!isBusy2" class="mt-3 px-3">
          <b-col sm="4" v-for="data of elementsTMP" v-bind:key="data.id" class="mb-3">
            <div v-if="data.active == 1"
              style="box-shadow: 0px 10px 15px #000000; border: none; border-radius: 20px; background-color: #6a71ec">
              <div class="text-center p-0"
                style="border: none; border-radius: 20px 20px 0px 0px; background-color: #000000">
                <button class="py-1" block disabled
                  style="width: 100%; border-radius: 20px; border: none; background-color: #000000">
                  <span style="color: #FFFFFF"><strong>{{ data.ordering }}</strong></span>
                  <span>
                    <button style="border: none; background-color: #000000">
                      <font-awesome-icon class="ml-0" @click="ChangeOrderTMP(data)"
                        style="color: #ffa500; font-size: 14px" icon="exchange-alt" />
                    </button>
                  </span>
                </button>
                <h6 class="py-1">
                  <strong style="color: #FFFFFF">{{
                  data.country ? data.country[language.substring(0,2)+'_name'] : $t('all')
                  }}</strong>
                </h6>
              </div>

              <div class="text-center">
                <img class="my-2" :src="data.imgkit_url_picture"
                  style="box-shadow: 0px 8px 10px #000000; border-radius: 50%; aspect-ratio: 1; object-fit: cover; background-color: #FFFFFF"
                  height="60px" alt="" />
              </div>

              <div class="text-center p-0 my-0">
                <h6 class="py-1 my-0">
                  <strong style="color: #FFFFFF">{{ data.name }}</strong>
                </h6>
              </div>

              <b-row class="ml-1 mr-1 my-0 py-2">
                <b-col md="4" class="mx-0 px-0">
                  <button class="py-1 my-1" @click="UpdateTMP(data)" block
                    style="width: 100%; border-radius: 20px; border: none; background-color: #6a71ec">
                    <font-awesome-icon style="color: #000000; font-size: 20px" icon="edit" />
                  </button>
                </b-col>
                <b-col md="4" class="mx-0 px-0">
                  <button class="mx-0 px-0 py-1 my-1" @click="ChangeActive(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #6a71ec">
                    <font-awesome-icon style="color: #000000; font-size: 20px" icon="check-square" />
                  </button>
                </b-col>
                <b-col md="4" class="mx-0 px-0">
                  <button class="py-1 my-1" @click="Delete(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #6a71ec">
                    <font-awesome-icon style="color: #000000; font-size: 20px" icon="trash" />
                  </button>
                </b-col>
              </b-row>

              <div class="text-center p-0"
                style="border: none; border-radius: 0px 0px 20px 20px; background-color: #000000">
                <span style="color: #FFFFFF">{{ format_num(data.users_connected) }}</span>
                <span>
                  <font-awesome-icon class="ml-2" style="color: #6a71ec; font-size: 14px" icon="users" />
                </span>
              </div>
            </div>

            <div v-else
              style="box-shadow: 0px 10px 15px #000000; border: none; border-radius: 20px; background-color: #B0B0B0">
              <div class="text-center p-0"
                style="border: none; border-radius: 20px 20px 0px 0px; background-color: #000000">
                <button class="py-1" block disabled
                  style="width: 100%; border-radius: 20px; border: none; background-color: #000000">
                  <span style="color: #FFFFFF"><strong>{{ data.ordering }}</strong></span>
                  <span>
                    <button style="border: none; background-color: #000000">
                      <font-awesome-icon class="ml-0" @click="ChangeOrderTMP(data)"
                        style="color: #ffa500; font-size: 14px" icon="exchange-alt" />
                    </button>
                  </span>
                </button>
                <h6 class="py-1">
                  <strong style="color: #FFFFFF">{{
                  data.country ? data.country[language.substring(0,2)+'_name'] : $t('all')
                  }}</strong>
                </h6>
              </div>

              <div class="text-center">
                <img class="my-2" :src="data.imgkit_url_picture"
                  style="box-shadow: 0px 8px 10px #000000; border-radius: 50%; aspect-ratio: 1; object-fit: cover; background-color: #FFFFFF"
                  height="60px" alt="" />
              </div>

              <div class="text-center p-0 my-0">
                <h6 class="py-1 my-0">
                  <strong style="color: #FFFFFF">{{ data.name }}</strong>
                </h6>
              </div>

              <b-row class="ml-1 mr-1 my-0 py-2">
                <b-col md="4" class="mx-0 px-0">
                  <button class="py-1 my-1" @click="UpdateTMP(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #B0B0B0">
                    <font-awesome-icon style="color: #000000; font-size: 20px" icon="edit" />
                  </button>
                </b-col>
                <b-col md="4" class="mx-0 px-0">
                  <button class="py-1 my-1" @click="ChangeActive(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #B0B0B0">
                    <font-awesome-icon style="color: #000000; font-size: 20px" icon="minus-square" />
                  </button>
                </b-col>
                <b-col md="4" class="mx-0 px-0">
                  <button class="py-1 my-1" @click="Delete(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #B0B0B0">
                    <font-awesome-icon style="color: #000000; font-size: 20px" icon="trash" />
                  </button>
                </b-col>
              </b-row>

              <div class="text-center p-0"
                style="border: none; border-radius: 0px 0px 20px 20px; background-color: #000000">
                <span style="color: #FFFFFF">{{ format_num(data.users_connected) }}</span>
                <span>
                  <font-awesome-icon class="ml-2" style="color: #6a71ec; font-size: 14px" icon="users" />
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="isBusy2" class="mt-3" align-h="center">
          <b-spinner class="m-1" style="width: 3rem; height: 3rem; font-size: 20px; color: #6a71ec">
          </b-spinner>
        </b-row>
        <b-row v-if="!isBusy2 && elementsTMP.length == 0" class="mt-3" align-h="center">
          <p>{{ $t('no_results') }}</p>
        </b-row>
      </b-card>
    </div>

    <b-modal id="modal-nearme" ref="modal" title-class="text-font-black" :title="$t('update')+ ' ' + title_lowcase + ' (Near me)'"
      modal-class="model-content" hide-footer content-class="shadow" @show="resetModal" @hidden="resetModal" size="md">
      <b-container>
        <b-row align-v="center">
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('picture')+':'" class="mb-0">
              <b-form-file v-model="fileToUpload" accept="image/*" :state="Boolean(fileToUpload)"
                v-on:change="onFileChange" :placeholder="$t('select_file')+'...'"
                :drop-placeholder="$t('drop_file')+'...'" :browse-text="$t('browse')">
                <img id="blah" src="#" alt="your image" />
              </b-form-file>

              <div v-if="fileToUpload.name" class="mt-3 text-center">
                <span v-if="fileToUpload.name">{{ $t('selected_image') + ':' }}</span>
                {{ fileToUpload ? fileToUpload.name : "" }}
                <br v-if="fileToUpload.name">
                <br v-if="fileToUpload.name">

                <img :src="preview_image" style="max-height: 150px; max-width: 100%" />
              </div>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('banner')+':'" class="mb-0">
              <b-form-file v-model="fileToUpload2" accept="image/*" :state="Boolean(fileToUpload2)"
                v-on:change="onFileChange2" :placeholder="$t('select_file')+'...'"
                :drop-placeholder="$t('drop_file')+'...'" :browse-text="$t('browse')">
                <img id="blah" src="#" alt="your image" />
              </b-form-file>

              <div v-if="fileToUpload2.name" class="mt-3 text-center">
                <span v-if="fileToUpload2.name">{{ $t('selected_image') + ':' }}</span>
                {{ fileToUpload2 ? fileToUpload2.name : "" }}
                <br v-if="fileToUpload2.name">
                <br v-if="fileToUpload2.name">

                <img :src="preview_image2" style="max-height: 150px; max-width: 100%" />
              </div>
            </b-form-group>
          </b-col>
          <br />
          <b-col lg="12">
            <b-button block pill v-show="!progress" class="my-3" :disabled="!fileToUpload.name || !fileToUpload2.name"
              @click="UpdateNearMe()"
              style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('update').toUpperCase() }}
            </b-button>
          </b-col>
          <b-col>
            <div v-show="progress" class="text-center my-3" style="color: #6a71ec">
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal id="modal-add" ref="modal" title-class="text-font-black" :title="$t('add')+ ' ' + title_lowcase"
      modal-class="model-content" hide-footer content-class="shadow" @show="resetModal" @hidden="resetModal" size="md">
      <b-container>
        <b-row align-v="center">
          <b-col lg="6" class="mb-2">
            <b-form-group :label="$t('country')+':'" class="mb-0">
              <v-select class="selector-class" v-model="element_add.id_country" :options="countries" label="text"
                style="max-height: 100px; border-radius: 20px; color: #000000;">
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{ $t('no_results_for') }}: <em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" class="mb-2">
            <b-form-group :label="$t('admin')+':'" class="mb-0" style="max-height: 200px; ">
              <v-select class="selector-class" v-model="user" :options="users" @search="SearchUsers" label="username"
                style="max-height: 100px; border-radius: 20px; color: #000000;">
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{ $t('no_results_for') }}: <em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('name')+':'" class="mb-0">
              <b-form-input type="text" v-model="element_add.name" :placeholder="$t('name')"
                style="border-radius: 20px; color: #000000;" required @keyup.enter="SaveElement()">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('picture')+':'" class="mb-0">
              <b-form-file v-model="fileToUpload" accept="image/*" :state="Boolean(fileToUpload)"
                v-on:change="onFileChange" :placeholder="$t('select_file')+'...'"
                :drop-placeholder="$t('drop_file')+'...'" :browse-text="$t('browse')">
                <img id="blah" src="#" alt="your image" />
              </b-form-file>

              <div v-if="fileToUpload.name" class="mt-3 text-center">
                <span v-if="fileToUpload.name">{{ $t('selected_image') + ':' }}</span>
                {{ fileToUpload ? fileToUpload.name : "" }}
                <br v-if="fileToUpload.name">
                <br v-if="fileToUpload.name">

                <img :src="preview_image" style="max-height: 150px; max-width: 100%" />
              </div>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('banner')+':'" class="mb-0">
              <b-form-file v-model="fileToUpload2" accept="image/*" :state="Boolean(fileToUpload2)"
                v-on:change="onFileChange2" :placeholder="$t('select_file')+'...'"
                :drop-placeholder="$t('drop_file')+'...'" :browse-text="$t('browse')">
                <img id="blah" src="#" alt="your image" />
              </b-form-file>

              <div v-if="fileToUpload2.name" class="mt-3 text-center">
                <span v-if="fileToUpload2.name">{{ $t('selected_image') + ':' }}</span>
                {{ fileToUpload2 ? fileToUpload2.name : "" }}
                <br v-if="fileToUpload2.name">
                <br v-if="fileToUpload2.name">

                <img :src="preview_image2" style="max-height: 150px; max-width: 100%" />
              </div>
            </b-form-group>
          </b-col>
          <br />
          <b-col lg="12">
            <b-button block pill v-show="!progress" class="my-3" :disabled="element_add == '' || element_add == ''"
              @click="SaveElement()"
              style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('add').toUpperCase() }}
            </b-button>
          </b-col>
          <b-col>
            <div v-show="progress" class="text-center my-3" style="color: #6a71ec">
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal id="modal-order" ref="modal" title-class="text-font-black" :title="$t('change_order')"
      modal-class="model-content" hide-footer content-class="shadow" @hidden="resetModal" size="sm">
      <b-container>
        <b-row align-v="center">
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('order')+':'" class="mb-0" style="max-height: 200px;">
              <v-select class="selector-class" v-model="element_update.ordering" :options="orders" label="username"
                style="max-height: 100px; border-radius: 20px; color: #000000;">
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{ $t('no_results_for') }}: <em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <br />
          <b-col lg="12">
            <b-button block pill v-show="!progress" class="my-3" @click="ChangeOrder()"
              style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('update').toUpperCase() }}
            </b-button>
          </b-col>
          <b-col>
            <div v-show="progress" class="text-center my-3" style="color: #6a71ec">
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal id="modal-update" ref="modal" title-class="text-font-black" :title="$t('update') + ' ' + title_lowcase"
      modal-class="model-content" hide-footer content-class="shadow" @hidden="resetModal" size="md">
      <b-container>
        <b-row align-v="center">
          <b-col lg="6" class="mb-2">
            <b-form-group :label="$t('country')+':'" class="mb-0">
              <v-select class="selector-class" v-model="element_update.id_country" :options="countries" label="text"
                style="max-height: 100px; border-radius: 20px; color: #000000;">
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{ $t('no_results_for') }}: <em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" class="mb-2">
            <b-form-group :label="$t('admin')+':'" class="mb-0" style="max-height: 200px; ">
              <v-select class="selector-class" v-model="user" :options="users" @search="SearchUsers" label="username"
                style="max-height: 100px; border-radius: 20px; color: #000000;">
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{ $t('no_results_for') }}: <em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('name')+':'" class="mb-0">
              <b-form-input type="text" v-model="element_update.name" :placeholder="$t('name')"
                style="border-radius: 20px; color: #000000;" required @keyup.enter="UpdateElement()">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('picture')+':'" class="mb-0">
              <b-form-file v-model="fileToUploadUpdate" accept="image/*" :state="Boolean(fileToUploadUpdate)"
                v-on:change="onFileChange" :placeholder="$t('select_file')+'...'"
                :drop-placeholder="$t('drop_file')+'...'" :browse-text="$t('browse')">
                <img id="blah" src="#" alt="your image" />
              </b-form-file>
              <div class="mt-3 text-center">
                <span v-if="fileToUploadUpdate.name">{{ $t('selected_image') + ':' }}</span>
                {{ fileToUploadUpdate ? fileToUploadUpdate.name : "" }}
                <br v-if="fileToUploadUpdate.name">
                <br v-if="fileToUploadUpdate.name">

                <img v-if="fileToUploadUpdate.name" :src="preview_image" style="max-height: 150px; max-width: 100%" />
                <img v-else :src="element_update.imgkit_url_picture" style="max-height: 150px; max-width: 100%" />
              </div>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('banner')+':'" class="mb-0">
              <b-form-file v-model="fileToUploadUpdate2" accept="image/*" :state="Boolean(fileToUploadUpdate2)"
                v-on:change="onFileChange2" :placeholder="$t('select_file')+'...'"
                :drop-placeholder="$t('drop_file')+'...'" :browse-text="$t('browse')">
                <img id="blah" src="#" alt="your image" />
              </b-form-file>
              <div class="mt-3 text-center">
                <span v-if="fileToUploadUpdate2.name">{{ $t('selected_image') + ':' }}</span>
                {{ fileToUploadUpdate2 ? fileToUploadUpdate2.name : "" }}
                <br v-if="fileToUploadUpdate2.name">
                <br v-if="fileToUploadUpdate2.name">

                <img v-if="fileToUploadUpdate2.name" :src="preview_image2" style="max-height: 150px; max-width: 100%" />
                <img v-else :src="element_update.imgkit_url_banner" style="max-height: 150px; max-width: 100%" />
              </div>
            </b-form-group>
          </b-col>
          <br />
          <b-col lg="12">
            <b-button block pill v-show="!progress" class="my-3" @click="UpdateElement()"
              style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('update').toUpperCase() }}
            </b-button>
          </b-col>
          <b-col>
            <div v-show="progress" class="text-center my-3" style="color: #6a71ec">
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>

import Cookies from 'js-cookie'
import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "MasterElements",

  data() {
    return {
      language: navigator.language,
      id_user: Cookies.get('id_user'),
      element: {},
      element_url: "public_room",
      title_gender_letter: "2",
      title_gender_letter2: "2",
      element_add: {
        id_country: { value: 0, text: this.$i18n.t('all') }
      },
      element_update: {},
      progress: false,
      total: 0,
      isBusy: false,
      isBusy2: false,
      emptyHTML: `<div class="mt-2 text-center"><h6>${this.$i18n.t('no_elements')}</h6></div>`,
      emptyFilteredHTML: `<div class="mt-2 text-center"><h6>${this.$i18n.t('no_elements_query')}</h6></div>`,
      id: "",
      id_country: { value: 0, text: this.$i18n.t('all') },
      countries: [{ value: 0, text: this.$i18n.t('all') }],
      currentPage: 1,
      filter: null,
      user: null,
      users: [],
      usersTMP: [],
      orders: [],
      elements: [],
      elementsTMP: [],
      fileToUpload: [],
      fileToUploadUpdate:[],
      fileToUpload2: [],
      fileToUploadUpdate2: [],
      file_name: "",
      file_description: "",
      file: {
        name: "",
        description: "",
        url: "",
        format: "",
      },
      preview_image:'',
      preview_image2:'',
      file_name2: "",
      file_description2: "",
      file2: {
        name: "",
        description: "",
        url: "",
        format: "",
      },
    };
  },
  computed: {
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.total
    },
    title_plural_uppercase() {
      return this.$i18n.t(this.element_url+'_plural').toUpperCase()
    },
    title_uppercase() {
      return this.$i18n.t(this.element_url).toUpperCase()
    },
    title_plural_midcase() {
      return this.$i18n.t(this.element_url+'_plural')
    },
    title_midcase() {
      return this.$i18n.t(this.element_url)
    },
    title_plural_lowcase() {
      return this.$i18n.t(this.element_url+'_plural').toLowerCase()
    },
    title_lowcase() {
      return this.$i18n.t(this.element_url).toLowerCase()
    }
  },
  mounted() {
    this.AllCountries();
    this.AllUsers();
    this.AllElements();
  },
  methods: {
    ...mapActions(["GetAll", "ChangeStatus", "Save", "Update", "SaveFormData", "UpdateFormData","UpdateFormData2"]),
    format_num(num) {
      if (num >= 1000 && num < 1000000){
        return (num/1000).toFixed(2) + 'K'
      }
      else if(num >= 1000000){
        return (num/1000000).toFixed(2) + 'M'
      }
      else {
        return num
      }
    },
    onFileChange(e) {
      //When file input change, create a image preview
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      //Set image to preview
      //var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.preview_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onFileChange2(e) {
      //When file input change, create a image preview
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage2(files[0]);
    },
    createImage2(file) {
      //Set image to preview
      //var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.preview_image2 = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    resetModal() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-add");
      });
      this.user = null
      this.progress = false
      this.element = {}
      this.element_update = {}
      this.element_add = {
        id_country: { value: 0, text: this.$i18n.t('all') }
      }
      this.fileToUpload = []
      this.fileToUploadUpdate = []
      this.fileToUpload2 = []
      this.fileToUploadUpdate2 = []
      this.file_name = ""
      this.file_description = ""
      this.file = { name: "", description: "", url: "", format: "" }
      this.preview_image = ''
      this.file_name2 = ""
      this.file_description2 = ""
      this.file2 = { name: "", description: "", url: "", format: "" }
      this.preview_image2 = ''
    },
    ClearFilter() {
      this.filter = "";
      this.AllElements();
      return true;
    },
    SearchInput() {
      let filterTMP = this.filter;

      if (filterTMP) {
        //Ask for Users by Query (first 20)
        this.isBusy = true;

        let form = {
          element: "public_room",
          query: {
            query: filterTMP,
            order_key: "ordering",
            order_value: "asc",
            limit: 9,
          },
          columns: ['type','dashboard'],
          params: {
            type: 2,
            dashboard: 1
          }
        };

        if (this.id_country && this.id_country.value > 0) {
          form.columns.push("id_country")
          form.params["id_country"] = this.id_country.value
        }

        this.GetAll(form)
          .then((response) => {
            if (response.code == 200) {
              this.isBusy = false;
              this.elements = response.data;
              this.elementsTMP = response.data;
              this.total = response.data.length;
            }
            else {
              this.isBusy = false;
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.isBusy = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            });
          });
      } else {
        this.AllElements();
      }

      return true;
    },
    SearchUsers(query) {
      if (query && query.length == 0) {
        this.users = this.usersTMP
      }
      else {
        if (query.trim() != '') {
          let form = {
            element: 'user',
            query: {
              active: 1,
              query: query,
              order_key: 'username',
              order_value: 'asc',
              limit: 10
            },
            columns: ['dashboard'],
            params: {
              dashboard: 1
            }
          }

          this.GetAll(form)
            .then((response) => {

              if (response.code === 200) {
                this.users = response.data
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        else {
          this.users = this.usersTMP
        }
      }
    },
    OnFiltered(filteredItems) {
      this.total = filteredItems.length;
      this.currentPage = 1;
    },
    PageChanged() {
      this.AllElements(false);
    },
    AllUsers() {
      let form = {
        element: "user",
        query: {
          active: 1,
          order_key: "username",
          order_value: "asc",
          limit: 10
        },
        columns: ['dashboard'],
        params: {
          dashboard: 1
        }
      };

      this.GetAll(form)
        .then((response) => {

          if (response.code == 200) {
            this.usersTMP = response.data
            this.users = response.data
          }
          else {
            console.log(response);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    AllCountries() {
      let form = {
        element: 'master/country',
        query: {
          active: 1,
          order_key: this.language.substring(0,2) + '_name',
          order_value: 'asc'
        }
      }

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            for (let item of response.data) {
              this.countries.push({ value: item.id, text: item[this.language.substring(0,2) + '_name'] })
            }
          }
          else {
            console.log(response)
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          }
        })
        .catch((error) => {
          console.log(error)
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        });
    },
    AllElements(upd_total = true) {
      this.isBusy2 = true
      if (upd_total) this.isBusy = true

      let form = {
        element: this.element_url,
        query: {
          order_key: "ordering",
          order_value: "asc",
          page: this.currentPage,
          limit: 9
        },
        columns: ['type','dashboard'],
        params: {
          type: 2,
          dashboard: 1
        },
      };

      if (this.id_country && this.id_country.value > 0) {
        form.columns.push("id_country")
        form.params["id_country"] = this.id_country.value
      }

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.isBusy = false;
            this.isBusy2 = false;
            this.elements = response.data.data;
            this.elementsTMP = response.data.data;
            this.total = response.data.meta.total;

            this.orders = []
            for (let i = 1; i <= this.total; i++) { this.orders.push(i) }
          } else {
            console.log(response);
            this.isBusy = false;
            this.isBusy2 = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          this.isBusy2 = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    SaveElement() {
      const validation =
        !this.user ||
        !this.element_add.id_country ||
        !this.element_add.name

      if (validation) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_all_fields'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return
      }

      this.element_add.id_user_admin = this.user.id ? this.user.id : 0

      this.progress = true;
      let data = new FormData();
      data.append("id_country", this.element_add.id_country.value);
      data.append("id_user_admin", this.element_add.id_user_admin);
      data.append("name", this.element_add.name);
      data.append("url_picture", this.fileToUpload);
      data.append("url_banner", this.fileToUpload2);

      let form = { element: this.element_url, data: data};

      this.SaveFormData(form)
        .then((response) => {
          if (response.code == 200) {
            this.$nextTick(() => {
              this.$bvModal.hide("modal-add");
            });

            this.progress = false;
            this.AllElements();
            Swal.fire({
              title: this.title_midcase + ' ' + this.$i18n.t('added'+this.title_gender_letter).toLowerCase(),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title: this.$i18n.t('register_exists'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },

    ChangeOrderTMP(data) {
      this.element_update = {
        id: data.id,
        ordering: data.ordering,
      };
      this.element = data

      this.$nextTick(() => {
        this.$bvModal.show("modal-order");
      });
    },
    ChangeOrder() {
      const change = this.element_update.ordering != this.element.ordering

      if (!change) return

      const validation = !this.element_update.ordering

      if (validation) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_all_fields'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return;
      }

      this.progress = true

      let form = {
        element: this.element_url,
        id: this.element_update.id,
        update: "update",
        data: {
          ordering: this.element_update.ordering
        },
      }

      this.Update(form)
        .then((response) => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-order");
          });

          if (response.code == 200) {
            this.progress = false
            this.AllElements()
            Swal.fire({
              title:
                this.title_midcase + ' ' + this.$i18n.t('updated' + this.title_gender_letter),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title: this.$i18n.t('register_exists'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },

    UpdateTMP(data) {
      this.element_update = {
        id: data.id,
        id_country: { value: data.id_country, text: data.country ? data.country[this.language.substring(0,2)+'_name'] : this.$i18n.t('all') },
        id_user_admin: data.id_user_admin,
        name: data.name,
        imgkit_url_picture: data.imgkit_url_picture,
        imgkit_url_banner: data.imgkit_url_banner,
      };

      this.element = data
      this.user = this.element.user_admin
      this.users = [this.element.user_admin]

      //this.element = data
      this.$nextTick(() => {
        this.$bvModal.show("modal-update");
      });
    },
    UpdateNearMe() {
      const validation =
        !this.fileToUpload ||
        !this.fileToUpload2

      if (validation) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_all_fields'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return;
      }

      this.progress = true;
      let data_to_update = new FormData();
      data_to_update.append("url_picture", this.fileToUpload);
      data_to_update.append("url_banner", this.fileToUpload2);

      let form = {
        element: this.element_url,
        update: "nearme/update",
        data: data_to_update,
      };

      this.UpdateFormData2(form)
        .then((response) => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-nearme");
          });

          if (response.code == 200) {
            this.progress = false;
            Swal.fire({
              title:
                this.title_midcase + ' (Near me) ' + this.$i18n.t('updated' + this.title_gender_letter).toLowerCase(),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title:
                this.$i18n.t('register_exists'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    UpdateElement() {
      this.element_update.id_user_admin = this.user.id ? this.user.id : 0

      const change =
        this.element_update.id_country.value != this.element.id_country ||
        this.element_update.id_user_admin != this.element.id_user_admin ||
        this.element_update.name != this.element.name ||
        this.element_update.imgkit_url_picture != this.fileToUploadUpdate.url ||
        this.element_update.imgkit_url_banner != this.fileToUploadUpdate2.url;

      if (!change) {
        return;
      }

      const validation =
        !this.element_update.name ||
        !this.element_update.id_country

      if (validation) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_all_fields'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return;
      }

      this.progress = true;
      let data_to_update = new FormData();
      data_to_update.append("id_country", this.element_update.id_country.value);
      data_to_update.append("id_user_admin", this.element_update.id_user_admin);
      data_to_update.append("name", this.element_update.name);
      data_to_update.append("url_picture", this.fileToUploadUpdate);
      data_to_update.append("url_banner", this.fileToUploadUpdate2);
      
      let form = {
        element: this.element_url,
        id: this.element_update.id,
        update: "update",
        data: data_to_update,
      };

      this.UpdateFormData(form)
        .then((response) => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-update");
          });

          if (response.code == 200) {
            this.progress = false;
            this.AllElements();
            Swal.fire({
              title:
                this.title_midcase + ' ' + this.$i18n.t('updated'+this.title_gender_letter).toLowerCase(),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title:
                this.$i18n.t('register_exists'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    ChangeActive(data) {
      let active = data.active == 1 ? 0 : 1;
      let title =
        data.active == 1
          ? "deactivate"
          : "activate"
      let title2 =
        data.active == 1
          ? "deactivated" + this.title_gender_letter
          : "activated" + this.title_gender_letter
      let text =
        data.active == 1
          ? "deactivate_question" + this.title_gender_letter2
          : "activate_question" + this.title_gender_letter2
      Swal.fire({
        title: this.$i18n.t(title) + ' ' + this.title_lowcase,
        text: this.$i18n.t(text) + ' ' + this.title_lowcase + '?',
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: active == 0 ? this.$i18n.t('deactivate') : this.$i18n.t('activate'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = { element: this.element_url, id: data.id, status: active };

          this.ChangeStatus(form)
            .then((response) => {
              
              if (response.code == 200) {
                data.active = active;
                Swal.fire({
                  title: this.title_midcase + " " + this.$i18n.t(title2).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              } else {
                console.log(response);
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                });
              }
            })
            .catch((error) => {
              console.log(error);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              });
            });
        }
      });
    },
    Delete(data) {
      Swal.fire({
        title: this.$i18n.t('delete') + ' ' + this.title_lowcase,
        text: this.$i18n.t('delete_question'+this.title_gender_letter) + ' ' + this.title_lowcase + '? ' + this.$i18n.t('delete_questionx'+this.title_gender_letter),
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: this.$i18n.t('delete'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = { element: this.element_url, id: data.id, status: 2 };

          this.ChangeStatus(form)
            .then((response) => {
              if (response.code == 200) {
                this.AllElements();
                Swal.fire({
                  title: this.title_midcase + ' ' + this.$i18n.t('deleted'+this.title_gender_letter).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              } else {
                console.log(response);
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                });
              }
            })
            .catch((error) => {
              console.log(error);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              });
            });
        }
      });
    },
  },
};
</script>

<style>

.table thead th {
  cursor: pointer;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #6a71ec !important;
  border-color: #6a71ec !important;
}

.header,
.table thead th,
thead,
th {
  background-color: #6a71ec !important;
  color: #ffffff;
}

.selector-class .vs__search::placeholder,
.selector-class .vs__dropdown-toggle {
  border-radius: 20px;
  height: 40px;
}

.selector-class .vs__dropdown-menu {
  border-color: #000000;
  border-radius: 0px;
  max-height: 200px;
  overflow-y: auto;
}

.selector-class .vs__dropdown-option--highlight {
  background-color: #6a71ec;
}

</style>

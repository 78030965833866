<template>
  <div>
    <br />
    <div class="content" style="padding-bottom: 5px">
      <h1 class="text2" style="color: #000000; margin-left: 10px; text-align: left;"><strong>{{ $t('home').toUpperCase()
      }}</strong></h1>

      <b-row style="margin-right: 3px">
        <b-col md="5" class="my-1"></b-col>
        <b-col md="4" class="my-1">
          <b-button v-if="id_role == 1" block pill v-b-modal.modal-live
            style="margin-left: 10px; box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
            <font-awesome-icon style="margin-right: 3px; text-align: left; color: white; font-size: 20px"
              icon="video" />
            {{ $t('new_live').toUpperCase() }}
          </b-button>
        </b-col>
        <b-col md="3" class="my-1">
          <b-button block pill @click="LoadCounters"
            style="margin-left: 10px; box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
            <font-awesome-icon style="margin-right: 3px; text-align: left; color: white; font-size: 20px"
              icon="sync-alt" />
            {{ $t('refresh').toUpperCase() }}
          </b-button>
        </b-col>
      </b-row>

      <!--<b-row align-h="center">
        <b-col lg="12">
          <h2>¡Pronto verás acá algunas estadísticas de tu aplicación!</h2>
        </b-col>
      </b-row>-->

      <br>

      <!--ADMIN STUFF-->
      <b-row v-if="!waiting && id_role == 1" class="mb-3">
        <b-col class="text-center" md="3">
          <font-awesome-icon class="py-0 my-1" style="color: #ffa500; font-size: 50px" icon="user" />
          <p class="py-0 mb-1" style="color: #FFFFFF">
            <strong style="color: #000000">{{ $t('users').toUpperCase() }}</strong>
          </p>
          <p class="py-0 mb-2" style="font-size: 30px; color: #000000"> {{ format_num(counters.total_users) }} </p>
        </b-col>
        <b-col class="text-center" md="3">
          <font-awesome-icon class="py-0 my-1" style="color: #ffa500; font-size: 50px" icon="photo-video" />
          <p class="py-0 mb-1" style="color: #FFFFFF">
            <strong style="color: #000000">{{ $t('posts').toUpperCase() }}</strong>
          </p>
          <p class="py-0 mb-2" style="font-size: 30px; color: #000000"> {{ format_num(counters.total_posts) }} </p>
        </b-col>
        <b-col class="text-center" md="3">
          <font-awesome-icon class="py-0 my-1" style="color: #ffa500; font-size: 50px" icon="video" />
          <p class="py-0 mb-1" style="color: #FFFFFF">
            <strong style="color: #000000">{{ $t('videos').toUpperCase() }}</strong>
          </p>
          <p class="py-0 mb-2" style="font-size: 30px; color: #000000"> {{ format_num(counters.total_videos) }} </p>
        </b-col>
        <b-col class="text-center" md="3">
          <font-awesome-icon class="py-0 my-1" style="color: #ffa500; font-size: 50px" icon="eye" />
          <p class="py-0 mb-1" style="color: #FFFFFF">
            <strong style="color: #000000">{{ $t('views').toUpperCase() }}</strong>
          </p>
          <p class="py-0 mb-2" style="font-size: 30px; color: #000000"> {{ format_num(counters.total_views) }} </p>
        </b-col>
      </b-row>

      <b-row v-if="!waiting && id_role == 1" class="mb-3 mx-auto">
        <b-col md="12" class="px-0" style="border: none; border-radius: 20px; background-color: #E0E0E0">
          <div class="text-center p-0"
            style="border: none; border-radius: 20px 20px 0px 0px; background-color: #000000">
            <h5 class="py-2">
              <strong style="color: #FFFFFF">{{ $t('today_activity').toUpperCase() }}</strong>
            </h5>
          </div>

          <b-row class="mx-auto mb-2">
            <b-col class="text-center mx-auto" md="6">
              <p class="py-0 mb-0" style="font-size: 30px; color: #000000"> {{
                  format_num(counters.total_connected_users)
              }} </p>
              <p class="py-0 mb-0" style="color: #000000">{{ $t('users_connected').toUpperCase() }}</p>
            </b-col>

            <b-col class="text-center mx-auto" md="6">
              <p class="py-0 mb-0" style="font-size: 30px; color: #000000"> {{
                  format_num(counters.total_day_connected_users)
              }} </p>
              <p class="py-0 mb-0" style="color: #000000">{{ $t('users_connected_day').toUpperCase() }}</p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="!waiting && id_role == 1" class="mb-3 mx-auto">
        <b-col md="12" class="px-0" style="border: none; border-radius: 20px; background-color: #E0E0E0">
          <div class="text-center p-0"
            style="border: none; border-radius: 20px 20px 0px 0px; background-color: #000000">
            <h5 class="py-2">
              <strong style="color: #FFFFFF">{{ $t('growing').toUpperCase() }}</strong>
            </h5>
          </div>
          <b-row class="mx-auto mb-2">
            <b-col class="text-center mx-auto" md="2" v-for="data of growing" v-bind:key="data.month">
              <p class="py-0 mb-0" style="font-size: 30px; color: #000000"> +{{ format_num(data.users) }} </p>
              <p class="py-0 mb-0" style="color: #000000">{{ $t('users').toUpperCase() }}</p>
              <p class="py-0 mb-1" style="color: #6a71ec">{{ $t(data.month) }}</p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="!waiting && id_role == 1" class="mb-2 mx-auto">
        <b-col md="12" class="mr-auto px-0" style="border: none; border-radius: 20px; background-color: #E0E0E0">
          <div class="text-center p-0"
            style="border: none; border-radius: 20px 20px 0px 0px; background-color: #000000">
            <h5 class="py-2">
              <strong style="color: #FFFFFF">{{ $t('top_3').toUpperCase() }} - {{ $t('users').toUpperCase() }}</strong>
            </h5>
          </div>
          <b-row class="mx-auto mb-2">
            <b-col class="text-center mx-auto" md="4" v-for="data of top_3" v-bind:key="data.user.id">
              <b-row>
                <b-col md="12">
                  <img :src="data.user.profile_picture" height="90px"
                    style="border-radius: 50%; aspect-ratio: 1; object-fit: cover;">
                </b-col>
                <b-col md="12">
                  <p class="py-0 mb-0" style="color: #000000">{{ data.user.first_name + ' ' + data.user.last_name }}</p>
                  <p class="py-0 mb-2" style="color: #000000">{{ '@' + data.user.username }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <h6 class="mt-1" style="color: #6a71ec"><strong>{{ $t('followers').toUpperCase() }}</strong></h6>
                  <p class="py-0 mb-1" style="font-size: 24px; color: #000000">{{ format_num(data.user.followers) }}</p>
                </b-col>
                <b-col md="6">
                  <h6 class="mt-1" style="color: #6a71ec"><strong>{{ $t('posts').toUpperCase() }}</strong></h6>
                  <p class="py-0 mb-1" style="font-size: 24px; color: #000000">{{ format_num(data.posts) }}</p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!--STATION OWNER STUFF-->
      <b-row v-if="!waiting && id_role != 1" class="mb-3">
        <b-col class="text-center" md="4">
          <font-awesome-icon class="py-0 my-1" style="color: #6a71ec; font-size: 50px" icon="headphones" />
          <p class="py-0 mb-1" style="color: #FFFFFF">
            <strong style="color: #000000">{{ $t('stations').toUpperCase() }}</strong>
          </p>
          <p class="py-0 mb-2" style="font-size: 30px; color: #000000"> {{ format_num(counters.total_stations) }} </p>
        </b-col>
        <b-col class="text-center" md="4">
          <font-awesome-icon class="py-0 my-1" style="color: #6a71ec; font-size: 50px" icon="comment-alt" />
          <p class="py-0 mb-1" style="color: #FFFFFF">
            <strong style="color: #000000">{{ $t('messages').toUpperCase() }}</strong>
          </p>
          <p class="py-0 mb-2" style="font-size: 30px; color: #000000"> {{ format_num(counters.total_messages) }} </p>
        </b-col>
        <b-col class="text-center" md="4">
          <font-awesome-icon class="py-0 my-1" style="color: #6a71ec; font-size: 50px" icon="users" />
          <p class="py-0 mb-1" style="color: #FFFFFF">
            <strong style="color: #000000">{{ $t('tuned').toUpperCase() }}</strong>
          </p>
          <p class="py-0 mb-2" style="font-size: 30px; color: #000000"> {{ format_num(counters.total_listeners) }} </p>
        </b-col>
      </b-row>

      <b-row v-if="waiting" class="mt-3" align-h="center">
        <b-spinner class="m-1" style="width: 3rem; height: 3rem; font-size: 20px; color: #6a71ec" />
      </b-row>
    </div>

    <b-modal id="modal-live" ref="modal" title-class="text-font-black" :title="$t('new_live')"
      modal-class="model-content" hide-footer content-class="shadow" @show="resetModal" @hidden="resetModal" size="md">
      <b-container>
        <b-row align-v="center">
          <b-col lg="12" class="text-center">
            <img src="https://storage.googleapis.com/jevi-app/20211014_120004_DskKqC_logo.png" alt="" height="150px"
              style="border-radius: 50%">
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('country') + ':'" class="mb-0" style="max-height: 200px; ">
              <v-select class="selector-class" v-model="id_country" :options="countries" label="text"
                style="max-height: 100px; border-radius: 20px; color: #000000;">
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{ $t('no_results_for') }}: <em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group label="Link:" class="mb-0">
              <b-form-input type="text" v-model="element_add.url_video" :placeholder="$t('live_link')"
                style="border-radius: 20px; color: #000000;" required>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('description') + ':'" class="mb-0">
              <b-form-textarea type="text" v-model="element_add.text" :placeholder="$t('description')"
                style="border-radius: 20px; color: #000000;" :rows="2" required>
              </b-form-textarea>
            </b-form-group>
          </b-col>
          <br />
          <b-col lg="12">
            <b-button block pill v-show="!progress" class="my-3"
              :disabled="element_add.url_video == '' || element_add.text == ''" @click="SaveElement()"
              style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('publish').toUpperCase() }}
            </b-button>
          </b-col>
          <b-col>
            <div v-show="progress" class="text-center my-3" style="color: #6a71ec">
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import Cookies from "js-cookie"
import { mapState, mapActions } from "vuex"
import Swal from "sweetalert2"

export default {
  name: "Home",

  data() {
    return {
      language: navigator.language.substring(0, 2) == 'es' ? 'es' : 'en',
      id_country: { value: 0, text: this.$i18n.t('all') },
      element_add: {},
      waiting: true,
      progress: false,
      countries: [{ value: 0, text: this.$i18n.t('all') }],
      counters: {},
      growing: [],
      top_3: [],
      id_role: Cookies.get('id_role'),
      id_user: Cookies.get('id_user')
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.me,
    }),
  },
  mounted() {
    this.LoadCounters();
  },

  methods: {
    ...mapActions(["Get", "GetAll", "Save"]),

    format_num(num) {
      if (num >= 1000 && num < 1000000) {
        return (num / 1000).toFixed(2) + 'K'
      }
      else if (num >= 1000000) {
        return (num / 1000000).toFixed(2) + 'M'
      }
      else {
        return num
      }
    },

    resetModal() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-live")
      })
      this.progress = false
      this.element_add = {}
    },

    LoadCounters() {
      this.waiting = true

      if (this.id_role == 1) {
        let form = {
          element: 'master/country',
          query: {
            active: 1,
            order_key: this.language + '_name',
            order_value: 'asc'
          }
        }

        this.GetAll(form)
          .then((response) => {
            if (response.code == 200) {
              for (let item of response.data) {
                this.countries.push({ value: item.id, text: item[this.language + '_name'] })
              }
            }
            else {
              console.log(response)
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            }
          })
          .catch((error) => {
            console.log(error)
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          });

        this.Get('user/admin/counters')
          .then((response) => {
            if (response.code == 200) {
              this.counters = response.data.counters
              this.growing = response.data.growing
              this.growing = this.growing.reverse()
              this.top_3 = response.data.top_users
              this.waiting = false
            }
            else {
              console.log(response)
              this.waiting = false
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            }
          })
          .catch((error) => {
            console.log(error)
            this.waiting = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          });
      }
      else {
        this.Get('user/owner/counters?id_user=' + this.id_user)
          .then((response) => {
            if (response.code == 200) {
              this.counters = response.data.counters
              this.waiting = false
            }
            else {
              console.log(response)
              this.waiting = false
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            }
          })
          .catch((error) => {
            console.log(error)
            this.waiting = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          });
      }
    },

    SaveElement() {
      this.element_add.type = 2
      this.element_add.id_user = this.id_user
      this.element_add.url_picture = "https://storage.googleapis.com/jevi-app/20211014_120004_DskKqC_logo.png"

      const validation =
        !this.element_add.id_user ||
        !this.element_add.text ||
        !this.element_add.url_video ||
        !this.id_country

      if (validation) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_all_fields'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        })
        return
      }

      //Obtain hashtags
      this.element_add.hashtags = this.element_add.text.split(' ').filter(v => v.startsWith('#'))

      //Setup extra parameter
      this.element_add.going_live = 1
      this.element_add.id_country = this.id_country.value

      this.progress = true

      let form = {
        element: 'post',
        data: this.element_add
      };

      this.Save(form)
        .then((response) => {
          if (response.code == 200) {
            this.$nextTick(() => {
              this.$bvModal.hide("modal-live")
            })

            this.LoadCounters()
            this.progress = false

            Swal.fire({
              title: this.$i18n.t('live_published'),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          console.log(error)
          this.progress = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
  },
}

</script>

<style scope>
.cardstyle {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.cardstyle::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.2;
}

/* On mouse-over, add a deeper shadow */
.cardstyle:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.cardtitle {
  color: white;
  margin-top: 15px;
  margin-left: 15px;
  text-align: left;
  font-weight: 400;
  font-size: 25px;
}

.number {
  color: white;
  margin-right: 15px;
  text-align: right;
  font-weight: 600;
  font-size: 60px;
  text-shadow: 3px 3px 10px #4F4F4F;
}

.cardtitle2 {
  color: white;
  margin-top: 15px;
  text-align: center;
  font-weight: 400;
  font-size: 25px;
}

.selector-class .vs__search::placeholder,
.selector-class .vs__dropdown-toggle {
  border-radius: 20px;
  height: 40px;
}

.selector-class .vs__dropdown-menu {
  border-color: #000000;
  border-radius: 0px;
  max-height: 200px;
  overflow-y: auto;
}

.selector-class .vs__dropdown-option--highlight {
  background-color: #6a71ec;
}
</style>
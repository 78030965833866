import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index.js"
import Cookies from "js-cookie"
import Swal from "sweetalert2"

import Begin from '../views/Begin.vue'
import SideNavbar from '../components/containers/SideNavbar.vue'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import User from '../views/User.vue'
import UserVerification from '../views/UserVerification.vue'
import UserReport from '../views/UserReport.vue'
import PostReport from '../views/PostReport.vue'
import Station from '../views/Station.vue'
import AdBanner from '../views/AdBanner.vue'
import Highlight from '../views/Highlight.vue'

import AdBannerCategory from '../views/AdBannerCategory.vue'
import PublicRoom from '../views/PublicRoom.vue'
//import Poll from '../views/Poll.vue'
import StationRoom from '../views/StationRoom.vue'
import JeviConfig from '../views/JeviConfig.vue'
import MasterCRUD from '../views/MasterCRUD.vue'
import NotFound from "../views/NotFound"
import Page403 from "../views/403.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Begin',
    component: Begin
  },
  {
    path: '/home',
    name: 'sidenavbar',
    component: SideNavbar,
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home,
        meta: { requiresAuth: true, keepAlive: false }
      },
      {
        path: '/users',
        name: 'users',
        component: User,
        meta: { requiresAuth: true, permission: 1 }
      },
      {
        path: '/user_verifications',
        name: 'user_verifications',
        component: UserVerification,
        meta: { requiresAuth: true, permission: 1 }
      },
      {
        path: '/user_reports',
        name: 'user_reports',
        component: UserReport,
        meta: { requiresAuth: true, permission: 1 }
      },
      {
        path: '/post_reports',
        name: 'post_reports',
        component: PostReport,
        meta: { requiresAuth: true, permission: 1 }
      },
      {
        path: '/ad_banners',
        name: 'ad_banners',
        component: AdBanner,
        meta: { requiresAuth: true, permission: 1 }
      },
      {
        path: '/highlights',
        name: 'highlights',
        component: Highlight,
        meta: { requiresAuth: true, permission: 1 }
      },
      {
        path: '/ad_banner_categories',
        name: 'ad_banner_categories',
        component: AdBannerCategory,
        meta: { requiresAuth: true, permission: 1 }
      },
      {
        path: '/stations',
        name: 'stations',
        component: Station,
        meta: { requiresAuth: true }
      },
      {
        path: '/public_rooms',
        name: 'public_rooms',
        component: PublicRoom,
        meta: { requiresAuth: true, permission: 1 }
      },
      /*{
        path: '/polls',
        name: 'polls',
        component: Poll,
        meta: { requiresAuth: true, permission: 3 }
      },*/
      {
        path: '/stations/:id_station/chat',
        name: 'station_room',
        component: StationRoom,
        meta: { requiresAuth: true, permission: 2 }
      },
      {
        path: '/jevi_config',
        name: 'jevi_config',
        component: JeviConfig,
        meta: { requiresAuth: true, permission: 1 }
      },
      {
        path: '/masters/:element',
        name: 'masters_elements',
        component: MasterCRUD,
        meta: { requiresAuth: true, permission: 1 }
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: "/403",
    name: "403",
    meta: { requiresAuth: true },
    component: Page403,
  },
  {
    path: "*",
    meta: { requiresAuth: true, layout: "not-found-layout" },
    component: NotFound
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const accessToken = Cookies.get("access_token")
  const id_role = Cookies.get("id_role")
  //const create_polls = Cookies.get("create_polls")

  if (to.matched.some(record => record.meta.requiresAuth) && !store.state.status && !accessToken) {
    Swal.fire({
      title: 'Atention!',
      text: 'You must login to continue',
      icon: "warning",
      confirmButtonColor: '#6a71ec',
    })

    next("/login");
  }
  else if (store.state.status && accessToken && to.meta.permission == 0) {
    next()
  }
  else if (accessToken && to.meta.permission && to.meta.permission != id_role) {
    next('/403')
    //if (to.meta.permission == 3 && !create_polls) {
    //  next("/403")
    //}
    //else {
    //  next()
    //}
  }
  else {
    next()
  }
})

export default router

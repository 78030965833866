<template>
  <div id="MasterCRUD">
    <br />
    <h1 class="text2" style="color: #000000; margin-left: 10px; text-align: left;">
      <strong>{{ title_plural_uppercase }}</strong>
    </h1>
    <div class="content">
      <b-card style="border: none; background-color: #F2F2F2">
        <b-row>
          <b-col md="12">
            <h4 class="my-1" style="color: #000000; text-align: left">
              <strong>TOTAL:</strong>
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3" class="my-1">
            <div class="py-1"
              style="box-shadow: 0px 10px 8px #B0B0B0; border-radius: 24px; width=100%; color: #FFFFFF; border-color: #6a71ec; background-color: #6a71ec">
              <span v-show="!isBusy" style="font-size: 24px;">{{ format_num(total) }}</span>
              <span v-show="isBusy" style="font-size: 24px;">--</span>
            </div>
          </b-col>
          <b-col md="6" class="my-1"> </b-col>
          <b-col md="3" class="my-1">
            <b-button block pill v-b-modal.modal-add
              style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('add').toUpperCase() }}
              <font-awesome-icon style="text-align: right; color: white; font-size: 20px" icon="plus-circle" />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="2" class="my-1">
          
          </b-col>
          <b-col md="2" class="my-1">
           
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group label-for="filterInput" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('searcher') + ':' }}</p>
              <b-input-group size="sm" style="box-shadow: 0px 10px 8px #B0B0B0;">
                <b-input-group-prepend>
                  <span class="input-group-text"
                    style="border-color: #000000; background-color: #E0E0E0; border-right: none;">
                    <font-awesome-icon @click="SearchInput" style="cursor: pointer; color: #000000; font-size: 17px"
                      icon="search" />
                  </span>
                </b-input-group-prepend>
                <b-form-input id="filterInput" v-model="filter" @keyup.enter="SearchInput" type="text"
                  :placeholder="$t('search')+'...'"
                  style="border-color: #000000; background-color: #E0E0E0; border-right: none; border-left: none;" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="ClearFilter" class="input-group-text"
                    style="border-color: #000000; background-color: #E0E0E0; border-left: none;">
                    <font-awesome-icon style="color: #000000; font-size: 17px" icon="times-circle" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group label-for="allPages" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('current_page') + ':' }}</p>
              <b-pagination id="allPages" v-model="currentPage" :total-rows="total" :per-page="8" size="sm" align="fill"
                @input="PageChanged" style="box-shadow: 0px 10px 8px #B0B0B0;"></b-pagination>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="!isBusy2" class="mt-3 px-3">
          <b-table id="my-table" show-empty small fixed stacked="md" sticky-header="true" hover thead-class="header"
                :primary-key="id" :items="elementsTMP" :fields="field" :current-page="current_page" :per-page="0"
                :empty-html="emptyHTML" :empty-filtered-html="emptyFilteredHTML" :busy="isBusy">
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle" style="color: #6a71ec;"></b-spinner>
                    <strong> {{ $t('loading') }}...</strong>
                  </div>
                </template>

                <template v-slot:cell(username)="row">
                  <img :src="row.item.imgkit_profile_picture + '?tr=n-xsmall_thumb'" alt="" height="20px"
                    style="border-radius: 50%; aspect-ratio: 1; object-fit: cover;">
                  <span class="my-0 py-0">
                    {{ '@' + row.item.username }}{{ row.item.id == id_user ? ' (' + $t('you').toLowerCase() + ')' : ''
                    }}
                  </span>
                  <font-awesome-icon v-if="row.item.verified == 1" class="mr-1" icon="check-circle"
                    style="color: #6a71ec; font-size: 17px" />
                  <font-awesome-icon v-if="row.item.id_role == 3" class="mr-1" icon="star"
                    style="color: #6a71ec; font-size: 17px" />
                  <font-awesome-icon v-if="(row.item.content_creator == 1)" style="color: #6a71ec; font-size: 17px"
                    icon="paint-brush" />
                </template>

                <template v-slot:cell(followers)="row">
                  <p class="my-0 py-0">
                    {{ format_num(row.item.followers) }}
                  </p>
                </template>

                <template v-slot:cell(following)="row">
                  <p class="my-0 py-0">
                    {{ format_num(row.item.following) }}
                  </p>
                </template>

                <template v-slot:cell(member_since)="row">
                  <p class="my-0 py-0">
                    {{ language == 'es'
                        ? moment.utc(row.item.created_at).local().format('D MMM YYYY')
                        : moment.utc(row.item.created_at).local().format('MMM D, YYYY')
                    }}
                  </p>
                </template>

                <template v-slot:cell(actions)="row">
                  <b-button size="sm" variant="white"
                    :title="$t('edit')" @click="UpdateTMP(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="edit" />
                  </b-button>
                  <b-button v-if="row.item.active == 1" size="sm" variant="white"
                    :title="$t('deactivate')" @click="ChangeActive(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="check-square" />
                  </b-button>
                  <b-button v-if="row.item.active == 0" size="sm" variant="white"
                    :title="$t('activate')" @click="ChangeActive(row.item)">
                    <font-awesome-icon style="color: #000000; font-size: 17px" icon="minus-square" />
                  </b-button>

                

                  <b-button v-if="row.item.active == 1 && row.item.id_role == 2 && row.item.id != id_user" size="sm"
                    variant="white" class="ml-1" title="Premium" @click="MakePremium(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="star" />
                  </b-button>
                  <b-button v-if="row.item.active == 1 && row.item.id_role == 3 && row.item.id != id_user" size="sm"
                    variant="white" class="ml-1" title="Premium" @click="RemovePremium(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="ban" />
                  </b-button>

                
                </template>
              </b-table>
        </b-row>
        <b-row v-if="isBusy2" class="mt-3" align-h="center">
          <b-spinner class="m-1" style="width: 3rem; height: 3rem; font-size: 20px; color: #6a71ec">
          </b-spinner>
        </b-row>
        <b-row v-if="!isBusy2 && elementsTMP.length == 0" class="mt-3" align-h="center">
          <p>{{ $t('no_results') }}</p>
        </b-row>
      </b-card>
    </div>

    <b-modal id="modal-add" ref="modal" title-class="text-font-black" :title="$t('add')+ ' ' + title_lowcase"
      modal-class="model-content" hide-footer content-class="shadow" @show="resetModal" @hidden="resetModal" size="md">
      <b-container>
        <b-row align-v="center">
        
        
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('es_name')+':'" class="mb-0">
              <b-form-input type="text" v-model="element_add.es_name" :placeholder="$t('es_name')"
                style="border-radius: 20px; color: #000000;" required>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('en_name')+':'" class="mb-0">
              <b-form-input type="text" v-model="element_add.en_name" :placeholder="$t('en_name')"
                style="border-radius: 20px; color: #000000;" :rows="2" required>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('ordenation')+':'" class="mb-0">
              <b-form-input type="number" v-model="element_add.ordenation" :placeholder="$t('ordenation')"
                style="border-radius: 20px; color: #000000;" :rows="2" required>
              </b-form-input>
            </b-form-group>
          </b-col>
       
          <br />
          <b-col lg="12">
            <b-button block pill v-show="!progress" class="my-3" @click="SaveElement()"
              style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('save').toUpperCase() }}
            </b-button>
          </b-col>
          <b-col>
            <div v-show="progress" class="text-center my-3" style="color: #6a71ec">
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal id="modal-update" ref="modal" title-class="text-font-black" :title="$t('update') + ' ' + title_lowcase"
      modal-class="model-content" hide-footer content-class="shadow" @hidden="resetModal" size="md">
      <b-container>
        <b-row align-v="center">
          <!--<b-col lg="12" class="mb-2">
            <b-form-group :label="$t('country')+':'" class="mb-0">
              <b-form-select v-model="element_update.id_country" :options="countries"
                style="border-radius: 20px; color: #000000;" required>
              </b-form-select>
            </b-form-group>
          </b-col>-->
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('es_name')+':'" class="mb-0">
              <b-form-input type="text" v-model="element_update.es_name" :placeholder="$t('es_name')"
                style="border-radius: 20px; color: #000000;" required @keyup.enter="UpdateElement()">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('en_name')+':'" class="mb-0">
              <b-form-input type="text" v-model="element_update.en_name" :placeholder="$t('en_name')"
                style="border-radius: 20px; color: #000000;" required @keyup.enter="UpdateElement()">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('ordenation')+':'" class="mb-0">
              <b-form-input type="number" v-model="element_update.ordenation" :placeholder="$t('ordenation')"
                style="border-radius: 20px; color: #000000;" :rows="2" required>
              </b-form-input>
            </b-form-group>
          </b-col>
        
          <br />
          <b-col lg="12">
            <b-button block pill v-show="!progress" class="my-3" @click="UpdateElement()"
              style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('update').toUpperCase() }}
            </b-button>
          </b-col>
          <b-col>
            <div v-show="progress" class="text-center my-3" style="color: #6a71ec">
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

   
  </div>
</template>

<script>

import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "MasterElements",

  data() {
    return {
      language: navigator.language,
      element: {},
      element_url: "master/bannera_category",
      element_title: "ad_banner_category",
      title_gender_letter: "",
      title_gender_letter2: "",
      element_add: { 
        es_name: '', 
        en_name: '', 
        order: "1" },
      element_update: {},
      progress: false,
      total: 0,
      isBusy: false,
      isBusy2: false,
      emptyHTML: `<div class="mt-2 text-center"><h6>${this.$i18n.t('no_elements')}</h6></div>`,
      emptyFilteredHTML: `<div class="mt-2 text-center"><h6>${this.$i18n.t('no_elements_query')}</h6></div>`,
      id: "",
      id_country: '0',
      id_ad_banner_category: '0',
      countries: [{ text: this.$i18n.t('all'), value: '0' }],
      countriesx: [{ text: this.$i18n.t('select_one'), value: '' }],
      categories: [{ text: this.$i18n.t('all2'), value: '0' }],
      categoriesx: [{ text: this.$i18n.t('select_one2'), value: '' }],
      categoriesTMP: [{ text: this.$i18n.t('all2'), value: '0' }],
      aspect_ratios: [{ text: "16:9", value: "1.777777778" }, { text: "1:1", value: '1' }],
      orders: [],
      currentPage: 1,
      filter: null,
      elements: [],
      elementsTMP: [],
      fileToUpload: [],
      fileToUploadUpdate:[],
      file_name: "",
      file_description: "",
      file: {
        name: "",
        description: "",
        url: "",
        format: "",
      },
      field: [
        {
          key: "es_name",
          label: this.$i18n.t('es_name'),
          sortable: false,
          thStyle: { width: "30%" }
        },
        {
          key: "en_name",
          label: this.$i18n.t('en_name'),
          sortable: false,
        },
        {
          key: "ordenation",
          label: this.$i18n.t('ordenation'),
          sortable: false,
        },      
        {
          key: "actions",
          label: this.$i18n.t('actions'),
          sortable: false,
          thStyle: { width: "25%" }
        },
      ],
      per_page: 20,
      current_page: 1,
      page_options: [10, 20, 50, 100],
      preview_image:''
    };
  },
  computed: {
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.total
    },
    title_plural_uppercase() {
      return this.$i18n.t(this.element_title+'_plural').toUpperCase()
    },
    title_uppercase() {
      return this.$i18n.t(this.element_title).toUpperCase()
    },
    title_plural_midcase() {
      return this.$i18n.t(this.element_title+'_plural')
    },
    title_midcase() {
      return this.$i18n.t(this.element_title)
    },
    title_plural_lowcase() {
      return this.$i18n.t(this.element_title+'_plural').toLowerCase()
    },
    title_lowcase() {
      return this.$i18n.t(this.element_title).toLowerCase()
    }
  },
  mounted() {
    //this.AllCategories();
   // this.AllCountries();
    this.AllElements();
  },
  methods: {
    ...mapActions(["GetAll", "ChangeStatus", "Save", "Update","SaveFormData","UpdateFormData"]),
    format_num(num) {
      if (num >= 1000 && num < 1000000){
        return (num/1000).toFixed(2) + 'K'
      }
      else if(num >= 1000000){
        return (num/1000000).toFixed(2) + 'M'
      }
      else {
        return num
      }
    },
   
    onFileChange(e) {
      //When file input change, create a image preview
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      //Set image to preview
      //var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.preview_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    resetModal() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-add");
      });
      this.progress = false
      this.element = {}
      this.element_update = {}
      this.element_add= { 
        es_name: '', 
        en_name: '', 
        ordenation: "1" }
      this.fileToUpload = []
      this.fileToUploadUpdate = []
      this.file_name = ""
      this.file_description = ""
      this.file = { name: "", description: "", url: "", format: "" }
      this.preview_image = ''
    },
    ClearFilter() {
      this.filter = "";
      this.AllElements();
      return true;
    },
    SearchInput() {
      let filterTMP = this.filter;

      if (filterTMP) {
        //Ask for Users by Query (first 20)
        this.isBusy = true;

        let form = {
          element: "master/bannera_category",
          query: {
            query: filterTMP,
            order_key: "ordenation",
            order_value: "asc",
            limit: 9,
          },
          columns: [],
          params: {}
        };

        if (this.id_ad_banner_category > 0) {
          form.columns.push("id_ad_banner_category")
          form.params.id_ad_banner_category = this.id_ad_banner_category
        }

        this.GetAll(form)
          .then((response) => {
            if (response.code == 200) {
              this.isBusy = false;
              this.elements = response.data;
              this.elementsTMP = response.data;
              this.total = response.data.length;
            }
            else {
              this.isBusy = false;
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.isBusy = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            });
          });
      } else {
        this.AllElements();
      }

      return true;
    },
    OnFiltered(filteredItems) {
      this.total = filteredItems.length;
      this.currentPage = 1;
    },
    PageChanged() {
      this.AllElements(false);
    },
    AllCategories() {
      let form = {
        element: "master/bannera_category",
        query: {
          active: 1,
          order_key: 'ordenation',
          order_value: "asc",
          select: ['id',this.language.substring(0,2)+'_name']
        },
      };

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.categoriesTMP = [{ text: this.$i18n.t('all2'), value: '0' }, ...response.data.map((el) => ({ text: el[this.language.substring(0,2)+'_name'], value: el.id }))]
            this.categories = this.categoriesTMP
            this.categoriesx = [{ text: this.$i18n.t('select_one2'), value: '' }, ...this.categoriesTMP.slice(1)]
          }
          else {
            console.log(response);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    AllCountries() {
      let form = {
        element: "master/country",
        query: {
          active: 1,
          order_key: this.language.substring(0,2)+'_name',
          order_value: "asc",
          select: ['id',this.language.substring(0,2)+'_name']
        },
      };

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.countriesTMP = [{ text: this.$i18n.t('all2'), value: '0' }, ...response.data.map((el) => ({ text: el[this.language.substring(0,2)+'_name'], value: el.id }))]
            this.countries = this.countriesTMP
            this.countriesx = this.countriesTMP
          }
          else {
            console.log(response);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    AllElements(upd_total = true) {
      this.isBusy2 = true
      if (upd_total) this.isBusy = true

      let form = {
        element: this.element_url,
        query: {
          order_key: "ordenation",
          order_value: "asc",
          page: this.currentPage,
          limit: 10
        },
        columns: [],
        params: {},
      };

      if (this.id_country != '0') {
        form.columns.push("id_country")
        form.params["id_country"] = this.id_country
      }

      if (this.id_ad_banner_category != '0') {
        form.columns.push("id_bannera_category")
        form.params["id_bannera_category"] = this.id_ad_banner_category
      }

      this.GetAll(form)
        .then((response) => {

          if (response.code == 200) {
            this.isBusy = false;
            this.isBusy2 = false;
            this.elements = response.data.data;
            this.elementsTMP = response.data.data;
            this.total = response.data.meta.total;

            this.orders = []
            for (let i = 1; i <= this.total; i++)   {this.orders.push(i)}
          } else {
            console.log(response);
            this.isBusy = false;
            this.isBusy2 = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          this.isBusy2 = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    SaveElement() {
      const validation =
        !this.element_add.es_name ||
        !this.element_add.en_name ||
        !this.element_add.ordenation
       

      if (validation) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_all_fields'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return
      }

      this.progress = true;
      let data = this.element_add;
      

     
      let form = { element: this.element_url, data: data};

      
      this.SaveFormData(form)
        .then((response) => {
          
          if (response.code == 200) {
            this.$nextTick(() => {
              this.$bvModal.hide("modal-add");
            });

            this.progress = false;
            this.AllElements();
            Swal.fire({
              title: this.title_midcase + ' ' + this.$i18n.t('added'+this.title_gender_letter).toLowerCase(),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title: this.$i18n.t('register_exists'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    UpdateTMP(data) {

      console.log(data)
      this.element_update = {
        id: data.id,
        es_name: data.es_name,
        en_name: data.en_name,
        ordenation: data.ordenation,
      
      };
      this.element = data

      //this.element = data
      this.$nextTick(() => {
        this.$bvModal.show("modal-update");
      });
    },
    ChangeOrderTMP(data) {
      this.element_update = {
        id: data.id,
        ordering: data.ordering,
      };
      this.element = data

      this.$nextTick(() => {
        this.$bvModal.show("modal-order");
      });
    },
    UpdateElement() {
      const change =
        this.element_update.es_name != this.element.es_name ||
        this.element_update.en_name != this.element.en_name ||
        this.element_update.ordenation != this.element.ordenation 
       

      if (!change) {
        return;
      }

      const validation =
        !this.element_update.es_name ||
        !this.element_update.en_name ||
        !this.element_update.ordenation
     
      if (validation) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_all_fields'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return;
      }

      this.progress = true;
      let data_to_update = this.element_update

    
      let form = {
        element: this.element_url,
        id: this.element_update.id,
        update: "update",
        data: data_to_update,
      };

      this.UpdateFormData(form)
        .then((response) => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-update");
          });

          if (response.code == 200) {
            this.progress = false;
            this.AllElements();
            Swal.fire({
              title:
                this.title_midcase + ' ' + this.$i18n.t('updated'+this.title_gender_letter).toLowerCase(),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title:
                this.$i18n.t('register_exists'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    ChangeOrder() {
      const change = this.element_update.ordering != this.element.ordering
        
      if (!change)    return

      const validation = !this.element_update.ordering

      if (validation) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_all_fields'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return;
      }

      this.progress = true
      
      let form = {
        element: this.element_url,
        id: this.element_update.id,
        update: "update",
        data: {
          ordering: this.element_update.ordering
        },
      }

      this.Update(form)
        .then((response) => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-order");
          });

          if (response.code == 200) {
            this.progress = false
            this.AllElements()
            Swal.fire({
              title:
                this.title_midcase + ' ' + this.$i18n.t('updated'+this.title_gender_letter),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title: this.$i18n.t('register_exists'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    ChangeActive(data) {
      let active = data.active == 1 ? 0 : 1;
      let title =
        data.active == 1
          ? "deactivate"
          : "activate"
      let title2 =
        data.active == 1
          ? "deactivated" + this.title_gender_letter
          : "activated" + this.title_gender_letter
      let text =
        data.active == 1
          ? "deactivate_question" + this.title_gender_letter2
          : "activate_question" + this.title_gender_letter2
      Swal.fire({
        title: this.$i18n.t(title) + ' ' + this.title_lowcase,
        text: this.$i18n.t(text) + ' ' + this.title_lowcase + '?',
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: active == 0 ? this.$i18n.t('deactivate') : this.$i18n.t('activate'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = { element: this.element_url, id: data.id, status: active };

          this.ChangeStatus(form)
            .then((response) => {
              
              if (response.code == 200) {
                data.active = active;
                Swal.fire({
                  title: this.title_midcase + " " + this.$i18n.t(title2).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              } else {
                console.log(response);
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                });
              }
            })
            .catch((error) => {
              console.log(error);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              });
            });
        }
      });
    },
    Delete(data) {
      Swal.fire({
        title: this.$i18n.t('delete') + ' ' + this.title_lowcase,
        text: this.$i18n.t('delete_question'+this.title_gender_letter) + ' ' + this.title_lowcase + '? ' + this.$i18n.t('delete_questionx'+this.title_gender_letter),
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: this.$i18n.t('delete'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = { element: this.element_url, id: data.id, status: 2 };

          this.ChangeStatus(form)
            .then((response) => {
              if (response.code == 200) {
                this.AllElements();
                Swal.fire({
                  title: this.title_midcase + ' ' + this.$i18n.t('deleted'+this.title_gender_letter).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              } else {
                console.log(response);
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                });
              }
            })
            .catch((error) => {
              console.log(error);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              });
            });
        }
      });
    },
  },
};
</script>

<style>

.table thead th {
  cursor: pointer;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #6a71ec !important;
  border-color: #6a71ec !important;
}

.header,
.table thead th,
thead,
th {
  background-color: #6a71ec !important;
  color: #ffffff;
}

.selector-class .vs__search::placeholder,
.selector-class .vs__dropdown-toggle {
  border-radius: 20px;
  height: 40px;
}

.selector-class .vs__dropdown-menu {
  border-color: #000000;
  border-radius: 0px;
  max-height: 200px;
  overflow-y: auto;
}

.selector-class .vs__dropdown-option--highlight {
  background-color: #6a71ec;
}

</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Cookies from "js-cookie";
import axios from 'axios'
import VueAxios from 'vue-axios'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Fontawesome
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
require('./fontawesome');

Vue.use(BootstrapVue)         // Make BootstrapVue available throughout your project
Vue.use(VueAxios, axios)      // Use Axios por HTTP requests

import vSelect from "vue-select"
Vue.component("v-select", vSelect)

import "vue-select/dist/vue-select.css"

//Socket.io
import VueSocketIO from 'vue-socket.io'
import * as Config from "./config"

let opts = { transports: ['websocket'] }
let end = Config.apiPath.indexOf('/api/1.0')
let url = Config.apiPath.substring(0, end)

Vue.use(new VueSocketIO({
  debug: false,
  connection: url,
  vuex: {
    store,
    actionPrefix: "SOCKET_",
    mutationPrefix: "SOCKET_"
  },
  options: opts
}))

axios.interceptors.request.use(function(config) {
  config['headers'] = {      
   'Authorization': 'Bearer ' + Cookies.get('access_token'),
  };
  return config;
}, error => Promise.reject(error))

//Import and Use i18n
import VueI18n from "vue-i18n"
import es_json from "./i18n/es.json"
import en_json from "./i18n/en.json"

Vue.use(VueI18n)

const messages = {
  en: en_json,
  es: es_json,
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: navigator.language.substring(0,2) == 'es' ? 'es' : 'en',
  messages,
})
 
Vue.config.productionTip = false

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="content" style="height: 100vh; background-color: #F8F8F8">
    <br>
    <br>
    <br>
    <b-row class="mx-1">
      <b-col md="3"></b-col>
      <b-col md="6">
        <b-card style="background-color: #6a71ec; border-radius: 20px; color: #FFFFFF">
          <img src="../assets/logo5.png" alt="" class="mb-3" height="120px">
          <h1 class="text-center mt-0">{{ $t('access_forbidden') }}</h1>
          <br>
          <p style="font-size: 16px;">
            {{ $t('403_1') }}.
            {{ $t('403_2') }}
          </p>
            <b-col md="12 text-right">
            <b-button pill style="font-size: 20px; background-color: #000000; border-color: #000000" block @click="GoLogin">{{ $t('back_to_home') }}</b-button>
          </b-col>
        </b-card>
      </b-col>
      <b-col md="3"></b-col>
    </b-row>
  </div>
</template>

<script>

import Cookies from "js-cookie"

export default {
  name: 'NotPermission',

  methods: {
    GoLogin() {
      Cookies.remove('access_token')
      Cookies.remove('email')
      Cookies.remove('id_role')
      this.$router.push({ name: 'Login' })
    }
  }
}

</script>

<template>
  <div id="MasterCRUD">
    <br />
    <h1 class="text2" style="color: #000000; margin-left: 10px; text-align: left;">
      <strong>{{ title_plural_uppercase }}</strong>
    </h1>
    <div class="content">
      <b-card style="border: none; background-color: #F2F2F2">
        <b-row>
          <b-col md="12">
            <h4 class="my-1" style="color: #000000; text-align: left">
              <strong>TOTAL:</strong>
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3" class="my-1">
            <div class="py-1"
              style="box-shadow: 0px 10px 8px #B0B0B0; border-radius: 24px; width=100%; color: #FFFFFF; border-color: #6a71ec; background-color: #6a71ec">
              <span v-show="!isBusy" style="font-size: 24px;">{{ format_num(total) }}</span>
              <span v-show="isBusy" style="font-size: 24px;">--</span>
            </div>
          </b-col>
          <b-col md="6" class="my-1"> </b-col>
          <b-col md="3" class="my-1">
            <b-button block pill v-b-modal.modal-add
              style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('add').toUpperCase() }}
              <font-awesome-icon style="text-align: right; color: white; font-size: 20px" icon="plus-circle" />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <!-- <b-col md="1" class="my-1">
           <b-form-group label-for="countrySelect" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('country') + ':' }}</p>
              <b-form-select id="countrySelect" v-model="id_country" size="sm" :options="countries"
                @change="PageChanged"
                style="box-shadow: 0px 10px 8px #B0B0B0; border-color: #000000; background-color: #E0E0E0;" />
            </b-form-group>
          </b-col>-->
          <b-col md="4" class="my-1">
            <b-form-group label-for="categorySelect" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('category') + ':' }}</p>
              <b-form-select id="categorySelect" v-model="id_ad_banner_category" size="sm" :options="categories"
                @change="PageChanged"
                style="box-shadow: 0px 10px 8px #B0B0B0; border-color: #000000; background-color: #E0E0E0;" />
            </b-form-group>
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group label-for="filterInput" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('searcher') + ':' }}</p>
              <b-input-group size="sm" style="box-shadow: 0px 10px 8px #B0B0B0;">
                <b-input-group-prepend>
                  <span class="input-group-text"
                    style="border-color: #000000; background-color: #E0E0E0; border-right: none;">
                    <font-awesome-icon @click="SearchInput" style="cursor: pointer; color: #000000; font-size: 17px"
                      icon="search" />
                  </span>
                </b-input-group-prepend>
                <b-form-input id="filterInput" v-model="filter" @keyup.enter="SearchInput" type="text"
                  :placeholder="$t('search')+'...'"
                  style="border-color: #000000; background-color: #E0E0E0; border-right: none; border-left: none;" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="ClearFilter" class="input-group-text"
                    style="border-color: #000000; background-color: #E0E0E0; border-left: none;">
                    <font-awesome-icon style="color: #000000; font-size: 17px" icon="times-circle" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group label-for="allPages" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('current_page') + ':' }}</p>
              <b-pagination id="allPages" v-model="currentPage" :total-rows="total" :per-page="8" size="sm" align="fill"
                @input="PageChanged" style="box-shadow: 0px 10px 8px #B0B0B0;"></b-pagination>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="!isBusy2" class="mt-3 px-3">
          <b-col sm="6" v-for="data of elementsTMP" v-bind:key="data.id" class="mb-3">
            <div v-if="data.active == 1"
              style="box-shadow: 0px 10px 15px #000000; border: none; border-radius: 20px; background-color: #6a71ec">
              <div class="text-center p-0"
                style="border: none; border-radius: 20px 20px 0px 0px; background-color: #000000">
                <button block disabled
                  style="width: 100%; border-radius: 20px; border: none; background-color: #000000">
                  <span style="color: #FFFFFF"><strong>{{ data.ordering }}</strong></span>
                  <span>
                    <button style="border: none; background-color: #000000">
                      <font-awesome-icon class="ml-0" @click="ChangeOrderTMP(data)"
                        style="color: #ffa500; font-size: 14px" icon="exchange-alt" />
                    </button>
                  </span>
                </button>
                <p class=" my-0 pb-1">
                  <strong style="color: #FFFFFF">{{
                    (data.country ? data.country[language.substring(0,2)+'_name'] : $t('all')) + ' - ' +
                    (data.ad_banner_category ? data.ad_banner_category[language.substring(0,2)+'_name'] : 'Ninguna')
                    }}</strong>
                </p>
              </div>

              <div class="text-center">
                <a :href="data.link" target="_blank">
                  <img :src="data.url_banner"
                    style="aspect-ratio: 1; object-fit: cover; background-color: #FFFFFF; max-height: 150px"
                    width="100%" alt="" />
                </a>
              </div>

              <div class="text-center p-0 my-0">
                <p class="py-1 my-0" style="color: #FFFFFF">
                  {{ data.description.length > 50 ? data.description.substring(0,50) + '...' : data.description }}
                </p>
              </div>

              <b-row class="ml-1 mr-1 my-0 py-2">
                <b-col md="4" class="mx-0 px-0">
                  <button class="py-1 my-1" @click="UpdateTMP(data)" block
                    style="width: 100%; border-radius: 20px; border: none; background-color: #6a71ec">
                    <font-awesome-icon style="color: #000000; font-size: 20px" icon="edit" />
                  </button>
                </b-col>
                <b-col md="4" class="mx-0 px-0">
                  <button class="mx-0 px-0 py-1 my-1" @click="ChangeActive(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #6a71ec">
                    <font-awesome-icon style="color: #000000; font-size: 20px" icon="check-square" />
                  </button>
                </b-col>
                <b-col md="4" class="mx-0 px-0">
                  <button class="py-1 my-1" @click="Delete(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #6a71ec">
                    <font-awesome-icon style="color: #000000; font-size: 20px" icon="trash" />
                  </button>
                </b-col>
              </b-row>

              <div class="text-center p-0"
                style="border: none; border-radius: 0px 0px 20px 20px; background-color: #6a71ec">
                <button class="py-1" block
                  style="cursor: default; width: 100%; border-radius: 0px 0px 20px 20px; border: none; background-color: #000000"
                >
                  <span style="color: #FFFFFF"><strong>{{ format_num(data.clicks) }}</strong></span>
                  <span>
                    <font-awesome-icon class="ml-2" style="color: #ffa500; font-size: 14px" icon="users" />
                  </span>
                </button>
              </div>
            </div>

            <div v-else
              style="box-shadow: 0px 10px 15px #000000; border: none; border-radius: 20px; background-color: #B0B0B0">
              <div class="text-center p-0"
                style="border: none; border-radius: 20px 20px 0px 0px; background-color: #000000">
                <button block disabled
                  style="width: 100%; border-radius: 20px; border: none; background-color: #000000">
                  <span style="color: #FFFFFF"><strong>{{ data.ordering }}</strong></span>
                  <span>
                    <button style="border: none; background-color: #000000">
                      <font-awesome-icon class="ml-0" @click="ChangeOrderTMP(data)"
                        style="color: #ffa500; font-size: 14px" icon="exchange-alt" />
                    </button>
                  </span>
                </button>
                <p class="my-0 pb-1">
                  <strong style="color: #FFFFFF">{{
                    (data.country ? data.country[language.substring(0,2)+'_name'] : $t('all')) + ' - ' +
                    (data.ad_banner_category ? data.ad_banner_category[language.substring(0,2)+'_name'] : 'Ninguna')
                    }}</strong>
                </p>
              </div>

              <div class="text-center">
                <a :href="data.link" target="_blank">
                  <img :src="data.url_banner"
                    style="aspect-ratio: 1; object-fit: cover; background-color: #FFFFFF; max-height: 150px"
                    width="100%" alt="" />
                </a>
              </div>

              <div class="text-center p-0 my-0">
                <p class="py-1 my-0" style="color: #FFFFFF">
                  {{ data.description.length > 50 ? data.description.substring(0,50) + '...' : data.description }}
                </p>
              </div>

              <b-row class="ml-1 mr-1 my-0 py-2">
                <b-col md="4" class="mx-0 px-0">
                  <button class="py-1 my-1" @click="UpdateTMP(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #B0B0B0">
                    <font-awesome-icon style="color: #000000; font-size: 20px" icon="edit" />
                  </button>
                </b-col>
                <b-col md="4" class="mx-0 px-0">
                  <button class="py-1 my-1" @click="ChangeActive(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #B0B0B0">
                    <font-awesome-icon style="color: #000000; font-size: 20px" icon="minus-square" />
                  </button>
                </b-col>
                <b-col md="4" class="mx-0 px-0">
                  <button class="py-1 my-1" @click="Delete(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #B0B0B0">
                    <font-awesome-icon style="color: #000000; font-size: 20px" icon="trash" />
                  </button>
                </b-col>
              </b-row>

              <div class="text-center p-0"
                style="border: none; border-radius: 0px 0px 20px 20px; background-color: #000000">
                <button class="py-1" block
                  style="cursor: default; width: 100%; border-radius: 0px 0px 20px 20px; border: none; background-color: #000000"
                >
                  <span style="color: #FFFFFF"><strong>{{ format_num(data.clicks) }}</strong></span>
                  <span>
                    <font-awesome-icon class="ml-2" style="color: #ffa500; font-size: 14px" icon="users" />
                  </span>
                </button>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="isBusy2" class="mt-3" align-h="center">
          <b-spinner class="m-1" style="width: 3rem; height: 3rem; font-size: 20px; color: #6a71ec">
          </b-spinner>
        </b-row>
        <b-row v-if="!isBusy2 && elementsTMP.length == 0" class="mt-3" align-h="center">
          <p>{{ $t('no_results') }}</p>
        </b-row>
      </b-card>
    </div>

    <b-modal id="modal-add" ref="modal" title-class="text-font-black" :title="$t('add')+ ' ' + title_lowcase"
      modal-class="model-content" hide-footer content-class="shadow" @show="resetModal" @hidden="resetModal" size="md">
      <b-container>
        <b-row align-v="center">
         <!-- <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('country')+':'" class="mb-0">
              <b-form-select v-model="element_add.id_country" :options="countries"
                style="border-radius: 20px; color: #000000;" required>
              </b-form-select>
            </b-form-group>
          </b-col>-->
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('category')+':'" class="mb-0">
              <b-form-select v-model="element_add.id_ad_banner_category" :options="categoriesx"
                style="border-radius: 20px; color: #000000;" required>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('external')+':'" class="mb-0">
              <b-form-input type="text" v-model="element_add.url_redirect" :placeholder="$t('url_redirect')"
                style="border-radius: 20px; color: #000000;" required @keyup.enter="SaveElement()">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('description')+':'" class="mb-0">
              <b-form-textarea type="text" v-model="element_add.description" :placeholder="$t('description')"
                style="border-radius: 20px; color: #000000;" :rows="2" required>
              </b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('aspect_ratio')+':'" class="mb-0">
              <b-form-select v-model="element_add.aspect_ratio" :options="aspect_ratios"
                style="border-radius: 20px; color: #000000;" required>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('opacity') + ':'" class="mb-0">
              <b-form-input
                type="number"
                min="0"
                max="100"
                v-model="element_add.opacity"
                :placeholder="$t('opacity')"
                style="border-radius: 20px; color: #000000;"
                required
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group label="Banner:" class="mb-0">
              <b-form-file v-model="fileToUpload" accept="image/*" :state="Boolean(fileToUpload)"
                v-on:change="onFileChange" :placeholder="$t('select_file')+'...'"
                :drop-placeholder="$t('drop_file')+'...'" :browse-text="$t('browse')">
                <img id="blah" src="#" alt="your image" />
              </b-form-file>

              <div v-if="fileToUpload.name" class="mt-3 text-center">
                <span v-if="fileToUpload.name">{{ $t('selected_image') + ':' }}</span>
                {{ fileToUpload ? fileToUpload.name : "" }}
                <br v-if="fileToUpload.name">
                <br v-if="fileToUpload.name">

                <img :src="preview_image" style="max-height: 150px; max-width: 100%" />
              </div>
            </b-form-group>
          </b-col>
          <br />
          <b-col lg="12">
            <b-button block pill v-show="!progress" class="my-3" :disabled="element_add == '' || element_add == ''"
              @click="SaveElement()"
              style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('add').toUpperCase() }}
            </b-button>
          </b-col>
          <b-col>
            <div v-show="progress" class="text-center my-3" style="color: #6a71ec">
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal id="modal-update" ref="modal" title-class="text-font-black" :title="$t('update') + ' ' + title_lowcase"
      modal-class="model-content" hide-footer content-class="shadow" @hidden="resetModal" size="md">
      <b-container>
        <b-row align-v="center">
          <!--<b-col lg="12" class="mb-2">
            <b-form-group :label="$t('country')+':'" class="mb-0">
              <b-form-select v-model="element_update.id_country" :options="countries"
                style="border-radius: 20px; color: #000000;" required>
              </b-form-select>
            </b-form-group>
          </b-col>-->
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('category')+':'" class="mb-0">
              <b-form-select v-model="element_update.id_ad_banner_category" :options="categories.slice(1)"
                style="border-radius: 20px; color: #000000;" required>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('external')+':'" class="mb-0">
              <b-form-input type="text" v-model="element_update.url_redirect" :placeholder="$t('url_redirect')"
                style="border-radius: 20px; color: #000000;" required @keyup.enter="UpdateElement()">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('description')+':'" class="mb-0">
              <b-form-textarea type="text" v-model="element_update.description" :placeholder="$t('description')"
                style="border-radius: 20px; color: #000000;" :rows="2" required>
              </b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('aspect_ratio')+':'" class="mb-0">
              <b-form-select v-model="element_update.aspect_ratio" :options="aspect_ratios"
                style="border-radius: 20px; color: #000000;" required>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('opacity') + ':'" class="mb-0">
              <b-form-input
                type="number"
                min="0"
                max="100"
                v-model="element_update.opacity"
                :placeholder="$t('opacity')"
                style="border-radius: 20px; color: #000000;"
                required
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group label="Banner:" class="mb-0">
              <b-form-file v-model="fileToUploadUpdate" accept="image/*" :state="Boolean(fileToUploadUpdate)"
                v-on:change="onFileChange" :placeholder="$t('select_file')+'...'"
                :drop-placeholder="$t('drop_file')+'...'" :browse-text="$t('browse')">
                <img id="blah" src="#" alt="your image" />
              </b-form-file>
              <div class="mt-3 text-center">
                <span v-if="fileToUploadUpdate.name">{{ $t('selected_image') + ':' }}</span>
                {{ fileToUploadUpdate ? fileToUploadUpdate.name : "" }}
                <br v-if="fileToUploadUpdate.name">
                <br v-if="fileToUploadUpdate.name">

                <img v-if="fileToUploadUpdate.name" :src="preview_image" style="max-height: 150px; max-width: 100%" />
                <img v-else :src="element_update.url_banner" style="max-height: 150px; max-width: 100%" />
              </div>
            </b-form-group>
          </b-col>
          <br />
          <b-col lg="12">
            <b-button block pill v-show="!progress" class="my-3" @click="UpdateElement()"
              style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('update').toUpperCase() }}
            </b-button>
          </b-col>
          <b-col>
            <div v-show="progress" class="text-center my-3" style="color: #6a71ec">
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal id="modal-order" ref="modal" title-class="text-font-black" :title="$t('change_order')"
      modal-class="model-content" hide-footer content-class="shadow" @hidden="resetModal" size="sm">
      <b-container>
        <b-row align-v="center">
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('order')+':'" class="mb-0" style="max-height: 200px;">
              <v-select class="selector-class" v-model="element_update.ordering" :options="orders" label="username"
                style="max-height: 100px; border-radius: 20px; color: #000000;">
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{ $t('no_results_for') }}: <em>{{ search }}</em>.
                  </template>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <br />
          <b-col lg="12">
            <b-button block pill v-show="!progress" class="my-3" @click="ChangeOrder()"
              style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('update').toUpperCase() }}
            </b-button>
          </b-col>
          <b-col>
            <div v-show="progress" class="text-center my-3" style="color: #6a71ec">
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>

import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "MasterElements",

  data() {
    return {
      language: navigator.language,
      element: {},
      element_url: "bannera",
      element_title: "ad_banner",
      title_gender_letter: "",
      title_gender_letter2: "",
      element_add: { id_country: '0', 
      id_ad_banner_category: '',
       aspect_ratio: "1.777777778",
      opacity:1 },
      element_update: {},
      progress: false,
      total: 0,
      isBusy: false,
      isBusy2: false,
      emptyHTML: `<div class="mt-2 text-center"><h6>${this.$i18n.t('no_elements')}</h6></div>`,
      emptyFilteredHTML: `<div class="mt-2 text-center"><h6>${this.$i18n.t('no_elements_query')}</h6></div>`,
      id: "",
      id_country: '0',
      id_ad_banner_category: '0',
      countries: [{ text: this.$i18n.t('all'), value: '0' }],
      countriesx: [{ text: this.$i18n.t('select_one'), value: '' }],
      categories: [{ text: this.$i18n.t('all2'), value: '0' }],
      categoriesx: [{ text: this.$i18n.t('select_one2'), value: '' }],
      categoriesTMP: [{ text: this.$i18n.t('all2'), value: '0' }],
      aspect_ratios: [{ text: "16:9", value: "1.777777778" }, { text: "1:1", value: '1' }],
      orders: [],
      currentPage: 1,
      filter: null,
      elements: [],
      elementsTMP: [],
      fileToUpload: [],
      fileToUploadUpdate:[],
      file_name: "",
      file_description: "",
      file: {
        name: "",
        description: "",
        url: "",
        format: "",
      },
      preview_image:''
    };
  },
  computed: {
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.total
    },
    title_plural_uppercase() {
      return this.$i18n.t(this.element_title+'_plural').toUpperCase()
    },
    title_uppercase() {
      return this.$i18n.t(this.element_title).toUpperCase()
    },
    title_plural_midcase() {
      return this.$i18n.t(this.element_title+'_plural')
    },
    title_midcase() {
      return this.$i18n.t(this.element_title)
    },
    title_plural_lowcase() {
      return this.$i18n.t(this.element_title+'_plural').toLowerCase()
    },
    title_lowcase() {
      return this.$i18n.t(this.element_title).toLowerCase()
    }
  },
  mounted() {
    this.AllCategories();
    this.AllCountries();
    this.AllElements();
  },
  methods: {
    ...mapActions(["GetAll", "ChangeStatus", "Save", "Update","SaveFormData","UpdateFormData"]),
    format_num(num) {
      if (num >= 1000 && num < 1000000){
        return (num/1000).toFixed(2) + 'K'
      }
      else if(num >= 1000000){
        return (num/1000000).toFixed(2) + 'M'
      }
      else {
        return num
      }
    },
    onFileChange(e) {
      //When file input change, create a image preview
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      //Set image to preview
      //var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.preview_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    resetModal() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-add");
      });
      this.progress = false
      this.element = {}
      this.element_update = {}
      this.element_add = { id_country: '0', id_ad_banner_category: '', aspect_ratio: '1.777777778' }
      this.fileToUpload = []
      this.fileToUploadUpdate = []
      this.file_name = ""
      this.file_description = ""
      this.file = { name: "", description: "", url: "", format: "" }
      this.preview_image = ''
    },
    ClearFilter() {
      this.filter = "";
      this.AllElements();
      return true;
    },
    SearchInput() {
      let filterTMP = this.filter;

      if (filterTMP) {
        //Ask for Users by Query (first 20)
        this.isBusy = true;

        let form = {
          element: "bannera",
          query: {
            query: filterTMP,
            order_key: "ordering",
            order_value: "asc",
            limit: 9,
          },
          columns: [],
          params: {}
        };

        if (this.id_ad_banner_category > 0) {
          form.columns.push("id_ad_banner_category")
          form.params.id_ad_banner_category = this.id_ad_banner_category
        }

        this.GetAll(form)
          .then((response) => {
            if (response.code == 200) {
              this.isBusy = false;
              this.elements = response.data;
              this.elementsTMP = response.data;
              this.total = response.data.length;
            }
            else {
              this.isBusy = false;
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.isBusy = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            });
          });
      } else {
        this.AllElements();
      }

      return true;
    },
    OnFiltered(filteredItems) {
      this.total = filteredItems.length;
      this.currentPage = 1;
    },
    PageChanged() {
      this.AllElements(false);
    },
    AllCategories() {
      let form = {
        element: "master/bannera_category",
        query: {
          active: 1,
          order_key: this.language.substring(0,2)+'_name',
          order_value: "asc",
          select: ['id',this.language.substring(0,2)+'_name']
        },
      };

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.categoriesTMP = [{ text: this.$i18n.t('all2'), value: '0' }, ...response.data.map((el) => ({ text: el[this.language.substring(0,2)+'_name'], value: el.id }))]
            this.categories = this.categoriesTMP
            this.categoriesx = [{ text: this.$i18n.t('select_one2'), value: '' }, ...this.categoriesTMP.slice(1)]
          }
          else {
            console.log(response);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    AllCountries() {
      let form = {
        element: "master/country",
        query: {
          active: 1,
          order_key: this.language.substring(0,2)+'_name',
          order_value: "asc",
          select: ['id',this.language.substring(0,2)+'_name']
        },
      };

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.countriesTMP = [{ text: this.$i18n.t('all2'), value: '0' }, ...response.data.map((el) => ({ text: el[this.language.substring(0,2)+'_name'], value: el.id }))]
            this.countries = this.countriesTMP
            this.countriesx = this.countriesTMP
          }
          else {
            console.log(response);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    AllElements(upd_total = true) {
      this.isBusy2 = true
      if (upd_total) this.isBusy = true

      let form = {
        element: this.element_url,
        query: {
          order_key: "ordering",
          order_value: "asc",
          page: this.currentPage,
          limit: 10
        },
        columns: [],
        params: {},
      };

      if (this.id_country != '0') {
        form.columns.push("id_country")
        form.params["id_country"] = this.id_country
      }

      if (this.id_ad_banner_category != '0') {
        form.columns.push("id_bannera_category")
        form.params["id_bannera_category"] = this.id_ad_banner_category
      }

      this.GetAll(form)
        .then((response) => {

          if (response.code == 200) {
            this.isBusy = false;
            this.isBusy2 = false;
            this.elements = response.data.data;
            this.elementsTMP = response.data.data;
            this.total = response.data.meta.total;

            this.orders = []
            for (let i = 1; i <= this.total; i++)   {this.orders.push(i)}
          } else {
            console.log(response);
            this.isBusy = false;
            this.isBusy2 = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          this.isBusy2 = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    SaveElement() {
      const validation =
        !this.element_add.id_ad_banner_category ||
        !this.element_add.aspect_ratio ||
        !this.element_add.url_redirect ||
        !this.element_add.description

      if (validation) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_all_fields'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return
      }

      this.progress = true;
      let data = new FormData();
      data.append("url_banner", this.fileToUpload);
      data.append("description", this.element_add.description);
      data.append("id_country", this.element_add.id_country ? this.element_add.id_country : '0');
      data.append("id_ad_banner_category", this.element_add.id_ad_banner_category);
      data.append("url_redirect", this.element_add.url_redirect);
      data.append("aspect_ratio", this.element_add.aspect_ratio);
      data.append("opacity", this.element_add.opacity);

      const words = this.element_add.description.split(" ")
      const hashtags = []

      for (let word of words) {
        if (word.startsWith("#")) hashtags.push(word)
      }
      
      if (hashtags.length > 0) {
        for (let element of hashtags) data.append("hashtags[]", element)
      }

      let form = { element: this.element_url, data: data};

      
      this.SaveFormData(form)
        .then((response) => {
          
          if (response.code == 200) {
            this.$nextTick(() => {
              this.$bvModal.hide("modal-add");
            });

            this.progress = false;
            this.AllElements();
            Swal.fire({
              title: this.title_midcase + ' ' + this.$i18n.t('added'+this.title_gender_letter).toLowerCase(),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title: this.$i18n.t('register_exists'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    UpdateTMP(data) {
      this.element_update = {
        id: data.id,
        id_country: data.id_country,
        id_ad_banner_category: data.id_ad_banner_category,
        description: data.description,
        url_redirect: data.url_redirect,
        aspect_ratio: data.aspect_ratio,
        url_banner: data.url_banner,
        ordering: data.ordering,
        opacity: data.opacity,
      };
      this.element = data

      //this.element = data
      this.$nextTick(() => {
        this.$bvModal.show("modal-update");
      });
    },
    ChangeOrderTMP(data) {
      this.element_update = {
        id: data.id,
        ordering: data.ordering,
      };
      this.element = data

      this.$nextTick(() => {
        this.$bvModal.show("modal-order");
      });
    },
    UpdateElement() {
      const change =
        this.element_update.id_country != this.element.id_country ||
        this.element_update.id_ad_banner_category != this.element.id_ad_banner_category ||
        this.element_update.description != this.element.description ||
        this.element_update.url_redirect != this.element.url_redirect ||
        this.element_update.aspect_ratio != this.element.aspect_ratio ||
        this.element_update.url_banner != this.fileToUploadUpdate.url;

      if (!change) {
        return;
      }

      const validation =
        !this.element_update.id_ad_banner_category ||
        !this.element_update.url_redirect ||
        !this.element_update.aspect_ratio ||
        !this.element_update.description

      if (validation) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_all_fields'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return;
      }

      this.progress = true;
      let data_to_update = new FormData();
      data_to_update.append("url_banner", this.fileToUploadUpdate);
      data_to_update.append("description", this.element_update.description);
      data_to_update.append("id_ad_banner_category", this.element_update.id_ad_banner_category);
      data_to_update.append("id_country", this.element_update.id_country ? this.element_update.id_country : '0');
      data_to_update.append("url_redirect", this.element_update.url_redirect);
      data_to_update.append("aspect_ratio", this.element_update.aspect_ratio);
      data_to_update.append("opacity", this.element_update.opacity);

      const words = this.element_update.description.split(" ")
      const hashtags = []

      for (let word of words) {
        if (word.startsWith("#")) hashtags.push(word)
      }
      
      if (hashtags.length > 0) {
        for (let element of hashtags) data_to_update.append("hashtags[]", element)
      }

      let form = {
        element: this.element_url,
        id: this.element_update.id,
        update: "update",
        data: data_to_update,
      };

      this.UpdateFormData(form)
        .then((response) => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-update");
          });

          if (response.code == 200) {
            this.progress = false;
            this.AllElements();
            Swal.fire({
              title:
                this.title_midcase + ' ' + this.$i18n.t('updated'+this.title_gender_letter).toLowerCase(),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title:
                this.$i18n.t('register_exists'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    ChangeOrder() {
      const change = this.element_update.ordering != this.element.ordering
        
      if (!change)    return

      const validation = !this.element_update.ordering

      if (validation) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_all_fields'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return;
      }

      this.progress = true
      
      let form = {
        element: this.element_url,
        id: this.element_update.id,
        update: "update",
        data: {
          ordering: this.element_update.ordering
        },
      }

      this.Update(form)
        .then((response) => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-order");
          });

          if (response.code == 200) {
            this.progress = false
            this.AllElements()
            Swal.fire({
              title:
                this.title_midcase + ' ' + this.$i18n.t('updated'+this.title_gender_letter),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title: this.$i18n.t('register_exists'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    ChangeActive(data) {
      let active = data.active == 1 ? 0 : 1;
      let title =
        data.active == 1
          ? "deactivate"
          : "activate"
      let title2 =
        data.active == 1
          ? "deactivated" + this.title_gender_letter
          : "activated" + this.title_gender_letter
      let text =
        data.active == 1
          ? "deactivate_question" + this.title_gender_letter2
          : "activate_question" + this.title_gender_letter2
      Swal.fire({
        title: this.$i18n.t(title) + ' ' + this.title_lowcase,
        text: this.$i18n.t(text) + ' ' + this.title_lowcase + '?',
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: active == 0 ? this.$i18n.t('deactivate') : this.$i18n.t('activate'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = { element: this.element_url, id: data.id, status: active };

          this.ChangeStatus(form)
            .then((response) => {
              
              if (response.code == 200) {
                data.active = active;
                Swal.fire({
                  title: this.title_midcase + " " + this.$i18n.t(title2).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              } else {
                console.log(response);
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                });
              }
            })
            .catch((error) => {
              console.log(error);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              });
            });
        }
      });
    },
    Delete(data) {
      Swal.fire({
        title: this.$i18n.t('delete') + ' ' + this.title_lowcase,
        text: this.$i18n.t('delete_question'+this.title_gender_letter) + ' ' + this.title_lowcase + '? ' + this.$i18n.t('delete_questionx'+this.title_gender_letter),
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: this.$i18n.t('delete'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = { element: this.element_url, id: data.id, status: 2 };

          this.ChangeStatus(form)
            .then((response) => {
              if (response.code == 200) {
                this.AllElements();
                Swal.fire({
                  title: this.title_midcase + ' ' + this.$i18n.t('deleted'+this.title_gender_letter).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              } else {
                console.log(response);
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                });
              }
            })
            .catch((error) => {
              console.log(error);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              });
            });
        }
      });
    },
  },
};
</script>

<style>

.table thead th {
  cursor: pointer;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #6a71ec !important;
  border-color: #6a71ec !important;
}

.header,
.table thead th,
thead,
th {
  background-color: #6a71ec !important;
  color: #ffffff;
}

.selector-class .vs__search::placeholder,
.selector-class .vs__dropdown-toggle {
  border-radius: 20px;
  height: 40px;
}

.selector-class .vs__dropdown-menu {
  border-color: #000000;
  border-radius: 0px;
  max-height: 200px;
  overflow-y: auto;
}

.selector-class .vs__dropdown-option--highlight {
  background-color: #6a71ec;
}

</style>

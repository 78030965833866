var url_domain = window.location.host;
var config_url;

if (url_domain == "dashtest.jeviapp.com") {
  //PROD FRONT URL
  config_url = "https://apitest.jeviapp.com/api/1.0"; //PROD API URL
} else if (url_domain == "dashboard.jeviapp.com") {
  config_url = "https://api.jeviapp.com/api/1.0";
} else {
  config_url = "http://127.0.0.1:3333/api/1.0";
  // config_url = 'https://apijevitest.myticktap.com/api/1.0'
}

console.log("Backend Conected: " + config_url);
export const apiPath = config_url;

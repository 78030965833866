<template>
  <div id="SideNavbar">
    <div class="d-flex" id="wrapper">
      <!-- Sidebar -->
      <div id="sidebar-wrapper" style="background-color: #6a71ec;">
        <b-row>
          <b-col md="12">
            <div class="my-3" style="text-align: center">
              <img src="../../assets/logo5.png" width="200px" style="padding: 10px" alt="Jevi App Logo" />
            </div>
          </b-col>
        </b-row>

        <div class="list-group list-group-flush">
          <router-link class="list-group-item list-group-item-action menu-item"
            style="border: none; background-color: #6a71ec; text-align: left;" :to="{ name: 'home' }" exact-path>
            <div v-if="!isToggled" @click="toggleNav">
              <font-awesome-icon style="color: white; font-size: 20px;" icon="home" />
              <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                {{ $t('home') }}
              </span>
            </div>
            <div v-if="isToggled">
              <font-awesome-icon style="color: white; font-size: 20px;" icon="home" />
              <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                {{ $t('home') }}
              </span>
            </div>
          </router-link>

          <router-link v-if="id_role == 1" class="list-group-item list-group-item-action menu-item"
            style="border: none; background-color: #6a71ec; text-align: left;" to="../../users" exact-path>
            <div @click="toggleNav">
              <font-awesome-icon style="color: white; font-size: 20px" icon="users" />
              <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                {{ $t('users') }}
              </span>
            </div>
          </router-link>

          <router-link v-if="id_role == 1" class="list-group-item list-group-item-action menu-item"
            style="border: none; background-color: #6a71ec; text-align: left;" to="../../user_verifications" exact-path>
            <div @click="toggleNav">
              <font-awesome-icon style="color: white; font-size: 20px" icon="user-check" />
              <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                {{ $t('user_verifications') }}
              </span>
            </div>
          </router-link>

          <a v-if="id_role == 1" class="list-group-item list-group-item-action menu-item"
            style="cursor: pointer; width: 100%; border: none; background-color: #6a71ec; text-align: left;"
            v-b-toggle.collapse-2>
            <font-awesome-icon style="color: white; font-size: 20px" icon="flag" />
            <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
              {{ $t("reports") }}
            </span>
            <font-awesome-icon class="ml-2" style="color: white; font-size: 14px" icon="chevron-down" />
          </a>

          <b-collapse id="collapse-2">
            <router-link class="pl-5 list-group-item list-group-item-action menu-item"
              style="border: none; background-color: #6a71ec; text-align: left;" to="../../user_reports">
              <div @click="toggleNav">
                <font-awesome-icon style="color: white; font-size: 20px" icon="user" />
                <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                  {{ $t('users') }}
                </span>
              </div>
            </router-link>

            <router-link class="pl-5 list-group-item list-group-item-action menu-item"
              style="border: none; background-color: #6a71ec; text-align: left;" to="../../post_reports">
              <div @click="toggleNav">
                <font-awesome-icon style="color: white; font-size: 20px" icon="camera" />
                <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                  {{ $t('posts') }}
                </span>
              </div>
            </router-link>
          </b-collapse>

          <!--<router-link
            v-if="create_polls == 1"
            class="list-group-item list-group-item-action menu-item"
            style="border: none; background-color: #6a71ec; text-align: left;"
            to="../../polls">
              <div @click="toggleNav">
                <font-awesome-icon style="color: white; font-size: 20px" icon="poll" />
                <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">            
                  {{ $t('polls') }}
                </span>
              </div>
          </router-link>-->

          <router-link class="list-group-item list-group-item-action menu-item"
            style="border: none; background-color: #6a71ec; text-align: left;" to="../../ad_banners">
            <div @click="toggleNav">
              <font-awesome-icon style="color: white; font-size: 20px" icon="ad" />
              <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                {{ $t('ad_banners') }}
              </span>
            </div>
          </router-link>

          <router-link class="list-group-item list-group-item-action menu-item"
            style="border: none; background-color: #6a71ec; text-align: left;" to="../../highlights">
            <div @click="toggleNav">
              <font-awesome-icon style="color: white; font-size: 20px" icon="ad" />
              <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                {{ $t('highlights') }}
              </span>
            </div>
          </router-link>

          <router-link class="list-group-item list-group-item-action menu-item"
            style="border: none; background-color: #6a71ec; text-align: left;" to="../../stations">
            <div @click="toggleNav">
              <font-awesome-icon style="color: white; font-size: 20px" icon="headphones" />
              <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                {{ $t('radio_stations') }}
              </span>
            </div>
          </router-link>

          <router-link class="list-group-item list-group-item-action menu-item"
            style="border: none; background-color: #6a71ec; text-align: left;" to="../../public_rooms">
            <div @click="toggleNav">
              <font-awesome-icon style="color: white; font-size: 20px" icon="comments" />
              <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                {{ $t('public_rooms') }}
              </span>
            </div>
          </router-link>

          <a v-if="id_role == 1" class="list-group-item list-group-item-action menu-item"
            style="cursor: pointer; width: 100%; border: none; background-color: #6a71ec; text-align: left;"
            v-b-toggle.collapse-3>
            <font-awesome-icon style="color: white; font-size: 20px" icon="cog" />
            <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
              {{ $t("settings") }}
            </span>
            <font-awesome-icon class="ml-2" style="color: white; font-size: 14px" icon="chevron-down" />
          </a>

          <b-collapse id="collapse-3">
            <router-link v-if="id_role == 1" class="pl-5 list-group-item list-group-item-action menu-item"
              style="border: none; background-color: #6a71ec; text-align: left;" to="../../jevi_config" exact-path>
              <div @click="toggleNav">
                <font-awesome-icon style="color: white; font-size: 20px" icon="sliders-h" />
                <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                  {{ $t('app') }}
                </span>
              </div>
            </router-link>

            <router-link v-if="id_role == 1" class="pl-5 list-group-item list-group-item-action menu-item"
              style="border: none; background-color: #6a71ec; text-align: left;" to="../../masters/verification_reasons"
              exact-path>
              <div @click="toggleNav">
                <font-awesome-icon style="color: white; font-size: 20px" icon="check-circle" />
                <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                  {{ $t('verification_reasons') }}
                </span>
              </div>
            </router-link>

            <router-link v-if="id_role == 1" class="pl-5 list-group-item list-group-item-action menu-item"
              style="border: none; background-color: #6a71ec; text-align: left;" to="../../masters/type_photos"
              exact-path>
              <div @click="toggleNav">
                <font-awesome-icon style="color: white; font-size: 20px" icon="camera" />
                <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                  {{ $t('type_photos') }}
                </span>
              </div>
            </router-link>

            <router-link v-if="id_role == 1" class="pl-5 list-group-item list-group-item-action menu-item"
              style="border: none; background-color: #6a71ec; text-align: left;" to="../../ad_banner_categories"
              exact-path>
              <div @click="toggleNav">
                <font-awesome-icon style="color: white; font-size: 20px" icon="ad" />
                <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                  {{ $t('ad_banner_categories') }}
                </span>
              </div>
            </router-link>

            <router-link v-if="id_role == 1" class="pl-5 list-group-item list-group-item-action menu-item"
              style="border: none; background-color: #6a71ec; text-align: left;" to="../../masters/public_room_rules"
              exact-path>
              <div @click="toggleNav">
                <font-awesome-icon style="color: white; font-size: 20px" icon="address-book" />
                <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                  {{ $t('public_room_rules') }}
                </span>
              </div>
            </router-link>

            <router-link v-if="id_role == 1" class="pl-5 list-group-item list-group-item-action menu-item"
              style="border: none; background-color: #6a71ec; text-align: left;" to="../../masters/forbidden_words"
              exact-path>
              <div @click="toggleNav">
                <font-awesome-icon style="color: white; font-size: 20px" icon="comment-slash" />
                <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                  {{ $t('forbidden_words') }}
                </span>
              </div>
            </router-link>

            <router-link v-if="id_role == 1" class="pl-5 list-group-item list-group-item-action menu-item"
              style="border: none; background-color: #6a71ec; text-align: left;"
              to="../../masters/user_report_categories" exact-path>
              <div @click="toggleNav">
                <font-awesome-icon style="color: white; font-size: 20px" icon="user-lock" />
                <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                  {{ $t('report_categories') }}
                </span>
              </div>
            </router-link>

            <router-link v-if="id_role == 1" class="pl-5 list-group-item list-group-item-action menu-item"
              style="border: none; background-color: #6a71ec; text-align: left;" to="../../masters/reporting_reasons"
              exact-path>
              <div @click="toggleNav">
                <font-awesome-icon style="color: white; font-size: 20px" icon="bullhorn" />
                <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                  {{ $t('reporting_reasons') }}
                </span>
              </div>
            </router-link>

            <!--<router-link v-if="id_role == 1" class="pl-5 list-group-item list-group-item-action menu-item"
              style="border: none; background-color: #6a71ec; text-align: left;"
              to="../../masters/room_reporting_reasons" exact-path>
              <div @click="toggleNav">
                <font-awesome-icon style="color: white; font-size: 20px" icon="user-slash" />
                <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                  {{ $t('room_reporting_reasons') }}
                </span>
              </div>
            </router-link>-->

            <router-link v-if="id_role == 1" class="pl-5 list-group-item list-group-item-action menu-item"
              style="border: none; background-color: #6a71ec; text-align: left;" to="../../masters/titles" exact-path>
              <div @click="toggleNav">
                <font-awesome-icon style="color: white; font-size: 20px" icon="id-card" />
                <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                  {{ $t('titles') }}
                </span>
              </div>
            </router-link>

            <router-link v-if="id_role == 1" class="pl-5 list-group-item list-group-item-action menu-item"
              style="border: none; background-color: #6a71ec; text-align: left;" to="../../masters/station_categories"
              exact-path>
              <div @click="toggleNav">
                <font-awesome-icon style="color: white; font-size: 20px" icon="th" />
                <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">
                  {{ $t('station_categories') }}
                </span>
              </div>
            </router-link>
          </b-collapse>

          <!--<router-link
            class="list-group-item list-group-item-action menu-item"
            style="border: none; background-color: #6a71ec; text-align: left;"
            :to="{ name: 'travel_places' }"
            exact-path>
              <div @click="toggleNav">
                <font-awesome-icon style="color: white; font-size: 20px" icon="plane" />
                <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">            
                    Lugares de viaje
                </span>
              </div>
          </router-link>-->

          <!--<router-link
            class="list-group-item list-group-item-action menu-item"
            style="border: none; background-color: #6a71ec; text-align: left;"
            :to="{ name: 'type_photos' }"
            exact-path>
              <div @click="toggleNav">
                <font-awesome-icon style="color: white; font-size: 20px" icon="camera" />
                <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">            
                    Tipos de fotos
                </span>
              </div>
          </router-link>-->

          <!--<router-link
            class="list-group-item list-group-item-action menu-item"
            style="border: none; background-color: #6a71ec; text-align: left;"
            :to="{ name: 'faqs' }"
            exact-path>
              <div @click="toggleNav">
                <font-awesome-icon style="color: white; font-size: 20px" icon="question-circle" />
                <span style="font-size: 16px; color: white; padding-left: 5px; margin-left: 5px;" class="text2">            
                    FAQs
                </span>
              </div>
          </router-link>-->
        </div>
      </div>
      <!-- /#sidebar-wrapper -->

      <!-- Page Content -->
      <div id="page-content-wrapper" style="background-color: #F2F2F2">
        <b-navbar toggleable="lg" type="light" :class="{ 'd-none': $route.path === '/login' }"
          style="box-shadow: 12px 10px 8px #B0B0B0; background-color: #6a71ec;">

          <b-navbar-brand href="#" style="background-color: #6a71ec; color: white" @click="forceToggleNav">
            <font-awesome-icon style="color: white; font-size: 20px;" icon="bars" />
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse" style="background-color: #6a71ec;" />

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <!--<b-dropdown :dropleft="dropleft" text="Notificaciones" style="background-color: #6a71ec; padding-top: 0px; padding-bottom: 0px; margin-top: 10px; margin-bottom: 0px; margin-right: 0px;" no-caret variant="#6a71ec">
                <template #button-content>
                  <notification-bell :size="25" :count="total_notif" :upper-limit="50" font-size="12px" counterPadding="4px" iconColor="#FFFFFF" counterBackgroundColor="#6a71ec" prefixPlus/>
                </template>
                <div class="notif-dd">
                  <b-dropdown-item v-if="total_notif == 0" style="height: 32px; text-align: center" align-v="center">
                    <p class="center py-0 my-0"><strong>¡Estás al día!</strong></p>
                    <p class="center py-0 my-0">No tienes notificaciones pendientes</p>
                  </b-dropdown-item>

                  <b-dropdown-item class="notif-el" v-if="total_notif > 0" @click="markAllNotif(1)" style="height: 100%; text-align: right">
                    <strong class="m-0 p-0" style="font-size: 12px;">Marcar todas como leídas</strong>
                  </b-dropdown-item>
                  <b-dropdown-item class="notif-el m-0 p-0" v-for="(data, index) of notifications" :key="data.id" style="height: 100%; text-align: left">
                    <b-btn variant="white" @click="clickNotif(index, data, 1)" class="p-0 m-0">
                      <p class="m-0 p-0" style="text-align: left"><strong style="font-size: 12px; text-align: left">{{ data.title }}</strong></p>
                      <p class="m-0 p-0" style="text-align: left; font-size: 12px;">{{ data.description }}</p>
                    </b-btn>
                  </b-dropdown-item>
                  <br>
                </div>
              </b-dropdown>-->
              <br>
              <div class="dropdown">
                <button class="dropbtn" style="background-color: #6a71ec; padding-top:30px;">
                  <span class="mr-auto text2" style="font-size: 20px; background-color: #6a71ec; color: #FFFFFF;">
                    <strong>{{ full_name }}</strong>
                  </span>
                  <i style="color: #FFFFFF; margin-left: 15px; font-size: 15px" class="fas fa-chevron-down"></i>
                </button>

                <div class="dropdown-content mr-3" style="background-color: #6a71ec; color: white; border-radius: 5px;">
                  <button class="buttonDr" v-on:click="logoutMenu" style="background-color: #6a71ec; color: white">
                    {{ $t('logout') }}
                  </button>
                </div>
              </div>
            </b-navbar-nav>

          </b-collapse>
        </b-navbar>

        <div v-if="!isToggled" class="container-fluid" @click="toggleNav">
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
        </div>
        <div v-if="isToggled" class="container-fluid">
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
        </div>
      </div>
      <!-- /#page-content-wrapper -->
    </div>
  </div>
</template>

<script>

//import NotificationBell from 'vue-notification-bell'
import $ from "jquery";
import { mapState, mapActions } from "vuex"
import Cookies from "js-cookie"
import Swal from "sweetalert2"

export default {
  name: "SideNavbar",

  /*components: {
    NotificationBell
  },*/
  
  data() {
    return {
      progress: true,
      error: false,
      id_user: Cookies.get('id_user'),
      id_role: Cookies.get('id_role'),
      create_polls: Cookies.get('create_polls'),
      total_notif: 0,
      notifications: [],
      isToggled: false,
      screenWidth: 5000,
      dropleft: true,
      full_name: Cookies.get('full_name'),
    };
  },

  computed: {
    ...mapState({ }),
  },

  mounted: function () {
    this.screenWidth = $(window).width()

    if (this.screenWidth < 500) this.dropleft = false
    
    $("#menu-toggle").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    })
  },

  methods: {
    ...mapActions(["Logout","GetAll","ChangeViewed"]),
    toggleNav() {
      if (this.screenWidth < 670) {
        if (!this.isToggled)    this.isToggled = true
        else                    this.isToggled = false

        $("#wrapper").toggleClass("toggled")
      }
    },
    forceToggleNav() {
      if (!this.isToggled)    this.isToggled = true
      else                    this.isToggled = false

      $("#wrapper").toggleClass("toggled")
    },
    logoutMenu() {
      Swal.fire({
        title: this.$i18n.t('logout_question'),
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#6a71ec",
        confirmButtonText: this.$i18n.t('logout'),
        cancelButtonColor: "#1E202D",
        cancelButtonText: this.$i18n.t('cancel'),
      })
      .then((result) => {
        if (result.value) {
          this.Logout().then((data) => {
              console.log(data);
              this.$router.push("/login");

              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "success",
                title: this.$i18n.t('see_you_soon'),
              });
          });
        }
      });
    },
  },
};

</script>

<style scoped>

.text5 {
  text-align: left;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 4;
}

/* Style The Dropdown Button */
.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  background-color: #f8f9fa;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  margin-top: -15px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  right: 0;
  display: none;
  position: absolute;
  background-color: #f8f9fa;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.buttonDr {
  border: none;
  background-color: #f8f9fa;
  height: 50px;
}

.menu-item {
  color: white;
}

.menu-item:hover {
  background-color: #00873d;
  background: #00873d;
}

#wrapper {
  overflow-x: hidden;
}

.router-link-active {
  background-color: #000000 !important;
  border-left: 10px solid #ffa500 !important;
}

.stylenew {
  border-width: 1px;
  border-left-color: #000;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -70rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  color: white;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
  color: white;
}

#sidebar-wrapper .list-group {
  width: 19rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (max-width: 550px) {
  .notif-dd {
    width: 21rem;
    max-height: 340px;
    overflow-y: auto;
  }

  .notif-el {
    width: 21rem;
    padding-right: 0px;
    margin-right: 0px;
  }

  #sidebar-wrapper .list-group {
    width: 20rem;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -20rem;
  }

  #wrapper.toggled .container-fluid {
    margin-left: 0rem;
    padding-left: 0rem;
  }

  .container-fluid {
    padding-left: 80rem;
  }

  #sidebar-wrapper {
    margin-left: 0rem;
  }

  .form-control3 {
    font-size: 1rem;
    font-weight: 600;
    color: #FFFFFF;
    background-color: #f8f9fa;
    border: 0px solid #ffffff;
  }
}

@media (min-width: 550px) {
  .notif-dd {
    width: 100%;
    max-height: 340px;
    overflow-y: auto;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -19rem;
  }

  #sidebar-wrapper {
    margin-left: 0;
  }

  .form-control3 {
    font-size: 1rem;
    font-weight: 600;
    color: #FFFFFF;
    background-color: #f8f9fa;
    border: 0px solid #ffffff;
  }
}

</style>
<template>
  <div class="fondo">
    <b-container>
      <br />
      <br />
      <b-row>
        <b-col md="3"></b-col>

        <b-col md="6">
          <b-form center @submit="onSubmit" style="background: transparent" >
            <b-card style="box-shadow: 0px 10px 10px #000000; background-color: #6a71ec; border-radius: 20px">
              <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <img src="../assets/logo5.png" style="max-width: 100%; max-height: 150px" />
                </div>
                <div class="col-sm-1"></div>
              </div>
              
              <h3 style="text-align: center; color: #000000">{{ $t('login1') }}</h3>

              <!-- Using props -->
              <b-form-input class="my-3" type="email" v-model="email" :placeholder="$t('email')" style="box-shadow: 0px 5px 15px #1E202D; color: black; text-align: center; border-radius: 20px; font-size: 20px; border-color: #FFFFFF; background-color: #FFFFFF"></b-form-input>
              
              <b-form-input class="my-3" type="password" v-model="password" :placeholder="$t('password')" style="box-shadow: 0px 5px 15px #1E202D; color: black; text-align: center; border-radius: 20px; font-size: 20px; border-color: #FFFFFF; background-color: #FFFFFF"></b-form-input>

              <div class="row">
                <div class="col-md-12">
                  <b-button block pill  class="mb-3" type="submit" style="box-shadow: 0px 5px 15px #1E202D; color: #FFFFFF; font-size: 20px; border-color: #ffa500; background-color: #ffa500"
                    >
                    <span v-show="!progress" style="color: #000000"><strong>{{ $t('login2') }}</strong></span>
                    <b-spinner
                        v-show="progress"
                        label="Text Centered"
                        style="color: #000000">
                      </b-spinner>
                    </b-button
                  >

                  <!--<router-link
                    v-b-modal.modal-recovery
                    style="color: #000000"
                    :to="{}"
                    >¿Olvidaste tu contraseña?</router-link
                  >-->
                </div>
                <div class="col-md-1"></div>
              </div>
            </b-card>
          </b-form>
        </b-col>

        <b-col md="3"></b-col>
      </b-row>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </b-container>

    <b-modal
      id="modal-recovery"
      ref="modal"
      title-class="text-font-black"
      :title="$t('recover_password')"
      modal-class="model-content"
      hide-footer
      content-class="shadow"
      @show="resetModal"
      @hidden="resetModal"
    >
      <b-container>
        <b-row align-v="center" v-if="step == 1">
          <b-col lg="12">
            <b-form-input
                type="email"
                v-model="email_recovery"
                :placeholder="$t('email')"
                style="border-radius: 20px; color: #000000;"
                required
              >
          </b-form-input>
          </b-col>
          <br>
          <b-col lg="12">
            <b-button 
              block pill 
              v-show="!progress1" 
              class="my-3" 
              @click="recoveryPass()" 
              style="color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('continue') }}
            </b-button>
          </b-col>
          <b-col>
            <div v-show="progress1" class="text-center my-3" style="color: #6a71ec">
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>

        <b-row align-v="center" v-if="step == 2">
          <b-col lg="12">
            <b-form-input
                v-model="code"
                :placeholder="$t('verification_code')"
                style="border-radius: 20px; color: #000000;"
                required>
            </b-form-input>
          </b-col>
          <br>
          <b-col lg="12">
            <b-button 
              block pill 
              v-show="!progress1" 
              class="my-3" 
              @click="verifToken()" 
              style="color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('continue') }}
            </b-button>
          </b-col>
          <b-col lg="12">
            <div v-show="progress1" class="text-center my-3" style="color: #6a71ec">
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>

        <b-row align-v="center" v-if="step == 3">
          <b-col lg="12">
            <b-form-input
              id="pass_recovery"
              v-model="pass_recovery"
              :placeholder="$t('new_password')"
              class="my-1"
              style="border-radius: 20px; color: #000000;"
              type="password"
              required>
            </b-form-input>
          </b-col>
          <b-col lg="12">
            <b-form-input
              id="pass_recovery2"
              v-model="pass_recovery2"
              :placeholder="$t('repeat_new_password')"
              class="my-1"
              style="border-radius: 20px; color: #000000;"
              type="password"
              required>
            </b-form-input>
          </b-col>
          <br>
          <b-col lg="12">
            <b-button 
              block pill 
              v-show="!progress1" 
              class="my-3" 
              @click="newPassword()" 
              style="color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('continue') }}
            </b-button>
          </b-col>
          <b-col>
            <div v-show="progress1" class="text-center my-3" style="color: #6a71ec">
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>

import Swal from "sweetalert2";
import { mapActions } from "vuex";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      progress: false,
      progress1: false,
      btnLogin: false,
      id_user_recovery: null,
      email: "",
      email_recovery: "",
      code: "",
      password: "",
      password1: "",
      password2: null,
      pass_recovery: null,
      pass_recovery2: null,
      auth: {},
      step: 1,
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    ...mapActions([
      "Login",
      "RecoveryPassword",
      "VerifyPassToken",
      "SetNewPassword",
    ]),
    onSubmit(evt) {
      evt.preventDefault();
      console.log("Submit");

      if (this.email == "" || this.password == "") {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_email_password'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
      }
      else {
        this.progress = true;
        this.btnLogin = true;

        this.Login({
            email: this.email,
            password: this.password,
          })
          .then((data) => {
            this.btnLogin = false
            this.progress = false

            if (data.code == 200) {
              console.log(data)
              
              if (data.data.id_role != 1 && data.data.stations.length == 0) {
                Swal.fire({
                  title: this.$i18n.t('unauthorized'),
                  text: this.$i18n.t('require_admin_owner_permises'),
                  confirmButtonColor: "#6a71ec",
                  icon: "error",
                })
                return
              }

              this.$router.push("/home")

              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              Toast.fire({
                icon: "success",
                title: this.$i18n.t('welcome'),
              })
            }
            else if (data.code == 400) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "warning",
                title: this.$i18n.t('must_provide_email_password'),
              });
            }
            else if (data.code == 401) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "warning",
                title: this.$i18n.t('unactive_user'),
              });
            }
            else if (data.code == 402 || data.code == 404) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "warning",
                title: this.$i18n.t('wrong_credentials'),
              });
            }
          })
          .catch((error) => {
            console.log(error)
            this.btnLogin = false
            this.progress = false

            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          });
      }
    },
    resetModal() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-recovery");
      });
      this.email_recovery = "";
      this.code = "";
      this.pass_recovery = "";
      this.pass_recovery2 = "";
      this.step = 1;
    },
    recoveryPass() {
      if (this.email_recovery == "") {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_email'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return
      }

      this.progress1 = true

      let form = {
        email: this.email_recovery,
        type: 'password_token'
      }

      this.RecoveryPassword(form)
        .then((response) => {

          if (response.code == 200) {
            this.id_user_recovery = response.data.id;
            this.progress1 = false;
            this.step = 2;
            Swal.fire({
              title: this.$i18n.t('email_sent'),
              text: this.$i18n.t('sent_code_via_email'),
              confirmButtonColor: "#6a71ec",
              icon: "success",
            });
          }
          else if (response.code == 404) {
            this.progress1 = false;
            Swal.fire({
              title: this.$i18n.t('atention'),
              text: this.$i18n.t('email_not_registered'),
              confirmButtonColor: "#6a71ec",
              icon: "warning",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "Error",
            text: this.$i18n.t('an_error_has_occurred'),
            confirmButtonColor: "#6a71ec",
            icon: "error",
          });
          this.progress1 = false;
        });
    },
    verifToken() {
      this.progress1 = true

      let form = {
        verif_code: this.code
      }

      this.VerifyPassToken(form)
        .then((response) => {
          if (response.code == 200) {
            Swal.fire({
              title: this.$i18n.t('verification_completed'),
              text: this.$i18n.t('provide_new_password'),
              confirmButtonColor: "#6a71ec",
              icon: "success",
            });
            this.progress1 = false;

            this.step = 3;
          }
          else if (response.code == 400) {
            this.progress1 = false;
            Swal.fire({
              title: this.$i18n.t('atention'),
              text: this.$i18n.t('code_not_correct') + '. ' + this.$i18n.t('try_again'),
              confirmButtonColor: "#6a71ec",
              icon: "warning",
            });
          }
          else if (response.code == 403) {
            this.progress1 = false;
            Swal.fire({
              title: this.$i18n.t('atention'),
              text: this.$i18n.t('code_no_longer_valid'),
              confirmButtonColor: "#6a71ec",
              icon: "warning",
            });
          }
          else {
            this.progress1 = false;
            Swal.fire({
              title: "Error",
              text: this.$i18n.t('an_error_has_occurred'),
              confirmButtonColor: "#6a71ec",
              icon: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress1 = false;
          Swal.fire({
            title: "Error",
            text: this.$i18n.t('an_error_has_occurred'),
            confirmButtonColor: "#6a71ec",
            icon: "error",
          });
        });
    },
    newPassword() {
      if (this.pass_recovery != this.pass_recovery2) {
        Swal.fire({
          title: this.$i18n.t('atention'),
          text: this.$i18n.t('password_mismatch'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
      }
      else if (this.pass_recovery == "" || this.pass_recovery2 == "") {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_password'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
      }
      else {
        this.progress1 = true

        let form = {
          id_user: this.id_user_recovery,
          password: this.pass_recovery,
        }

        this.SetNewPassword(form)
          .then((response) => {
            if (response.code == 200) {
              this.$nextTick(() => {
                this.$bvModal.hide("modal-recovery");
              });
              
              Swal.fire({
                title: this.$i18n.t('password_recovered'),
                text: this.$i18n.t('login_with_new_password'),
                confirmButtonColor: "#6a71ec",
                icon: "success",
              });
              
              this.progress1 = false;
              this.step = 3;
            }
            else {
              this.progress1 = false;

              Swal.fire({
                title: this.$i18n.t('atention'),
                text: this.$i18n.t('password_not_recovered') + '. ' + this.$i18n.t('try_again'),
                confirmButtonColor: "#6a71ec",
                icon: "warning",
              });
            }
          })
          .catch((error) => {
            console.log(error);

            Swal.fire({
              title: "Error",
              text: this.$i18n.t('an_error_has_occurred'),
              confirmButtonColor: "#6a71ec",
              icon: "error",
            });

            this.progress1 = false;
          });
      }
    },
  },
}

</script>

<style>

.fondo {
  background-color: #f8f8f8;
  height: 100vh;
  overflow: auto;
}

</style>
<template>
  <div class="Begin"></div>
</template>

<script>
// @ is an alias to /src
import Cookies from "js-cookie";

export default {
  name: "Begin",
  components: {},
  mounted() {
    if (Cookies.get("access_token") != null) {
      this.$router.push("/home")
    }
    else {
      this.$router.push("/login")
    }
  },
};
</script>

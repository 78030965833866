/* eslint-disable */
import Vue from 'vue'
import * as Config from "../config"
import Cookies from "js-cookie"
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    me: [],
    me_user: [],
  },
  mutations: {
    LOGIN(state, data) {
      const access_token = data.access_token.token;
      const full_name = data.first_name + ' ' + data.last_name;

      Cookies.set("access_token", access_token, { expires: 1 });
      Cookies.set("full_name", full_name)
      Cookies.set("id_user", data.id)
      Cookies.set("id_role", data.id_role)
      Cookies.set("create_polls", data.create_polls)

      state.me = data
    },
    LOGOUT(state) {
      Cookies.remove("access_token")
      Cookies.remove("full_name")
      Cookies.remove("id_user")
      Cookies.remove("id_role")
      Cookies.remove("create_polls")
      state.me = []
    },
  },
  actions: {
    //AUTHENTICATION
    Login({ commit }, form) {
      return new Promise((resolve, reject) => {
        axios
          .post(Config.apiPath + "/auth/login", form)
          .then(response => {
            if (response.data.code == 200) {
              if (response.data.data.id_role == 1 || response.data.data.stations.length > 0) {
                commit("LOGIN", response.data.data)
              }
            }

            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          });
      });
    },
    Logout({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post(Config.apiPath + "/auth/logout")
          .then(response => {
            if (response.data.code === 200) {
              commit("LOGOUT");
              resolve(response.data);
            }
            else {
              reject(response.data);
            }
          })
          .catch(error => {
            reject(error)
          });
      });
    },
    ChangePassword(form) {
      return new Promise((resolve, reject) => {
        axios
          .patch(Config.apiPath + "/user/change_password/" + form.id, form.params)
          .then(response => {
            resolve(response.data);

          })
          .catch(error => {
            reject(error);
          });
      });
    },
    RecoveryPassword(form) {
      return new Promise((resolve, reject) => {
        axios
          .post(Config.apiPath + "/security/verification_code/send", form)
          .then(response => {
            resolve(response.data);

          })
          .catch(error => {
            reject(error);
          });
      });
    },
    VerifyPassToken(form) {
      return new Promise((resolve, reject) => {
        axios
          .post(Config.apiPath + "/security/verification_code/verify", form)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    SetNewPassword(form) {
      return new Promise((resolve, reject) => {
        axios
          .put(Config.apiPath + "/security/set_new_password", form)
          .then(response => {
            resolve(response.data);

          })
          .catch(error => {
            reject(error);
          });
      });
    },

    //HOME PAGE (COUNTERS)
    GetHome() {
      return new Promise((resolve, reject) => {
        axios
          .get(Config.apiPath + "/home")
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    //GENERAL GET
    Get({ commit }, endpoint) {
      
      return new Promise((resolve, reject) => {
        axios
          .get(Config.apiPath + "/" + endpoint)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    //MASTERS
    //GET ALL
    GetAllMaster({ commit }, form) {

      return new Promise((resolve, reject) => {
        axios
          .get(Config.apiPath + `/master/${form.element}/all`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    //SAVE
    SaveMaster({ commit }, form) {

      return new Promise((resolve, reject) => {
        axios
          .post(Config.apiPath + `/master/${form.element}/save`, form.params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    //UPDATE
    UpdateMaster({ commit }, form) {

      return new Promise((resolve, reject) => {
        axios
          .put(Config.apiPath + `/master/${form.element}/update/${form.id}`, form.params)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    //CHANGE STATUS
    ChangeStatusMaster({ commit }, form) {

      return new Promise((resolve, reject) => {
        axios
          .put(Config.apiPath + `/master/${form.element}/change_status/${form.id}/${form.status}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    //MAIN CRUD
    GetAll({ commit }, form) {
      /*
      form = {
        element: 'product',
        id?: 1,
        status?: 1,
        is_master?: false,
        query: {
          active?: 1,
          query?: 'hola',
          page?: 1,
          limit?: 10,
          order_key?: 'id',
          order_value?: 'desc'
        },
        columns: ['id_category','id_product']
        params: {
          id_category: 1,
          id_product: 1
        },
        data: {
          (Datos a la hora de un POST o un PUT)
        }
      }
      */

      let is_master = (form.is_master) ? '/master' : ''
      let query_params = ''
      let params = ['active', 'query', 'page', 'limit', 'order_key', 'order_value']

      if (form.query) {
        for (let param of params) {
         
          if (form.query[param])                                  query_params += param + '=' + form.query[param] + '&'
          else if (!form.query[param] && form.query[param] == 0)  query_params += param + '=' + form.query[param] + '&'
        }
      }

      if (form.columns) {
        for (let col of form.columns) {
          if (form.params[col])                                   query_params += col + '=' + form.params[col] + '&'
          else if (!form.params[col] && form.params[col] == 0)    query_params += col + '=' + form.params[col] + '&'
        }
      }

      return new Promise((resolve, reject) => {
        axios
          .get(Config.apiPath + `${is_master}/${form.element}/all?${query_params}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GetByID({ commit }, form) {
      /*
      form = {
        element: 'product',
        id?: 1,
        status?: 1,
        is_master?: false,
        query: {
          active?: 1,
          query?: 'hola',
          page?: 1,
          limit?: 10,
          order_key?: 'id',
          order_value?: 'desc'
        },
        columns: ['id_category','id_product']
        params: {
          id_category: 1,
          id_product: 1
        },
        data: {
          (Datos a la hora de un POST o un PUT)
        }
      }
      */

      let is_master = (form.is_master) ? '/master' : ''

      return new Promise((resolve, reject) => {
        axios
          .get(Config.apiPath + `${is_master}/${form.element}/${form.id}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    Save({ commit }, form) {

      /*
      form = {
        element: 'product',
        id?: 1,
        status?: 1,
        is_master?: false,
        query: {
          active?: 1,
          query?: 'hola',
          page?: 1,
          limit?: 10,
          order_key?: 'id',
          order_value?: 'desc'
        },
        columns: ['id_category','id_product']
        params: {
          id_category: 1,
          id_product: 1
        },
        data: {
          (Datos a la hora de un POST o un PUT)
        }
      }
      */

      let is_master = (form.is_master) ? '/master' : ''

      return new Promise((resolve, reject) => {
        axios
          .post(Config.apiPath + `${is_master}/${form.element}/save`, form.data)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    SaveFormData({ commit }, form) {

      let is_master = (form.is_master) ? '/master' : ''

      return new Promise((resolve, reject) => {
        axios(
          {
            method: 'post',
            url: Config.apiPath + `${is_master}/${form.element}/save`,
            data: form.data,
            headers: {'Content-Type': 'multipart/form-data' }
            })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    SaveFormDataComplete({ commit }, form) {

      let is_master = (form.is_master) ? '/master' : ''

      return new Promise((resolve, reject) => {
        axios(
          {
            method: 'post',
            url: Config.apiPath + `${is_master}/${form.element}`,
            data: form.data,
            headers: {'Content-Type': 'multipart/form-data' }
            })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    UpdateFormData({ commit }, form) {

      let is_master = (form.is_master) ? '/master' : ''

      return new Promise((resolve, reject) => {
        axios
          axios(
            {
              method: 'put',
              url: Config.apiPath + `${is_master}/${form.element}/${form.update}/${form.id}`,
              data: form.data,
              headers: {'Content-Type': 'multipart/form-data' }
              })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    UpdateFormData2({ commit }, form) {

      let is_master = (form.is_master) ? '/master' : ''

      return new Promise((resolve, reject) => {
        axios
        axios(
          {
            method: 'put',
            url: Config.apiPath + `${is_master}/${form.element}/${form.update}`,
            data: form.data,
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    Update({ commit }, form) {

      let is_master = (form.is_master) ? '/master' : ''

      return new Promise((resolve, reject) => {
        axios
          .put(Config.apiPath + `${is_master}/${form.element}/${form.update}/${form.id}`, form.data)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    ChangeStatus({ commit }, form) {

      /*
      form = {
        element: 'product',
        id?: 1,
        status?: 1,
        is_master?: false,
        query: {
          active?: 1,
          query?: 'hola',
          page?: 1,
          limit?: 10,
          order_key?: 'id',
          order_value?: 'desc'
        },
        columns: ['id_category','id_product']
        params: {
          id_category: 1,
          id_product: 1
        },
        data: {
          (Datos a la hora de un POST o un PUT)
        }
      }
      */

      let is_master = (form.is_master) ? '/master' : ''

      return new Promise((resolve, reject) => {
        axios
          .put(Config.apiPath + `${is_master}/${form.element}/change_status/${form.id}/${form.status}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },
  modules: {
  }
})

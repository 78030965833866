<template>
  <div id="MasterCRUD">
    <br />
    <h1
      class="text2"
      style="color: #000000; margin-left: 10px; text-align: left;"
    >
      <strong>{{ title_plural_uppercase }}</strong>
    </h1>
    <div class="content">
      <b-card style="border: none; background-color: #F2F2F2">
        <b-row>
          <b-col md="12">
            <h4 class="my-1" style="color: #000000; text-align: left">
              <strong>TOTAL:</strong>
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3" class="my-1">
            <div
              class="py-1"
              style="box-shadow: 0px 10px 8px #B0B0B0; border-radius: 24px; width=100%; color: #FFFFFF; border-color: #6a71ec; background-color: #6a71ec"
            >
              <span v-show="!isBusy" style="font-size: 24px;">{{
                format_num(total)
              }}</span>
              <span v-show="isBusy" style="font-size: 24px;">--</span>
            </div>
          </b-col>
          <b-col md="6" class="my-1"> </b-col>
          <b-col md="3" class="my-1">
            <b-button
              block
              pill
              v-b-modal.modal-add
              style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec"
            >
              {{ $t("add").toUpperCase() }}
              <font-awesome-icon
                style="text-align: right; color: white; font-size: 20px"
                icon="plus-circle"
              />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <!-- <b-col md="1" class="my-1">
             <b-form-group label-for="countrySelect" class="mb-0">
                <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('country') + ':' }}</p>
                <b-form-select id="countrySelect" v-model="id_country" size="sm" :options="countries"
                  @change="PageChanged"
                  style="box-shadow: 0px 10px 8px #B0B0B0; border-color: #000000; background-color: #E0E0E0;" />
              </b-form-group>
            </b-col>-->
          <!-- <b-col md="4" class="my-1">
              <b-form-group label-for="categorySelect" class="mb-0">
                <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('category') + ':' }}</p>
                <b-form-select id="categorySelect" v-model="id_ad_banner_category" size="sm" :options="categories"
                  @change="PageChanged"
                  style="box-shadow: 0px 10px 8px #B0B0B0; border-color: #000000; background-color: #E0E0E0;" />
              </b-form-group>
            </b-col>-->
          <b-col md="4" class="my-1">
            <b-form-group label-for="filterInput" class="mb-0">
              <p
                class="text-left my-0 py-0"
                style="color: #000000; font-size: 14px;"
              >
                {{ $t("searcher") + ":" }}
              </p>
              <b-input-group
                size="sm"
                style="box-shadow: 0px 10px 8px #B0B0B0;"
              >
                <b-input-group-prepend>
                  <span
                    class="input-group-text"
                    style="border-color: #000000; background-color: #E0E0E0; border-right: none;"
                  >
                    <font-awesome-icon
                      @click="SearchInput"
                      style="cursor: pointer; color: #000000; font-size: 17px"
                      icon="search"
                    />
                  </span>
                </b-input-group-prepend>
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  @keyup.enter="SearchInput"
                  type="text"
                  :placeholder="$t('search') + '...'"
                  style="border-color: #000000; background-color: #E0E0E0; border-right: none; border-left: none;"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="ClearFilter"
                    class="input-group-text"
                    style="border-color: #000000; background-color: #E0E0E0; border-left: none;"
                  >
                    <font-awesome-icon
                      style="color: #000000; font-size: 17px"
                      icon="times-circle"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4" class="my-1"></b-col>
          <b-col md="4" class="my-1">
            <b-form-group label-for="allPages" class="mb-0">
              <p
                class="text-left my-0 py-0"
                style="color: #000000; font-size: 14px;"
              >
                {{ $t("current_page") + ":" }}
              </p>
              <b-pagination
                id="allPages"
                v-model="currentPage"
                :total-rows="total"
                :per-page="8"
                size="sm"
                align="fill"
                @input="PageChanged"
                style="box-shadow: 0px 10px 8px #B0B0B0;"
              ></b-pagination>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="!isBusy2" class="mt-3 px-3">
          <b-col
            sm="6"
            v-for="data of elementsTMP"
            v-bind:key="data.id"
            class="mb-3"
          >
            <div
              v-if="data.active == 1"
              style="box-shadow: 0px 10px 15px #000000; border: none; border-radius: 20px 20px 20px 20px; background-color: #6a71ec"
            >
              <div class="text-center">
                <a :href="data.url" target="_blank">
                  <img
                    :src="data.url_image"
                    style="aspect-ratio: 1; border-radius: 20px 20px 0px 0px; object-fit: cover; background-color: #FFFFFF; max-height: 150px"
                    width="100%"
                    alt=""
                  />
                </a>
              </div>

              <b-row>
                <b-col md="6">
                  <div class="text-center p-0 my-0">
                    <p class="py-1 my-0" style="color: #FFFFFF">
                      {{
                        data.es_title.length > 50
                          ? data.es_title.substring(0, 50) + "..."
                          : data.es_title
                      }}
                    </p>
                  </div>
                </b-col>
                <b-col md="6">
                  <div class="text-center p-0 my-0">
                    <p class="py-1 my-0" style="color: #FFFFFF">
                      {{
                        data.en_title.length > 50
                          ? data.en_title.substring(0, 50) + "..."
                          : data.en_title
                      }}
                    </p>
                  </div>
                </b-col>
              </b-row>

              <b-row class="ml-1 mr-1 my-0 py-2">
                <b-col md="2" class="mx-0 px-0">
                  <button
                    class="py-1 my-1"
                    @click="UpdateTMP(data)"
                    block
                    style="width: 100%; border-radius: 20px; border: none; background-color: #6a71ec"
                  >
                    <font-awesome-icon
                      style="color: #000000; font-size: 20px"
                      icon="edit"
                    />
                  </button>
                </b-col>
                <b-col md="2" class="mx-0 px-0">
                  <button
                    class="mx-0 px-0 py-1 my-1"
                    @click="ChangeActive(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #6a71ec"
                  >
                    <font-awesome-icon
                      style="color: #000000; font-size: 20px"
                      icon="check-square"
                    />
                  </button>
                </b-col>
                <b-col md="2" class="mx-0 px-0">
                  <button
                    class="py-1 my-1"
                    @click="UpdateLinks(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #6a71ec"
                  >
                    <font-awesome-icon
                      style="color: #000000; font-size: 20px"
                      icon="link"
                    />
                  </button>
                </b-col>
                <b-col md="2" class="mx-0 px-0">
                  <button
                    class="py-1 my-1"
                    @click="UpdateCountriesTMP(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #6a71ec"
                  >
                    <font-awesome-icon
                      style="color: #000000; font-size: 20px"
                      icon="flag"
                    />
                  </button>
                </b-col>
                <b-col md="2" class="mx-0 px-0">
                  <button
                    class="py-1 my-1"
                    @click="Delete(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #6a71ec"
                  >
                    <font-awesome-icon
                      style="color: #000000; font-size: 20px"
                      icon="trash"
                    />
                  </button>
                </b-col>
                <b-col md="2" class="mx-0 px-0">
                  <button
                    class="py-1 my-1"
                    @click="sendNotificationTMP(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #6a71ec"
                  >
                    <font-awesome-icon
                      style="color: #000000; font-size: 20px"
                      icon="bell"
                    />
                  </button>
                </b-col>
              </b-row>

              <div
                class="text-center p-0"
                style="border: none; border-radius: 0px 0px 20px 20px; background-color: #6a71ec"
              >
                <button
                  class="py-1"
                  block
                  style="cursor: default; width: 100%; border-radius: 0px 0px 20px 20px; border: none; background-color: #000000"
                >
                  <span style="color: #FFFFFF"
                    ><strong>{{ format_num(data.clicks) }}</strong></span
                  >
                  <span>
                    <font-awesome-icon
                      class="ml-2"
                      style="color: #ffa500; font-size: 14px"
                      icon="users"
                    />
                  </span>
                </button>
              </div>
            </div>

            <div
              v-else
              style="box-shadow: 0px 10px 15px #000000; border: none; border-radius: 20px; background-color: #B0B0B0"
            >
              <div class="text-center">
                <a :href="data.url" target="_blank">
                  <img
                    :src="data.url_image"
                    style="aspect-ratio: 1;border-radius: 20px 20px 0px 0px; object-fit: cover; background-color: #FFFFFF; max-height: 150px"
                    width="100%"
                    alt=""
                  />
                </a>
              </div>

              <b-row>
                <b-col md="6">
                  <div class="text-center p-0 my-0">
                    <p class="py-1 my-0" style="color: #FFFFFF">
                      {{
                        data.es_title.length > 50
                          ? data.es_title.substring(0, 50) + "..."
                          : data.es_title
                      }}
                    </p>
                  </div>
                </b-col>
                <b-col md="6">
                  <div class="text-center p-0 my-0">
                    <p class="py-1 my-0" style="color: #FFFFFF">
                      {{
                        data.en_title.length > 50
                          ? data.en_title.substring(0, 50) + "..."
                          : data.en_title
                      }}
                    </p>
                  </div>
                </b-col>
              </b-row>

              <b-row class="ml-1 mr-1 my-0 py-2">
                <b-col md="2" class="mx-0 px-0">
                  <button
                    class="py-1 my-1"
                    @click="UpdateTMP(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #B0B0B0"
                  >
                    <font-awesome-icon
                      style="color: #000000; font-size: 20px"
                      icon="edit"
                    />
                  </button>
                </b-col>
                <b-col md="2" class="mx-0 px-0">
                  <button
                    class="py-1 my-1"
                    @click="ChangeActive(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #B0B0B0"
                  >
                    <font-awesome-icon
                      style="color: #000000; font-size: 20px"
                      icon="minus-square"
                    />
                  </button>
                </b-col>
                <b-col md="2" class="mx-0 px-0">
                  <button
                    class="py-1 my-1"
                    @click="UpdateLinks(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #B0B0B0"
                  >
                    <font-awesome-icon
                      style="color: #000000; font-size: 20px"
                      icon="link"
                    />
                  </button>
                </b-col>
                <b-col md="2" class="mx-0 px-0">
                  <button
                    class="py-1 my-1"
                    @click="UpdateCountriesTMP(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #B0B0B0"
                  >
                    <font-awesome-icon
                      style="color: #000000; font-size: 20px"
                      icon="flag"
                    />
                  </button>
                </b-col>
                <b-col md="2" class="mx-0 px-0">
                  <button
                    class="py-1 my-1"
                    @click="Delete(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #B0B0B0"
                  >
                    <font-awesome-icon
                      style="color: #000000; font-size: 20px"
                      icon="trash"
                    />
                  </button>
                </b-col>

                <b-col md="2" class="mx-0 px-0">
                  <button
                    class="py-1 my-1"
                    @click="sendNotificationTMP(data)"
                    style="width: 100%; border-radius: 20px; border: none; background-color: #B0B0B0"
                  >
                    <font-awesome-icon
                      style="color: #000000; font-size: 20px"
                      icon="bell"
                    />
                  </button>
                </b-col>
              </b-row>

              <div
                class="text-center p-0"
                style="border: none; border-radius: 0px 0px 20px 20px; background-color: #000000"
              >
                <button
                  class="py-1"
                  block
                  style="cursor: default; width: 100%; border-radius: 0px 0px 20px 20px; border: none; background-color: #000000"
                >
                  <span style="color: #FFFFFF"
                    ><strong>{{ format_num(data.clicks) }}</strong></span
                  >
                  <span>
                    <font-awesome-icon
                      class="ml-2"
                      style="color: #ffa500; font-size: 14px"
                      icon="users"
                    />
                  </span>
                </button>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="isBusy2" class="mt-3" align-h="center">
          <b-spinner
            class="m-1"
            style="width: 3rem; height: 3rem; font-size: 20px; color: #6a71ec"
          >
          </b-spinner>
        </b-row>
        <b-row
          v-if="!isBusy2 && elementsTMP.length == 0"
          class="mt-3"
          align-h="center"
        >
          <p>{{ $t("no_results") }}</p>
        </b-row>
      </b-card>
    </div>

    <b-modal
      id="modal-add"
      ref="modal"
      title-class="text-font-black"
      :title="$t('add') + ' ' + title_lowcase"
      modal-class="model-content"
      hide-footer
      content-class="shadow"
      @show="resetModal"
      @hidden="resetModal"
      size="md"
    >
      <b-container>
        <b-row align-v="center">
          <b-col lg="12" class="mb-2">
            <b-row align-v="center">
              <b-col lg="9" class="mb-2">
                <b-form-group :label="$t('username') + ':'" class="mb-0">
                  <b-form-input
                    type="text"
                    v-model="user_finded.username"
                    :placeholder="$t('username')"
                    style="border-radius: 20px; color: #000000;"
                    required
                  disabled
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3" class="mb-2 pt-4"> 
                <b-button
              block
              pill
              v-b-modal.modal-list-users
              style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 15px; border-color: #6a71ec; background-color: #6a71ec"
            >
              {{ $t("find").toUpperCase() }}
              <font-awesome-icon
                style="text-align: right; color: white; font-size: 15px"
                icon="search"
              />
            </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('es_title') + ':'" class="mb-0">
              <b-form-input
                type="text"
                v-model="element_add.es_title"
                :placeholder="$t('es_title')"
                style="border-radius: 20px; color: #000000;"
                required
                @keyup.enter="SaveElement()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('en_title') + ':'" class="mb-0">
              <b-form-textarea
                type="text"
                v-model="element_add.en_title"
                :placeholder="$t('en_title')"
                style="border-radius: 20px; color: #000000;"
                :rows="2"
                required
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('es_description') + ':'" class="mb-0">
              <b-form-textarea
                type="text"
                v-model="element_add.es_description"
                :placeholder="$t('es_description')"
                style="border-radius: 20px; color: #000000;"
                :rows="2"
                required
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('en_description') + ':'" class="mb-0">
              <b-form-textarea
                type="text"
                v-model="element_add.en_description"
                :placeholder="$t('en_description')"
                style="border-radius: 20px; color: #000000;"
                :rows="2"
                required
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('opacity') + ':'" class="mb-0">
              <b-form-input
                type="number"
                min="0"
                max="100"
                v-model="element_add.opacity"
                :placeholder="$t('opacity')"
                style="border-radius: 20px; color: #000000;"
                required
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group label="Banner:" class="mb-0">
              <b-form-file
                v-model="fileToUpload"
                accept="image/*"
                :state="Boolean(fileToUpload)"
                v-on:change="onFileChange"
                :placeholder="$t('select_file') + '...'"
                :drop-placeholder="$t('drop_file') + '...'"
                :browse-text="$t('browse')"
              >
                <img id="blah" src="#" alt="your image" />
              </b-form-file>

              <div v-if="fileToUpload.name" class="mt-3 text-center">
                <span v-if="fileToUpload.name">{{
                  $t("selected_image") + ":"
                }}</span>
                {{ fileToUpload ? fileToUpload.name : "" }}
                <br v-if="fileToUpload.name" />
                <br v-if="fileToUpload.name" />

                <img
                  :src="preview_image"
                  style="max-height: 150px; max-width: 100%"
                />
              </div>
            </b-form-group>
          </b-col>
          <br />
          <b-col lg="12">
            <b-button
              block
              pill
              v-show="!progress"
              class="my-3"
              :disabled="element_add == '' || element_add == ''"
              @click="SaveElement()"
              style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec"
            >
              {{ $t("add").toUpperCase() }}
            </b-button>
          </b-col>
          <b-col>
            <div
              v-show="progress"
              class="text-center my-3"
              style="color: #6a71ec"
            >
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t("loading") }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal
      id="modal-update"
      ref="modal"
      title-class="text-font-black"
      :title="$t('update') + ' ' + title_lowcase"
      modal-class="model-content"
      hide-footer
      content-class="shadow"
      @hidden="resetModal"
      size="md"
    >
      <b-container>
        <b-row align-v="center">
          <b-col lg="12" class="mb-2">
            <b-row align-v="center">
              <b-col lg="9" class="mb-2">
                <b-form-group :label="$t('username') + ':'" class="mb-0">
                  <b-form-input
                    type="text"
                    v-model="user_finded.username"
                    :placeholder="$t('username')"
                    style="border-radius: 20px; color: #000000;"
                    required
                  disabled
                  >
                  </b-form-input>
                 
                </b-form-group>
              </b-col>
              <b-col lg="3" class="mb-2 pt-4"> 
                <b-button
              block
              pill
              v-b-modal.modal-list-users
              style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 15px; border-color: #6a71ec; background-color: #6a71ec"
            >
              {{ $t("find").toUpperCase() }}
              <font-awesome-icon
                style="text-align: right; color: white; font-size: 15px"
                icon="search"
              />
            </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('es_title') + ':'" class="mb-0">
              <b-form-input
                type="text"
                v-model="element_update.es_title"
                :placeholder="$t('es_title')"
                style="border-radius: 20px; color: #000000;"
                required
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('en_title') + ':'" class="mb-0">
              <b-form-input
                type="text"
                v-model="element_update.en_title"
                :placeholder="$t('en_title')"
                style="border-radius: 20px; color: #000000;"
                required
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('es_description') + ':'" class="mb-0">
              <b-form-input
                type="text"
                v-model="element_update.es_description"
                :placeholder="$t('en_title')"
                style="border-radius: 20px; color: #000000;"
                required
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('en_description') + ':'" class="mb-0">
              <b-form-input
                type="text"
                v-model="element_update.en_description"
                :placeholder="$t('en_title')"
                style="border-radius: 20px; color: #000000;"
                required
              >
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('opacity') + ':'" class="mb-0">
              <b-form-input
                type="number"
                min="0"
                max="100"
                v-model="element_update.opacity"
                :placeholder="$t('opacity')"
                style="border-radius: 20px; color: #000000;"
                required
              >
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="12" class="mb-2">
            <b-form-group :label="$t('url_image') + ':'" class="mb-0">
              <img :src="element_update.url_image" class="img-fluid" />
            </b-form-group>
          </b-col>

          <b-col lg="12" class="mb-2">
            <b-form-group label="Update Banner:" class="mb-0">
              <b-form-file
                v-model="fileToUploadUpdate"
                accept="image/*"
                :state="Boolean(fileToUploadUpdate)"
                v-on:change="onFileChange"
                :placeholder="$t('select_file') + '...'"
                :drop-placeholder="$t('drop_file') + '...'"
                :browse-text="$t('browse')"
              >
                <img id="blah" src="#" alt="your image" />
              </b-form-file>
              <div class="mt-3 text-center">
                <span v-if="fileToUploadUpdate.name">{{
                  $t("selected_image") + ":"
                }}</span>
                {{ fileToUploadUpdate ? fileToUploadUpdate.name : "" }}
                <br v-if="fileToUploadUpdate.name" />
                <br v-if="fileToUploadUpdate.name" />

                <img
                  v-if="fileToUploadUpdate.name"
                  :src="preview_image"
                  style="max-height: 150px; max-width: 100%"
                />
                <img
                  v-else
                  :src="element_update.url_banner"
                  style="max-height: 150px; max-width: 100%"
                />
              </div>
            </b-form-group>
          </b-col>
          <br />
          <b-col lg="12">
            <b-button
              block
              pill
              v-show="!progress"
              class="my-3"
              @click="UpdateElement()"
              style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec"
            >
              {{ $t("update").toUpperCase() }}
            </b-button>
          </b-col>
          <b-col>
            <div
              v-show="progress"
              class="text-center my-3"
              style="color: #6a71ec"
            >
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t("loading") }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal
      id="modal-links"
      ref="modal"
      title-class="text-font-black"
      :title="$t('update') + ' ' + title_lowcase"
      modal-class="model-content"
      hide-footer
      content-class="shadow"
      @hidden="resetModal"
      size="md"
    >
      <b-container>
        <b-row>
          <b-col md="8" class="my-1"> </b-col>
          <b-col md="4" class="my-1">
            <b-button
              block
              pill
              v-b-modal.modal-add-links
              style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 15px; border-color: #6a71ec; background-color: #6a71ec"
            >
              {{ $t("add_url").toUpperCase() }}
              <font-awesome-icon
                style="text-align: right; color: white; font-size: 15px"
                icon="plus-circle"
              />
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="highlight_links.length === 0">
          <p>{{$t('no_links')}}</p>
        </b-row>

        <div align-v="center" v-for="hlink in highlight_links" :key="hlink.id">
          <b-card style="max-width: 100%;" class="mb-2">
            <b-row>
              <b-col md="8" class="mb-2">
                <b-form-group :label="$t('url') + ':'" class="mb-0">
                  <b-form-input
                    type="text"
                    v-model="hlink.url"
                    :placeholder="$t('url')"
                    style="border-radius: 20px; color: #000000;"
                    required
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="4" class="mb-2">
                <b-form-group
                  :label="'Actual ' + $t('url_image') + ':'"
                  class="mb-0"
                >
                  <b-img
                    thumbnail
                    fluid
                    :src="hlink.url_image"
                    alt="Image 1"
                    style="max-width: 100px"
                  ></b-img>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-2">
                <b-form-group label="Update Banner:" class="mb-0">
                  <b-form-file
                    v-model="fileToUploadUpdate"
                    accept="image/*"
                    :state="Boolean(fileToUploadUpdate)"
                    v-on:change="onFileChange"
                    :placeholder="$t('select_file') + '...'"
                    :drop-placeholder="$t('drop_file') + '...'"
                    :browse-text="$t('browse')"
                  >
                    <img id="blah" src="#" alt="your image" />
                  </b-form-file>
                  <div class="mt-3 text-center">
                    <span v-if="fileToUploadUpdate.name">{{
                      $t("selected_image") + ":"
                    }}</span>
                    {{ fileToUploadUpdate ? fileToUploadUpdate.name : "" }}
                    <br v-if="fileToUploadUpdate.name" />
                    <br v-if="fileToUploadUpdate.name" />

                    <img
                      v-if="fileToUploadUpdate.name"
                      :src="preview_image"
                      style="max-height: 150px; max-width: 100%"
                    />
                  </div>
                </b-form-group>
              </b-col>

              <b-col md="9">
                <b-button
                  block
                  pill
                  v-show="!progress"
                  class="my-1"
                  @click="UpdateElementLink(hlink)"
                  style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec"
                >
                  {{ $t("update").toUpperCase() }}
                </b-button>
              </b-col>
             
              <b-col md="3">
                <b-button
              block
              class="my-1"
              pill
              @click="deleteHLink(hlink)"
              style="box-shadow: 0px 5px 5px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec"
            >
              
              <font-awesome-icon
                style="text-align: right; color: white; font-size: 15px"
                icon="trash"
              />
            </b-button>
              </b-col>
              <b-col md="12">
                <div
                  v-show="progress"
                  class="text-center my-3"
                  style="color: #6a71ec"
                >
                  <b-spinner small class="align-middle"></b-spinner>
                  <strong class="ml-2">{{ $t("loading") }}...</strong>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-container>
    </b-modal>

    <b-modal
      id="modal-add-links"
      ref="modal"
      title-class="text-font-black"
      :title="$t('update') + ' ' + title_lowcase"
      modal-class="model-content"
      hide-footer
      content-class="shadow"
      @hidden="resetModal"
      size="md"
    >
      <b-container>
        <b-card style="max-width: 100%;" class="mb-2">
          <b-row>
            <b-col md="12" class="mb-2">
              <b-form-group :label="$t('url') + ':'" class="mb-0">
                <b-form-input
                  type="text"
                  v-model="link_add.url"
                  :placeholder="$t('url')"
                  style="border-radius: 20px; color: #000000;"
                  required
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="12" class="mb-2">
              <b-form-group label="Update Banner:" class="mb-0">
                <b-form-file
                  v-model="fileToUploadUpdate"
                  accept="image/*"
                  :state="Boolean(fileToUploadUpdate)"
                  v-on:change="onFileChange"
                  :placeholder="$t('select_file') + '...'"
                  :drop-placeholder="$t('drop_file') + '...'"
                  :browse-text="$t('browse')"
                >
                  <img id="blah" src="#" alt="your image" />
                </b-form-file>
                <div class="mt-3 text-center">
                  <span v-if="fileToUploadUpdate.name">{{
                    $t("selected_image") + ":"
                  }}</span>
                  {{ fileToUploadUpdate ? fileToUploadUpdate.name : "" }}
                  <br v-if="fileToUploadUpdate.name" />
                  <br v-if="fileToUploadUpdate.name" />

                  <img
                    v-if="fileToUploadUpdate.name"
                    :src="preview_image"
                    style="max-height: 150px; max-width: 100%"
                  />
                </div>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-button
                block
                pill
                v-show="!progress"
                class="my-1"
                @click="SaveElementLink(hlink)"
                style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec"
              >
                {{ $t("add").toUpperCase() }}
              </b-button>
            </b-col>
            <b-col md="12">
              <div
                v-show="progress"
                class="text-center my-3"
                style="color: #6a71ec"
              >
                <b-spinner small class="align-middle"></b-spinner>
                <strong class="ml-2">{{ $t("loading") }}...</strong>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-container>
    </b-modal>

    <b-modal
      id="modal-list-users"
      ref="modal"
      title-class="text-font-black"
      :title="$t('users') + ' ' + title_lowcase"
      modal-class="model-content"
      hide-footer
      content-class="shadow"
      @hidden="resetModalUsers"
      size="xl"
    >
      <b-container>
        <b-card style="max-width: 100%;" class="mb-2">
          <b-row>
            <b-row class="mt-2">
          <b-col md="4" class="my-1">
            <b-form-group label-for="filterInput" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('searcher') + ':' }}</p>
              <b-input-group size="sm">
                <b-input-group-prepend>
                  <span class="input-group-text"
                    style="border-color: #000000; background-color: #E0E0E0; border-right: none;">
                    <font-awesome-icon @click="SearchInput" style="cursor: pointer; color: #000000; font-size: 17px"
                      icon="search" />
                  </span>
                </b-input-group-prepend>
                <b-form-input id="filterInput" v-model="filter" @keyup.enter="SearchInput" type="text"
                  :placeholder="$t('search') + '...'"
                  style="border-color: #000000; background-color: #E0E0E0; border-right: none; border-left: none;" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="ClearFilter" class="input-group-text"
                    style="border-color: #000000; background-color: #E0E0E0; border-left: none;">
                    <font-awesome-icon style="color: #000000; font-size: 17px" icon="times-circle" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group label-for="per_pageSelect" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('per_page') + ':' }}</p>
              <b-form-select id="per_pageSelect" v-model="per_page" size="sm" :options="page_options"
                @change="PageChanged" style="border-color: #000000; background-color: #E0E0E0;" />
            </b-form-group>
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group label-for="allPages" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('current_page') + ':' }}</p>
              <b-pagination id="allPages" v-model="current_page" :total-rows="total" :per-page="per_page"
                aria-controls="my-table" size="sm" align="fill" @input="PageChanged()" class="variable-width2">
              </b-pagination>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3 px-3">
          <b-col sm="12">
            <div
              style="box-shadow: 0px 10px 8px #B0B0B0; border: 3px; border-color: #000000; background-color: #FFFFFF">
              <b-table id="my-table" show-empty small fixed stacked="md" sticky-header="true" hover thead-class="header"
                :primary-key="id" :items="usersTMP" :fields="field" :current-page="current_page" :per-page="0"
                :empty-html="emptyHTML" :empty-filtered-html="emptyFilteredHTML" :busy="isBusy">
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle" style="color: #6a71ec;"></b-spinner>
                    <strong> {{ $t('loading') }}...</strong>
                  </div>
                </template>

                <template v-slot:cell(username)="row">
                  <img :src="row.item.imgkit_profile_picture + '?tr=n-xsmall_thumb'" alt="" height="20px"
                    style="border-radius: 50%; aspect-ratio: 1; object-fit: cover;">
                  <span class="my-0 py-0">
                    {{ '@' + row.item.username }}{{ row.item.id == id_user ? ' (' + $t('you').toLowerCase() + ')' : ''
                    }}
                  </span>
                  <font-awesome-icon v-if="row.item.verified == 1" class="mr-1" icon="check-circle"
                    style="color: #6a71ec; font-size: 17px" />
                  <font-awesome-icon v-if="row.item.id_role == 3" class="mr-1" icon="star"
                    style="color: #6a71ec; font-size: 17px" />
                  <font-awesome-icon v-if="(row.item.content_creator == 1)" style="color: #6a71ec; font-size: 17px"
                    icon="paint-brush" />
                </template>

                <template v-slot:cell(followers)="row">
                  <p class="my-0 py-0">
                    {{ format_num(row.item.followers) }}
                  </p>
                </template>

                <template v-slot:cell(following)="row">
                  <p class="my-0 py-0">
                    {{ format_num(row.item.following) }}
                  </p>
                </template>

              

                <template v-slot:cell(actions)="row">
             

               

                  <b-button size="sm" variant="white"
                    :title="$t('verify')" @click="selectUser(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="plus-circle" />
                  </b-button>
               

               
                
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
          </b-row>
        </b-card>
      </b-container>
    </b-modal>

    <b-modal
      id="modal-countries"
      ref="modal"
      title-class="text-font-black"
      :title="$t('update') + ' ' + title_lowcase"
      modal-class="model-content"
      hide-footer
      content-class="shadow"
      @hidden="resetModal"
      size="md"
    >
      <b-container>
        <b-row>
          <b-col md="8" class="my-1"> </b-col>
          <b-col md="4" class="my-1">
            <b-button
              block
              pill
              v-b-modal.modal-add-country
              style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 15px; border-color: #6a71ec; background-color: #6a71ec"
            >
              {{ $t("add_country").toUpperCase() }}
              <font-awesome-icon
                style="text-align: right; color: white; font-size: 15px"
                icon="plus-circle"
              />
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="highlight_countries.length === 0">
          <p>{{$t('no_links')}}</p>
        </b-row>

        <div align-v="center" v-for="hcountry in highlight_countries" :key="hcountry.id">
          <b-card style="max-width: 100%;" class="mb-2">
            <b-row>
              <b-col md="8" class="mb-2">
                <b-form-group v-if="hcountry.id_country != 0" :label="$t('country') + ':'" class="mb-0">
                  <b-form-select  v-model="hcountry.country.id" :options="countries"
                style="border-radius: 20px; color: #000000;" required>


              </b-form-select>
                </b-form-group>

                <b-form-group v-if="hcountry.id_country == 0" :label="$t('country') + ':'" class="mb-0">
                  <p><strong>{{ $t('all_country_asigned') }}</strong></p>
                </b-form-group>
              </b-col>

              <b-col md="2" class="mt-4">
                <b-button
              block
              pill
              @click="deleteHCountry(hcountry)"
              style="box-shadow: 0px 5px 5px #B0B0B0; color: #FFFFFF; font-size: 15px; border-color: #6a71ec; background-color: #6a71ec"
            >
              
              <font-awesome-icon
                style="text-align: right; color: white; font-size: 15px"
                icon="trash"
              />
            </b-button>
              </b-col>

           

              <b-col md="12">
                <b-button
                v-if="hcountry.id_country != 0"
                  block
                  pill
                  v-show="!progress"
                  class="my-1"
                  @click="UpdateElementCountry(hcountry)"
                  style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec"
                >
                  {{ $t("update").toUpperCase() }}
                </b-button>
              </b-col>
              <b-col md="12">
                <div
                  v-show="progress"
                  class="text-center my-3"
                  style="color: #6a71ec"
                >
                  <b-spinner small class="align-middle"></b-spinner>
                  <strong class="ml-2">{{ $t("loading") }}...</strong>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-container>
    </b-modal>

    <b-modal
      id="modal-add-country"
      ref="modal"
      title-class="text-font-black"
      :title="$t('add_country')"
      modal-class="model-content"
      hide-footer
      content-class="shadow"
      @hidden="resetModal"
      size="md"
    >
      <b-container>
        <b-card style="max-width: 100%;" class="mb-2">
          <b-row>
            <b-col md="12" class="mb-2">
              <b-form-group class="mb-0">
                <b-form-group :label="$t('country') + ':'" class="mb-0">
                  <b-form-select v-model="country_add.id_country" :options="countries"
                style="border-radius: 20px; color: #000000;" required>
              </b-form-select>
                </b-form-group>
              </b-form-group>
            </b-col>

        

            <b-col md="12">
              <b-button
                block
                pill
                v-show="!progress"
                class="my-1"
                @click="SaveElementCountry()"
                style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec"
              >
                {{ $t("add").toUpperCase() }}
              </b-button>
            </b-col>
            <b-col md="12">
              <div
                v-show="progress"
                class="text-center my-3"
                style="color: #6a71ec"
              >
                <b-spinner small class="align-middle"></b-spinner>
                <strong class="ml-2">{{ $t("loading") }}...</strong>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-container>
    </b-modal>

    <b-modal
      id="modal-add-notification"
      ref="modal"
      title-class="text-font-black"
      :title="$t('send_notification')"
      modal-class="model-content"
      hide-footer
      content-class="shadow"
      @hidden="resetModal"
      size="md"
    >
      <b-container>
        <b-card style="max-width: 100%;" class="mb-2">
          <b-row>
            <b-col md="6" class="mb-2">
              <b-form-group class="mb-0">
                <p>{{$t('es_title')}}: <strong>{{ this.actualHighLight.es_title }}</strong></p>
                
              </b-form-group>
            </b-col>
            <b-col md="6" class="mb-2">
              <b-form-group class="mb-0">
                <p>{{$t('en_title')}}: <strong>{{ this.actualHighLight.en_title }}</strong></p>
              
              </b-form-group>
             
            </b-col>
          
            </b-row>
            <br>
            <p>Please write a notification to send push to the app</p>
            <b-row>
            <b-col md="12" class="mb-2">
              <b-form-group :label="$t('es_title') + ':'" class="mb-0">
                  <b-form-input
                    type="text"
                    v-model="notification_add.es_title"
                    :placeholder="$t('es_title')"
                    style="border-radius: 20px; color: #000000;"
                    required
                  >
                  </b-form-input>                 
                </b-form-group>
            </b-col>

            <b-col md="12" class="mb-2">
              <b-form-group :label="$t('en_title') + ':'" class="mb-0">
                  <b-form-input
                    type="text"
                    v-model="notification_add.en_title"
                    :placeholder="$t('en_title')"
                    style="border-radius: 20px; color: #000000;"
                    required
                  >
                  </b-form-input>                 
                </b-form-group>
            </b-col>

            <b-col md="12" class="mb-2">
              <b-form-group :label="$t('es_description') + ':'" class="mb-0">
                  <b-form-input
                    type="text"
                    v-model="notification_add.es_description"
                    :placeholder="$t('es_description')"
                    style="border-radius: 20px; color: #000000;"
                    required
                  >
                  </b-form-input>                 
                </b-form-group>
            </b-col>

            <b-col md="12" class="mb-2">
              <b-form-group :label="$t('en_description') + ':'" class="mb-0">
                  <b-form-input
                    type="text"
                    v-model="notification_add.en_description"
                    :placeholder="$t('en_description')"
                    style="border-radius: 20px; color: #000000;"
                    required
                  >
                  </b-form-input>                 
                </b-form-group>
            </b-col>

            <b-col md="12">
              <b-button
                block
                pill
                v-show="!progress"
                class="my-1"
                @click="SaveElementNotification()"
                style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec"
              >
                {{ $t("send_notification").toUpperCase() }}
              </b-button>
            </b-col>
            <b-col md="12">
              <div
                v-show="progress"
                class="text-center my-3"
                style="color: #6a71ec"
              >
                <b-spinner small class="align-middle"></b-spinner>
                <strong class="ml-2">{{ $t("loading") }}...</strong>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "MasterElements",

  data() {
    return {
      language: navigator.language,
      element: {},
      element_url: "highlight",
      element_title: "highlight",
      title_gender_letter: "",
      title_gender_letter2: "",
      element_add: {
        id_user: "0",
        es_title: "",
        en_title: "",
        es_description: "",
        en_description: "",
        url_image: "",
        opacity:0
      },
      link_add: {
        id_highlight: "0",

        url: "",

        url_image: "",
      },
      country_add: {
        id_highlight: 0,
        id_country: 0,     
      },
      notification_add: {    
        es_title: "",
        en_title: "",
        es_description: "",
        en_description: "",
        type_child: "highlight",
        id_child:0
      },
      element_update: {
        user:{
          username:''
        }
      },
      progress: false,
      total: 0,
      isBusy: false,
      isBusy2: false,
      emptyHTML: `<div class="mt-2 text-center"><h6>${this.$i18n.t(
        "no_elements"
      )}</h6></div>`,
      emptyFilteredHTML: `<div class="mt-2 text-center"><h6>${this.$i18n.t(
        "no_elements_query"
      )}</h6></div>`,
      id: "",
      id_country: "0",
      id_ad_banner_category: "0",
      countries: [{ text: this.$i18n.t("all"), value: "0" }],
      countriesx: [{ text: this.$i18n.t("select_one"), value: "" }],
      categories: [{ text: this.$i18n.t("all2"), value: "0" }],
      categoriesx: [{ text: this.$i18n.t("select_one2"), value: "" }],
      categoriesTMP: [{ text: this.$i18n.t("all2"), value: "0" }],
      aspect_ratios: [
        { text: "16:9", value: "1.777777778" },
        { text: "1:1", value: "1" },
      ],
      orders: [],
      currentPage: 1,
      filter: null,
      elements: [],
      elementsTMP: [],
      fileToUpload: [],
      fileToUploadUpdate: [],
      file_name: "",
      file_description: "",
      file: {
        name: "",
        description: "",
        url: "",
        format: "",
      },
      preview_image: "",
      highlight_countries:[],
      actualCountry:{},
      highlight_links: [],
      actualHighLight: {},
      user_finded: {
        username: "",
      },
      //USERS
      field: [
        {
          key: "username",
          label: this.$i18n.t('username'),
          sortable: false,
          thStyle: { width: "30%" }
        },
        {
          key: "followers",
          label: this.$i18n.t('followers'),
          sortable: false,
        },
        {
          key: "following",
          label: this.$i18n.t('following'),
          sortable: false,
        },       
        {
          key: "actions",
          label: this.$i18n.t('actions'),
          sortable: false,
          thStyle: { width: "25%" }
        },
      ],
      per_page: 20,
      current_page: 1,
      page_options: [10, 20, 50, 100],
      total_users:0,
      users: [],
      usersTMP: [],
      id_user: Cookies.get('id_user'),
   
    };
  },
  computed: {
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.total;
    },
    title_plural_uppercase() {
      return this.$i18n.t(this.element_title + "_plural").toUpperCase();
    },
    title_uppercase() {
      return this.$i18n.t(this.element_title).toUpperCase();
    },
    title_plural_midcase() {
      return this.$i18n.t(this.element_title + "_plural");
    },
    title_midcase() {
      return this.$i18n.t(this.element_title);
    },
    title_plural_lowcase() {
      return this.$i18n.t(this.element_title + "_plural").toLowerCase();
    },
    title_lowcase() {
      return this.$i18n.t(this.element_title).toLowerCase();
    },
    title_country_uppercase() {
      return this.$i18n.t('country').toUpperCase();
    },
    title_country_lowcase() {
      return this.$i18n.t('country').toLowerCase();
    },
    title_link_uppercase() {
      return this.$i18n.t('link').toUpperCase();
    },
    title_link_lowcase() {
      return this.$i18n.t('link').toLowerCase();
    },
  },
  mounted() {
    // this.AllCategories();
    this.AllCountries();
    this.AllElements();
    this.AllUsers();
  },
  methods: {
    ...mapActions([
      "GetAll",
      "ChangeStatus",
      "Save",
      "Update",
      "SaveFormData",
      "SaveFormDataComplete",
      "UpdateFormData",
    ]),
    format_num(num) {
      if (num >= 1000 && num < 1000000) {
        return (num / 1000).toFixed(2) + "K";
      } else if (num >= 1000000) {
        return (num / 1000000).toFixed(2) + "M";
      } else {
        return num;
      }
    },
    selectUser(user){
      console.log(user)
      this.user_finded = user;

      this.$nextTick(() => {
              this.$bvModal.hide("modal-list-users");
            });
    },
    AllUsers() {
      this.isBusy = true

      let form = {
        element: 'user',
        query: {
          order_key: 'id',
          order_value: 'desc',
          limit: this.per_page,
          page: this.current_page,
        },
        columns: ['dashboard'],
        params: {
          dashboard: 1
        }
      }

      if (this.active_status_label != this.$i18n.t('all').toUpperCase()) {
        form.query['active'] = this.active_status_number
      }

      if (this.premium_status_label != this.$i18n.t('all').toUpperCase()) {
        form.columns.push('id_role')
        form.params['id_role'] = this.premium_status_number
      }

      if (this.verified_status_label != this.$i18n.t('all').toUpperCase()) {
        form.columns.push('verified')
        form.params['verified'] = this.verified_status_number
      }

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.isBusy = false
            this.isBusy2 = false
            this.users = response.data.data
            this.usersTMP = response.data.data
            this.total_users = response.data.meta.total
          }
          else {
            this.isBusy = false
            this.isBusy2 = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.isBusy = false
          this.isBusy2 = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        })
    },
    onFileChange(e) {
      //When file input change, create a image preview
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      //Set image to preview
      //var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.preview_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    SearchInput() {
      let filterTMP = this.filter
      this.active_status_label = this.$i18n.t('all').toUpperCase()

      if (filterTMP) {
        //Ask for Users by Query (first 10)
        this.isBusy = true

        let form = {
          element: 'user',
          query: {
            query: filterTMP,
            order_key: 'id',
            order_value: 'desc',
            limit: 10,
          },
          columns: ['dashboard'],
          params: {
            dashboard: 1
          }
        }

        if (this.active_status_label != this.$i18n.t('all').toUpperCase()) {
          form.query['active'] = this.active_status_number
        }

        if (this.premium_status_label != this.$i18n.t('all').toUpperCase()) {
          form.columns.push('id_role')
          form.params['id_role'] = this.premium_status_number
        }

        this.GetAll(form)
          .then((response) => {
            if (response.code == 200) {
              this.isBusy = false
              this.isBusy2 = false
              this.users = response.data
              this.usersTMP = response.data
              this.total = response.data.length
            }
            else {
              this.isBusy = false
              this.isBusy2 = false
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            }
          })
          .catch((error) => {
            console.log(error)
            this.isBusy = false
            this.isBusy2 = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          })
      }
      else {
        this.AllUsers()
      }

      return true
    },
    resetModal() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-add");
      });
      this.progress = false;
      this.element = {};
      this.element_update = {};
      this.element_add = {
        id_country: "0",
        id_ad_banner_category: "",
        aspect_ratio: "1.777777778",
      };
      this.fileToUpload = [];
      this.fileToUploadUpdate = [];
      this.file_name = "";
      this.file_description = "";
      this.file = { name: "", description: "", url: "", format: "" };
      this.preview_image = "";
    },
    resetModalUsers() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-list-users");
      });
      this.progress = false;
     
    },
    ClearFilter() {
      this.filter = "";
      this.AllElements();
      return true;
    },
   
    OnFiltered(filteredItems) {
      this.total = filteredItems.length;
      this.currentPage = 1;
    },
    PageChanged() {
      this.AllElements(false);
    },
    AllCategories() {
      let form = {
        element: "master/bannera_category",
        query: {
          active: 1,
          order_key: this.language.substring(0, 2) + "_name",
          order_value: "asc",
          select: ["id", this.language.substring(0, 2) + "_name"],
        },
      };

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.categoriesTMP = [
              { text: this.$i18n.t("all2"), value: "0" },
              ...response.data.map((el) => ({
                text: el[this.language.substring(0, 2) + "_name"],
                value: el.id,
              })),
            ];
            this.categories = this.categoriesTMP;
            this.categoriesx = [
              { text: this.$i18n.t("select_one2"), value: "" },
              ...this.categoriesTMP.slice(1),
            ];
          } else {
            console.log(response);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t("an_error_has_occurred"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t("an_error_has_occurred"),
          });
        });
    },
    AllCountries() {
      let form = {
        element: "master/country",
        query: {
          active: 1,
          order_key: this.language.substring(0, 2) + "_name",
          order_value: "asc",
          select: ["id", this.language.substring(0, 2) + "_name"],
        },
      };

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.countriesTMP = [
              { text: this.$i18n.t("all2"), value: "0" },
              ...response.data.map((el) => ({
                text: el[this.language.substring(0, 2) + "_name"],
                value: el.id,
              })),
            ];
            this.countries = this.countriesTMP;
            this.countriesx = this.countriesTMP;
          } else {
            console.log(response);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t("an_error_has_occurred"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t("an_error_has_occurred"),
          });
        });
    },
    AllElements(upd_total = true) {
      this.isBusy2 = true;
      if (upd_total) this.isBusy = true;

      let form = {
        element: this.element_url,
        query: {
          order_key: "id",
          order_value: "desc",
          page: this.currentPage,
          limit: 10,
        },
        columns: [],
        params: {},
      };

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.isBusy = false;
            this.isBusy2 = false;
            this.elements = response.data.data;
            this.elementsTMP = response.data.data;
            this.total = response.data.meta.total;

            this.orders = [];
            for (let i = 1; i <= this.total; i++) {
              this.orders.push(i);
            }
          } else {
            console.log(response);
            this.isBusy = false;
            this.isBusy2 = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t("an_error_has_occurred"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          this.isBusy2 = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t("an_error_has_occurred"),
          });
        });
    },
    SaveElement() {
      const validation =
        !this.element_add.es_title ||
        !this.element_add.en_title ||
        !this.element_add.es_description ||
        !this.element_add.en_description;

      if (validation) {
        Swal.fire({
          title: this.$i18n.t("fill_fields"),
          text: this.$i18n.t("fill_all_fields"),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return;
      }

      this.progress = true;
      let data = new FormData();

      if(this.user_finded.id){
        data.append("id_user",this.user_finded.id);
      }else{
        data.append("id_user", 0);
      }
    
      data.append("url_image", this.fileToUpload);
      data.append("es_title", this.element_add.es_title);
      data.append("en_title", this.element_add.en_title);
      data.append("es_description", this.element_add.es_description);
      data.append("en_description", this.element_add.en_description);
      data.append("opacity", this.element_add.opacity);

      let form = { element: this.element_url, data: data };

      this.SaveFormData(form)
        .then((response) => {
          if (response.code == 200) {
            this.$nextTick(() => {
              this.$bvModal.hide("modal-add");
            });

            this.progress = false;
            this.AllElements();
            Swal.fire({
              title:
                this.title_midcase +
                " " +
                this.$i18n.t("added" + this.title_gender_letter).toLowerCase(),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title: this.$i18n.t("register_exists"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t("an_error_has_occurred"),
          });
        });
    },
    SaveElementNotification(){
      const validation = !this.notification_add.es_title || 
      !this.notification_add.en_title ||
      !this.notification_add.es_description ||
      !this.notification_add.en_description;

      
      if (validation) {
        Swal.fire({
          title: this.$i18n.t("fill_fields"),
          text: this.$i18n.t("fill_all_fields"),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return;
      }

      this.progress = true;
      let data = new FormData();
      data.append("es_title", this.notification_add.es_title);
      data.append("en_title", this.notification_add.en_title);
      data.append("es_description", this.notification_add.es_description);
      data.append("en_description", this.notification_add.en_description);
      data.append("type_child", 'highlight');
    
      data.append("id_child", this.actualHighLight.id);

      let form = { element: "notification/save_manual", data: data };

      this.SaveFormDataComplete(form)
        .then((response) => {
          if (response.code == 200) {
            this.progress = false;
           // this.getLinksByHighlight(this.actualHighLight.id);
            this.$nextTick(() => {
              this.$bvModal.hide("modal-add-notification");
            });

            Swal.fire({
              title: "Notification Sended",
                icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title: this.$i18n.t("register_exists"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t("an_error_has_occurred"),
          });
  });
    },
    UpdateTMP(data) {
      this.element_update = {
        id: data.id,
        es_title: data.es_title,
        en_title: data.en_title,
        es_description: data.es_description,
        en_description: data.en_description,
        url_image: data.url_image,
        user: data.user,
        opacity: data.opacity
      };
      if(data.user){
        this.user_finded = data.user;
      }
     
      this.element = data;

      //this.element = data
      this.$nextTick(() => {
        this.$bvModal.show("modal-update");
      });
    },
    sendNotificationTMP(data) {
          
      this.actualHighLight = data;

      //this.element = data
      this.$nextTick(() => {
        this.$bvModal.show("modal-add-notification");
      });
    },
    getLinksByHighlight(id) {
      let form = {
        element: "highlight_link",
        query: {
          order_key: "id",
          order_value: "desc",
        },
        columns: ["id_highlight"],
        params: {
          id_highlight: id,
        },
      };

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.isBusy = false;
            this.isBusy2 = false;
            this.highlight_links = response.data;
          } else {
            console.log(response);
            this.isBusy = false;
            this.isBusy2 = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t("an_error_has_occurred"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          this.isBusy2 = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t("an_error_has_occurred"),
          });
        });
    },
    getCountriesByHighlight(id) {
      let form = {
        element: "highlight_country",
        query: {
          order_key: "id",
          order_value: "desc",
        },
        columns: ["id_highlight"],
        params: {
          id_highlight: id,
        },
      };

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.isBusy = false;
            this.isBusy2 = false;

           
            this.highlight_countries = response.data;
          } else {
            console.log(response);
            this.isBusy = false;
            this.isBusy2 = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t("an_error_has_occurred"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          this.isBusy2 = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t("an_error_has_occurred"),
          });
        });
    },
    UpdateLinks(data) {
      this.highlight_links = [];
      this.element_update = {
        id: data.id,
        es_title: data.es_title,
        en_title: data.en_title,
        es_description: data.es_description,
        en_description: data.en_description,
        url_image: data.url_image,
      };
      this.element = data;
      this.actualHighLight = data;
      this.getLinksByHighlight(data.id);

      //this.element = data
      this.$nextTick(() => {
        this.$bvModal.show("modal-links");
      });
    },
    ChangeOrderTMP(data) {
      this.element_update = {
        id: data.id,
        ordering: data.ordering,
      };
      this.element = data;

      this.$nextTick(() => {
        this.$bvModal.show("modal-order");
      });
    },
    UpdateCountriesTMP(data){
      this.highlight_countries = [];
      this.element_update = {
        id: data.id,
        es_title: data.es_title,
        en_title: data.en_title,
        es_description: data.es_description,
        en_description: data.en_description,
        url_image: data.url_image,
      };
      this.element = data;
      this.actualHighLight = data;
      this.getCountriesByHighlight(data.id);

      //this.element = data
      this.$nextTick(() => {
        this.$bvModal.show("modal-countries");
      });
    },
    UpdateElement() {
      console.log("Updating");
      console.log(this.element_update);
      console.log(this.element);
      const change =
        this.element_update.es_title != this.element.es_title ||
        this.element_update.en_title != this.element.en_title ||
        this.element_update.es_description != this.element.es_description ||
        this.element_update.en_description != this.element.en_description ||
        this.fileToUploadUpdate;

      if (!change) {
        console.log(change);
        console.log("Returning");
        return;
      }

      const validation =
        !this.element_update.es_title ||
        !this.element_update.en_title ||
        !this.element_update.es_description ||
        !this.element_update.en_description;

      if (validation) {
        Swal.fire({
          title: this.$i18n.t("fill_fields"),
          text: this.$i18n.t("fill_all_fields"),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return;
      }

      this.progress = true;
      let data_to_update = new FormData();
      console.log(this.user_finded.id);
      if(this.user_finded.id){
        data_to_update.append("id_user",this.user_finded.id);
      }
      data_to_update.append("url_image", this.fileToUploadUpdate);
      data_to_update.append("es_title", this.element_update.es_title);
      data_to_update.append("en_title", this.element_update.en_title);
      data_to_update.append("opacity", this.element_update.opacity);
      
      data_to_update.append(
        "es_description",
        this.element_update.es_description
      );
      data_to_update.append(
        "en_description",
        this.element_update.en_description
      );

      let form = {
        element: this.element_url,
        id: this.element_update.id,
        update: "update",
        data: data_to_update,
      };

      this.UpdateFormData(form)
        .then((response) => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-update");
          });

          if (response.code == 200) {
            this.progress = false;
            this.AllElements();
            Swal.fire({
              title:
                this.title_midcase +
                " " +
                this.$i18n
                  .t("updated" + this.title_gender_letter)
                  .toLowerCase(),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title: this.$i18n.t("register_exists"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t("an_error_has_occurred"),
          });
        });
    },
    UpdateElementLink(hlink) {
      console.log("Updating");
      console.log(hlink);

      this.progress = true;
      let data_link_to_update = new FormData();

      if (this.fileToUploadUpdate) {
        data_link_to_update.append("url_image", this.fileToUploadUpdate);
      }

      data_link_to_update.append("url", hlink.url);

      let form = {
        element: "highlight_link",
        id: hlink.id,
        update: "update",
        data: data_link_to_update,
      };

      this.UpdateFormData(form)
        .then((response) => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-update");
          });

          if (response.code == 200) {
            this.progress = false;
            this.AllElements();
            this.getLinksByHighlight(hlink.id_highlight);
            Swal.fire({
              title:
                this.title_midcase +
                " " +
                this.$i18n
                  .t("updated" + this.title_gender_letter)
                  .toLowerCase(),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title: this.$i18n.t("register_exists"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t("an_error_has_occurred"),
          });
        });
    },
    UpdateElementCountry(hcountry) {
      console.log("Updating");
      console.log(hcountry);

      this.progress = true;
      let data_link_to_update = new FormData();

    
      data_link_to_update.append("id_highlight", hcountry.id_highlight);
      data_link_to_update.append("id_country", hcountry.country.id);

      let form = {
        element: "highlight_country",
        id: hcountry.id,
        update: "update",
        data: data_link_to_update,
      };

      this.UpdateFormData(form)
        .then((response) => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-update");
          });

          if (response.code == 200) {
            this.progress = false;
            this.AllElements();
            this.getCountriesByHighlight(hcountry.id_highlight);
            Swal.fire({
              title:
                this.title_midcase +
                " " +
                this.$i18n
                  .t("updated" + this.title_gender_letter)
                  .toLowerCase(),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title: this.$i18n.t("register_exists"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t("an_error_has_occurred"),
          });
        });
    },
    
    SaveElementLink() {
      const validation = !this.link_add.url;

      if (validation) {
        Swal.fire({
          title: this.$i18n.t("fill_fields"),
          text: this.$i18n.t("fill_all_fields"),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return;
      }

      this.progress = true;
      let data = new FormData();
      data.append("id_highlight", this.actualHighLight.id);
      data.append("url_image", this.fileToUploadUpdate);
      data.append("url", this.link_add.url);
      data.append("type", 1);

      let form = { element: "highlight_link", data: data };

      this.SaveFormData(form)
        .then((response) => {
          if (response.code == 200) {
            this.progress = false;
            this.getLinksByHighlight(this.actualHighLight.id);
            this.$nextTick(() => {
              this.$bvModal.hide("modal-add-links");
            });

            Swal.fire({
              title:
                this.title_midcase +
                " " +
                this.$i18n.t("added" + this.title_gender_letter).toLowerCase(),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title: this.$i18n.t("register_exists"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t("an_error_has_occurred"),
          });
        });
    },
    SaveElementCountry() {
      const validation = !this.country_add.id_country;

      if (validation) {
        Swal.fire({
          title: this.$i18n.t("fill_fields"),
          text: this.$i18n.t("fill_all_fields"),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return;
      }

      this.progress = true;
      let data = new FormData();
      data.append("id_highlight", this.actualHighLight.id);    
      data.append("id_country", this.country_add.id_country);
    

      let form = { element: "highlight_country", data: data };

      this.SaveFormData(form)
        .then((response) => {
          if (response.code == 200) {
            this.progress = false;
            this.getCountriesByHighlight(this.actualHighLight.id);
            this.$nextTick(() => {
              this.$bvModal.hide("modal-add-country");
            });

            Swal.fire({
              title:
                this.title_midcase +
                " " +
                this.$i18n.t("added" + this.title_gender_letter).toLowerCase(),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title: this.$i18n.t("register_exists"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t("an_error_has_occurred"),
          });
        });
    },
   
    ChangeOrder() {
      const change = this.element_update.ordering != this.element.ordering;

      if (!change) return;

      const validation = !this.element_update.ordering;

      if (validation) {
        Swal.fire({
          title: this.$i18n.t("fill_fields"),
          text: this.$i18n.t("fill_all_fields"),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return;
      }

      this.progress = true;

      let form = {
        element: this.element_url,
        id: this.element_update.id,
        update: "update",
        data: {
          ordering: this.element_update.ordering,
        },
      };

      this.Update(form)
        .then((response) => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-order");
          });

          if (response.code == 200) {
            this.progress = false;
            this.AllElements();
            Swal.fire({
              title:
                this.title_midcase +
                " " +
                this.$i18n.t("updated" + this.title_gender_letter),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title: this.$i18n.t("register_exists"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t("an_error_has_occurred"),
          });
        });
    },
    ChangeActive(data) {
      let active = data.active == 1 ? 0 : 1;
      let title = data.active == 1 ? "deactivate" : "activate";
      let title2 =
        data.active == 1
          ? "deactivated" + this.title_gender_letter
          : "activated" + this.title_gender_letter;
      let text =
        data.active == 1
          ? "deactivate_question" + this.title_gender_letter2
          : "activate_question" + this.title_gender_letter2;
      Swal.fire({
        title: this.$i18n.t(title) + " " + this.title_lowcase,
        text: this.$i18n.t(text) + " " + this.title_lowcase + "?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText:
          active == 0 ? this.$i18n.t("deactivate") : this.$i18n.t("activate"),
        cancelButtonText: this.$i18n.t("cancel"),
      }).then((result) => {
        if (result.value) {
          let form = { element: this.element_url, id: data.id, status: active };

          this.ChangeStatus(form)
            .then((response) => {
              if (response.code == 200) {
                data.active = active;
                Swal.fire({
                  title:
                    this.title_midcase +
                    " " +
                    this.$i18n.t(title2).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              } else {
                console.log(response);
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t("an_error_has_occurred"),
                });
              }
            })
            .catch((error) => {
              console.log(error);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t("an_error_has_occurred"),
              });
            });
        }
      });
    },
    deleteHCountry(data) {
      let active = data.active == 1 ? 0 : 1;
      let title = data.active == 1 ? "deactivate" : "activate";
      let title2 =
        data.active == 1
          ? "deactivated" + this.title_gender_letter
          : "activated" + this.title_gender_letter;
      let text =
        data.active == 1
          ? "deactivate_question" + this.title_gender_letter2
          : "activate_question" + this.title_gender_letter2;
      Swal.fire({
        title: this.$i18n.t(title) + " " + this.title_country_lowcase,
        text: this.$i18n.t(text) + " " + this.title_country_lowcase + "?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText:
          active == 0 ? this.$i18n.t("deactivate") : this.$i18n.t("activate"),
        cancelButtonText: this.$i18n.t("cancel"),
      }).then((result) => {
        if (result.value) {
          let form = { 
            element: 'highlight_country', 
            id: data.id, 
            status: 2 };

          this.ChangeStatus(form)
            .then((response) => {
              if (response.code == 200) {
                data.active = active;
                Swal.fire({
                  title:
                    this.title_midcase +
                    " " +
                    this.$i18n.t(title2).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.getCountriesByHighlight(data.id_highlight)

              } else {
                console.log(response);
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t("an_error_has_occurred"),
                });
              }
            })
            .catch((error) => {
              console.log(error);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t("an_error_has_occurred"),
              });
            });
        }
      });
    },
    deleteHLink(data) {
      let active = data.active == 1 ? 0 : 1;
      let title = data.active == 1 ? "deactivate" : "activate";
      let title2 =
        data.active == 1
          ? "deactivated" + this.title_gender_letter
          : "activated" + this.title_gender_letter;
      let text =
        data.active == 1
          ? "deactivate_question" + this.title_gender_letter2
          : "activate_question" + this.title_gender_letter2;
      Swal.fire({
        title: this.$i18n.t(title) + " " + this.title_link_lowcase,
        text: this.$i18n.t(text) + " " + this.title_link_lowcase + "?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText:
          active == 0 ? this.$i18n.t("deactivate") : this.$i18n.t("activate"),
        cancelButtonText: this.$i18n.t("cancel"),
      }).then((result) => {
        if (result.value) {
          let form = { 
            element: 'highlight_link', 
            id: data.id, 
            status: 2 };

          this.ChangeStatus(form)
            .then((response) => {
              if (response.code == 200) {
                data.active = active;
                Swal.fire({
                  title:
                    this.title_midcase +
                    " " +
                    this.$i18n.t(title2).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.getLinksByHighlight(data.id_highlight)

              } else {
                console.log(response);
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t("an_error_has_occurred"),
                });
              }
            })
            .catch((error) => {
              console.log(error);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t("an_error_has_occurred"),
              });
            });
        }
      });
    },
    
    Delete(data) {
      Swal.fire({
        title: this.$i18n.t("delete") + " " + this.title_lowcase,
        text:
          this.$i18n.t("delete_question" + this.title_gender_letter) +
          " " +
          this.title_lowcase +
          "? " +
          this.$i18n.t("delete_questionx" + this.title_gender_letter),
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: this.$i18n.t("delete"),
        cancelButtonText: this.$i18n.t("cancel"),
      }).then((result) => {
        if (result.value) {
          let form = { element: this.element_url, id: data.id, status: 2 };

          this.ChangeStatus(form)
            .then((response) => {
              if (response.code == 200) {
                this.AllElements();
                Swal.fire({
                  title:
                    this.title_midcase +
                    " " +
                    this.$i18n
                      .t("deleted" + this.title_gender_letter)
                      .toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              } else {
                console.log(response);
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t("an_error_has_occurred"),
                });
              }
            })
            .catch((error) => {
              console.log(error);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t("an_error_has_occurred"),
              });
            });
        }
      });
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #6a71ec !important;
  border-color: #6a71ec !important;
}

.header,
.table thead th,
thead,
th {
  background-color: #6a71ec !important;
  color: #ffffff;
}

.selector-class .vs__search::placeholder,
.selector-class .vs__dropdown-toggle {
  border-radius: 20px;
  height: 40px;
}

.selector-class .vs__dropdown-menu {
  border-color: #000000;
  border-radius: 0px;
  max-height: 200px;
  overflow-y: auto;
}

.selector-class .vs__dropdown-option--highlight {
  background-color: #6a71ec;
}
</style>

<template>
  <div id="User">
    <br />
    <h1 class="text2" style="color: #000000; margin-left: 10px; text-align: left;"><strong>{{ $t('users').toUpperCase()
    }}</strong></h1>
    <div class="content">
      <b-card style="border: none; background-color: #F2F2F2">
        <b-row>
          <b-col md="3">
            <h4 class="my-1" style="color: #000000; text-align: left"><strong>TOTAL:</strong></h4>
          </b-col>
          <b-col md="3">
            <h4 class="my-1" style="color: #000000; text-align: left"><strong>{{ $t('verified').toUpperCase()
            }}:</strong></h4>
          </b-col>
          <b-col md="3">
            <h4 class="my-1" style="color: #000000; text-align: left"><strong>PREMIUM:</strong></h4>
          </b-col>
          <b-col md="3">
            <h4 class="my-1" style="color: #000000; text-align: left"><strong>{{ $t('actives').toUpperCase()
            }}:</strong></h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3" class="my-1">
            <div class="py-1"
              style="box-shadow: 0px 10px 8px #B0B0B0; border-radius: 24px; width=100%; color: #FFFFFF; border-color: #6a71ec; background-color: #6a71ec">
              <span v-show="!isBusy" style="font-size: 24px;">{{ format_num(total) }}</span>
              <span v-show="isBusy" style="font-size: 24px;">--</span>
            </div>
          </b-col>
          <b-col md="3" class="my-1">
            <b-dropdown offset="15" toggle-class="dropdownx" class="py-1 dropdownx my-class"
              :text="verified_status_label"
              style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              <b-dropdown-item v-for="data of verified_statuses" :key="data.id" @click="VerifiedSelected(data)">{{
                  data.verified_status
              }}</b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col md="3" class="my-1">
            <b-dropdown offset="15" toggle-class="dropdownx" class="py-1 dropdownx my-class"
              :text="premium_status_label"
              style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              <b-dropdown-item v-for="data of premium_statuses" :key="data.id" @click="PremiumSelected(data)">{{
                  data.premium_status
              }}</b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col md="3" class="my-1">
            <b-dropdown offset="15" toggle-class="dropdownx" class="py-1 dropdownx my-class" :text="active_status_label"
              style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              <b-dropdown-item v-for="data of active_statuses" :key="data.id" @click="ActiveSelected(data)">{{
                  data.active_status
              }}</b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="4" class="my-1">
            <b-form-group label-for="filterInput" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('searcher') + ':' }}</p>
              <b-input-group size="sm">
                <b-input-group-prepend>
                  <span class="input-group-text"
                    style="border-color: #000000; background-color: #E0E0E0; border-right: none;">
                    <font-awesome-icon @click="SearchInput" style="cursor: pointer; color: #000000; font-size: 17px"
                      icon="search" />
                  </span>
                </b-input-group-prepend>
                <b-form-input id="filterInput" v-model="filter" @keyup.enter="SearchInput" type="text"
                  :placeholder="$t('search') + '...'"
                  style="border-color: #000000; background-color: #E0E0E0; border-right: none; border-left: none;" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="ClearFilter" class="input-group-text"
                    style="border-color: #000000; background-color: #E0E0E0; border-left: none;">
                    <font-awesome-icon style="color: #000000; font-size: 17px" icon="times-circle" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group label-for="per_pageSelect" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('per_page') + ':' }}</p>
              <b-form-select id="per_pageSelect" v-model="per_page" size="sm" :options="page_options"
                @change="PageChanged" style="border-color: #000000; background-color: #E0E0E0;" />
            </b-form-group>
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group label-for="allPages" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('current_page') + ':' }}</p>
              <b-pagination id="allPages" v-model="current_page" :total-rows="total" :per-page="per_page"
                aria-controls="my-table" size="sm" align="fill" @input="PageChanged()" class="variable-width2">
              </b-pagination>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3 px-3">
          <b-col sm="12">
            <div
              style="box-shadow: 0px 10px 8px #B0B0B0; border: 3px; border-color: #000000; background-color: #FFFFFF">
              <b-table id="my-table" show-empty small fixed stacked="md" sticky-header="true" hover thead-class="header"
                :primary-key="id" :items="usersTMP" :fields="field" :current-page="current_page" :per-page="0"
                :empty-html="emptyHTML" :empty-filtered-html="emptyFilteredHTML" :busy="isBusy">
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle" style="color: #6a71ec;"></b-spinner>
                    <strong> {{ $t('loading') }}...</strong>
                  </div>
                </template>

                <template v-slot:cell(username)="row">
                  <img :src="row.item.imgkit_profile_picture + '?tr=n-xsmall_thumb'" alt="" height="20px"
                    style="border-radius: 50%; aspect-ratio: 1; object-fit: cover;">
                  <span class="my-0 py-0">
                    {{ '@' + row.item.username }}{{ row.item.id == id_user ? ' (' + $t('you').toLowerCase() + ')' : ''
                    }}
                  </span>
                  <font-awesome-icon v-if="row.item.verified == 1" class="mr-1" icon="check-circle"
                    style="color: #6a71ec; font-size: 17px" />
                  <font-awesome-icon v-if="row.item.id_role == 3" class="mr-1" icon="star"
                    style="color: #6a71ec; font-size: 17px" />
                  <font-awesome-icon v-if="(row.item.content_creator == 1)" style="color: #6a71ec; font-size: 17px"
                    icon="paint-brush" />
                </template>

                <template v-slot:cell(followers)="row">
                  <p class="my-0 py-0">
                    {{ format_num(row.item.followers) }}
                  </p>
                </template>

                <template v-slot:cell(following)="row">
                  <p class="my-0 py-0">
                    {{ format_num(row.item.following) }}
                  </p>
                </template>

                <template v-slot:cell(member_since)="row">
                  <p class="my-0 py-0">
                    {{ language == 'es'
                        ? moment.utc(row.item.created_at).local().format('D MMM YYYY')
                        : moment.utc(row.item.created_at).local().format('MMM D, YYYY')
                    }}
                  </p>
                </template>

                <template v-slot:cell(actions)="row">
                  <b-button v-if="row.item.active == 1 && row.item.id != id_user" size="sm" variant="white"
                    :title="$t('deactivate')" @click="ChangeActive(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="check-square" />
                  </b-button>
                  <b-button v-if="row.item.active == 0 && row.item.id != id_user" size="sm" variant="white"
                    :title="$t('activate')" @click="ChangeActive(row.item)">
                    <font-awesome-icon style="color: #000000; font-size: 17px" icon="minus-square" />
                  </b-button>

                  <b-button v-if="row.item.active == 1 && !is_mobile" v-b-modal.modal-details size="sm" variant="white"
                    class="ml-1" :title="$t('details')" @click="ProfileTMP(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="id-card" />
                  </b-button>
                  <b-button v-if="row.item.active == 1 && !is_mobile" v-b-modal.modal-subcriptions size="sm" variant="white"
                    class="ml-1" :title="$t('details')" @click="SuscriptionTMP(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="ad" />
                  </b-button> 
                  <b-button v-if="row.item.active == 1 && !is_mobile" v-b-modal.modal-subcriptions size="sm" variant="white"
                    class="ml-1" :title="$t('details')" @click="SuscriptionTMP(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="plus-circle" />
                  </b-button>
                  <b-button v-if="row.item.active == 1 && row.item.id_role == 2 && row.item.id != id_user" size="sm"
                    variant="white" class="ml-1" title="Premium" @click="MakePremium(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="star" />
                  </b-button>
                  <b-button v-if="row.item.active == 1 && row.item.id_role == 3 && row.item.id != id_user" size="sm"
                    variant="white" class="ml-1" title="Premium" @click="RemovePremium(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="ban" />
                  </b-button>

                  <b-button v-if="row.item.verified == 0 && row.item.id != id_user" size="sm" variant="white"
                    :title="$t('verify')" @click="MakeVerify(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="user-check" />
                  </b-button>
                  <b-button v-if="row.item.verified == 1 && row.item.id != id_user" size="sm" variant="white"
                    :title="$t('unverify')" @click="RemoveVerify(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="user-minus" />
                  </b-button>

                  <b-button v-if="row.item.content_creator == 0 && row.item.id != id_user" size="sm" variant="white"
                    :title="$t('content_creator')" @click="MakeContentCreator(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="paint-brush" />
                  </b-button>
                  <b-button v-if="row.item.content_creator == 1 && row.item.id != id_user" size="sm" variant="white"
                    :title="$t('remove_content_creator')" @click="RemoveContentCreator(row.item)">
                    <font-awesome-icon style="color: #000000; font-size: 17px" icon="paint-brush" />
                  </b-button>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <b-modal id="modal-details" ref="modal" title-class="text-font-black"
      :title="$t('user_profile') + ' - @' + element.username" modal-class="model-content" hide-footer
      content-class="shadow" style="max-height: 400px; overflow-y: auto;" @show="ResetModal" @hidden="ResetModal">
      <b-container>
        <div v-if="!post_selected">
          <b-row class="mt-1 mb-2" style="border-radius: 20px; background-color: #000000">
            <b-col class="text-center mx-0 px-0 pt-4" md="4">
              <p class="py-0 mb-0" style="font-size: 26px; color: #FFFFFF"><strong>{{ format_num(element.followers)
              }}</strong></p>
              <p class="py-0 my-0" style="font-size: 12px; color: #FFFFFF">{{ $t('followers') }}</p>
            </b-col>
            <b-col class="text-center mt-2 mx-0 px-0" md="4">
              <img :src="element.imgkit_profile_picture + '?tr=n-small_thumb'" height="90px"
                style="border-radius: 50%; aspect-ratio: 1; object-fit: cover;">
            </b-col>
            <b-col class="text-center mx-0 px-0 pt-4" md="4">
              <p class="py-0 mb-0" style="font-size: 26px; color: #FFFFFF"><strong>{{ format_num(element.following)
              }}</strong></p>
              <p class="py-0 my-0" style="font-size: 12px; color: #FFFFFF">{{ $t('following') }}</p>
            </b-col>
            <b-col class="text-center mx-0 px-0" md="12">
              <span class="py-0 mb-0" style="color: #FFFFFF"><strong>{{ element.first_name + ' ' + element.last_name
              }}</strong></span>
              <span class="ml-1" v-if="element.verified == 1">
                <div class="py-0 px-1 mb-0"
                  style="display: inline-block; border-radius: 20px; background-color: #6a71ec; font-size: 10px; color: #FFFFFF">
                  <font-awesome-icon icon="check-circle" style="color: #FFFFFF; font-size: 10px" />
                </div>
              </span>
              <span class="ml-1" v-if="element.id_role == 3">
                <div class="py-0 px-1 mb-0"
                  style="display: inline-block; border-radius: 20px; background-color: #6a71ec; font-size: 10px; color: #FFFFFF">
                  <span><strong>PREMIUM</strong></span>
                  <font-awesome-icon icon="star" class="ml-1" style="color: #FFFFFF; font-size: 10px" />
                </div>
              </span>
              <span class="ml-1" v-if="element.id_role == 1">
                <div class="py-0 px-1 mb-0"
                  style="display: inline-block; border-radius: 20px; background-color: #6a71ec; font-size: 10px; color: #FFFFFF">
                  <span><strong>ADMIN</strong></span>
                  <font-awesome-icon icon="crown" class="ml-1" style="color: #FFFFFF; font-size: 10px" />
                </div>
              </span>
            </b-col>
            <b-col md="12" class="mx-0 px-3 mb-3">
              <p class="py-0 mt-0 mb-1" style="color: #FFFFFF"><strong>{{ element.username }}</strong></p>
              <p v-if="element.biography" class="py-0 my-0" style="font-size: 14px; color: #FFFFFF">{{ element.biography
              }}</p>
              <a v-if="element.link" :href="element.link" target="_blank" class="font-size: 14px; py-0 my-0"
                style="color: #6a71ec">{{ element.link.substring(7) }}</a>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col v-if="grid_view" md="4" class="text-center"
              style="cursor: pointer; background-color: #6a71ec; border-radius: 20px;" @click="grid_view = true">
              <font-awesome-icon class="mt-3" style="color: #FFFFFF; font-size: 20px" icon="th-large" />
            </b-col>
            <b-col v-else md="4" class="text-center"
              style="cursor: pointer; background-color: #000000; border-radius: 20px;" @click="grid_view = true">
              <font-awesome-icon class="mt-3" style="color: #FFFFFF; font-size: 20px" icon="th-large" />
            </b-col>
            <b-col md="4" class="text-center">
              <p class="py-0 mb-0" style="font-size: 26px; color: #000000"><strong>{{ format_num(total_posts)
              }}</strong></p>
              <p class="py-0 my-0" style="font-size: 12px; color: #000000">{{ $t('posts') }}</p>
            </b-col>
            <b-col v-if="!grid_view" md="4" class="text-center"
              style="cursor: pointer; background-color: #6a71ec; border-radius: 20px;" @click="grid_view = false">
              <font-awesome-icon class="mt-3" style="color: #FFFFFF; font-size: 20px" icon="th-list" />
            </b-col>
            <b-col v-else md="4" class="text-center"
              style="cursor: pointer; background-color: #000000; border-radius: 20px;" @click="grid_view = false">
              <font-awesome-icon class="mt-3" style="color: #FFFFFF; font-size: 20px" icon="th-list" />
            </b-col>
          </b-row>

          <b-col v-if="post_loading" lg="12" class="mt-2 mb-0 text-center">
            <b-spinner style="width: 2rem; height: 2rem; font-size: 10px; color: #6a71ec">
            </b-spinner>
          </b-col>

          <b-col v-if="!post_loading && total_posts == 0" lg="12" class="mt-2 mb-0 text-center">
            <p style="font-size: 14px; text-align: center; color: #000000">{{ $t('user_has_no_posts') }}...</p>
          </b-col>

          <b-col v-if="grid_view && !post_loading && total_posts > 0" lg="12" class="text-center">
            <b-row>
              <b-col md="4" v-for="data of posts" v-bind:key="data.id" class="mb-1">
                <img v-if="data.type == 1" :src="data.imgkit_url_picture + '?tr=n-grid_thumb'" width="100%"
                  style="cursor: pointer; object-fit: cover; aspect-ratio: 1;" @click="PostSelected(data)">
                <div v-else-if="data.type == 2" @click="PostSelected(data)"
                  style="cursor: pointer; position: relative;">
                  <img :src="data.imgkit_url_picture + '?tr=n-grid_thumb'" width="100%"
                    style="object-fit: cover; aspect-ratio: 1;">
                  <div style="position: absolute; top: 45%; left: 45%;">
                    <font-awesome-icon style="color: #FFFFFF; font-size: 20px" icon="play" />
                  </div>
                </div>
                <div v-else-if="data.type == 4" @click="PostSelected(data)"
                  style="cursor: pointer; position: relative;">
                  <img v-if="data.imgkit_url_picture" :src="data.imgkit_url_picture + '?tr=n-grid_thumb'" width="100%"
                    style="object-fit: cover; aspect-ratio: 1;">
                  <img v-else src="../assets/logo2.png" width="100%"
                    style="cursor: pointer; object-fit: cover; aspect-ratio: 1;" @click="PostSelected(data)">
                </div>
                <img v-else src="../assets/logo2.png" width="100%"
                  style="cursor: pointer; object-fit: cover; aspect-ratio: 1;" @click="PostSelected(data)">
              </b-col>
            </b-row>
          </b-col>

          <b-col v-if="!grid_view && !post_loading && total_posts > 0" lg="12" class="text-center">
            <b-row v-for="(data, index) of posts" v-bind:key="data.id">
              <b-col lg="12" class="mb-3 p-2" style="border-radius: 20px; background-color: #000000">
                <b-row>
                  <b-col lg="2" class="pt-2">
                    <img :src="element.imgkit_profile_picture + '?tr=n-small_thumb'" class="ml-2" height="50px"
                      style="object-fit: cover; aspect-ratio: 1; border-radius: 50%;">
                  </b-col>
                  <b-col lg="8" class="pt-2 text-left">
                    <p class="py-0 my-0" style="color: #FFFFFF"><strong>{{ element.username }}</strong></p>
                    <p class="py-0 my-0" style="font-size: 14px; color: #FFFFFF">{{
                        moment.utc(data.created_at).local().fromNow()
                    }}</p>
                  </b-col>
                  <b-col lg="2" class="pt-3 text-center">
                    <font-awesome-icon v-if="!progress" style="cursor: pointer; color: #FFFFFF; font-size: 24px"
                      icon="flag" @click="ChangeActivePost(data, index)" />
                    <b-spinner v-else style="width: 24px; height: 24px; font-size: 10px; color: #FFFFFF" />
                  </b-col>
                  <b-col lg="12" v-if="data.type == 1" class="text-center">
                    <b-spinner v-if="img_is_loading[index]"
                      style="margin-top: 80px; margin-bottom: 70px; width: 24px; height: 24px; font-size: 10px; color: #6a71ec" />
                    <br v-if="img_is_loading[index]">
                    <img :src="data.imgkit_url_picture" v-bind:class="{ mini: img_is_loading[index] }" class="mt-2"
                      width="90%" @load="ShowImg(index)" />
                    <p class="py-0 mt-2 ml-2 text-left" style="color: #FFFFFF">{{ data.text }}</p>
                  </b-col>
                  <b-col lg="12" v-if="data.type == 2" class="text-center">
                    <a :href="data.url_video" target="_blank">
                      <div style="position: relative;">
                        <b-spinner v-if="img_is_loading[index]"
                          style="margin-top: 80px; margin-bottom: 70px; width: 24px; height: 24px; font-size: 10px; color: #6a71ec" />
                        <br v-if="img_is_loading[index]">
                        <img :src="data.imgkit_url_picture" v-bind:class="{ mini: img_is_loading[index] }" class="mt-2"
                          width="100%" style="object-fit: cover; aspect-ratio: 1;" @load="ShowImg(index)">
                        <div v-if="!img_is_loading[index]" style="position: absolute; top: 45%; left: 44%;">
                          <font-awesome-icon style="color: #FFFFFF; font-size: 60px" icon="play" />
                        </div>
                      </div>
                    </a>
                    <p class="py-0 mt-2 ml-2 text-left" style="color: #FFFFFF">{{ data.text }}</p>
                  </b-col>
                  <b-col lg="12" v-if="data.type == 3" class="text-left">
                    <p class="py-0 mt-2 ml-2" style="color: #FFFFFF">{{ data.text }}</p>
                  </b-col>
                  <b-col lg="12" v-if="data.type == 4" class="text-center">
                    <b-spinner v-if="img_is_loading[index]"
                      style="margin-top: 80px; margin-bottom: 70px; width: 24px; height: 24px; font-size: 10px; color: #6a71ec" />
                    <br v-if="img_is_loading[index]">
                    <img v-if="data.imgkit_url_picture" :src="data.imgkit_url_picture"
                      v-bind:class="{ mini: img_is_loading[index] }" class="mt-2" width="90%" @load="ShowImg(index)" />
                    <img v-else src="../assets/logo5.png" v-bind:class="{ mini: img_is_loading[index] }" class="mt-2"
                      width="90%" @load="ShowImg(index)" />
                    <p v-if="data.text" class="py-0 mt-2 ml-2 text-left" style="color: #FFFFFF">{{ data.text }}</p>

                    <b-row v-for="(datax, indexx) of data.questions" :key="datax.question + indexx" align-h="center"
                      class="mx-auto px-auto pt-2 pb-2 mb-1 text-left"
                      style="border-radius: 20px; background-color: #000000">
                      <b-col lg="12">
                        <b-row class="mx-auto px-auto mb-1">
                          <b-col lg="12" class="px-1 py-0 text-left">
                            <p class="py-0 mt-0 mb-1" style="color: #FFFFFF; font-size: 20px;">
                              {{ datax.question.question }}
                            </p>
                          </b-col>
                        </b-row>

                        <b-row v-for="(datay, indexy) of datax.answers" :key="indexy" class="mx-auto mb-2"
                          style="border-radius: 10px; border-style: solid; border-width: 1px; border-color: #6a71ec">
                          <b-col lg="10" class="py-0 pl-0 pr-2 mb-1 text-center" align-self="center">
                            <p class="my-0 py-0" style="color: #FFFFFF">{{ datay.answer.answer }}</p>
                          </b-col>
                          <b-col lg="2" class="m-0 p-0 pr-2 text-right" align-self="center">
                            <p class="my-0 py-0" style="color: #FFFFFF">{{ datay.percentage }}%</p>
                          </b-col>

                          <b-col lg="12" class="m-0 p-0">
                            <div
                              v-bind:style="{ borderRadius: (datay.percentage == 100 ? '0px 0px 8px 8px' : '0px 0px 0px 8px'), width: datay.percentage + '%' }"
                              style="height: 10px; border-radius: 0px 0px 8px 8px; background-color: #6a71ec;">
                            </div>
                          </b-col>
                        </b-row>
                      </b-col>

                      <b-col lg="12" style="color: #FFFFFF">
                        <p v-if="data.ended == 0" class="my-0 py-0 textl-left">{{
                            format_num(datax.total_answers_for_question) + ' ' + $t('votes')
                        }} - {{ $t('in_progress') }}
                        </p>
                        <p v-else class="my-0 py-0 textl-left">{{ format_num(datax.total_answers_for_question) + ' ' +
                            $t('votes')
                        }} - {{ $t('already_ended') }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col lg="12" class="text-left mb-2">
                    <span class="mr-3">
                      <font-awesome-icon class="mt-2 ml-2 mr-2" style="color: #FFFFFF; font-size: 20px" icon="heart" />
                      <span style="color: #FFFFFF; font-size: 20px">{{ format_num(data.total_likes) }}</span>
                    </span>
                    <span>
                      <font-awesome-icon class="mt-2 mr-2" style="color: #FFFFFF; font-size: 20px" icon="comment" />
                      <span style="color: #FFFFFF; font-size: 20px">{{ format_num(data.total_comments) }}</span>
                    </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>

          <b-col v-if="!post_loading && total_posts > 0 && !no_more_posts" lg="12" class="mt-2 mb-0">
            <b-button block pill :disable="new_posts_loading" class="my-1" @click="NewPostPage"
              style="color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              <span v-if="!new_posts_loading">
                {{ $t('load_more_posts') }}
              </span>
              <b-spinner v-else style="width: 2rem; height: 2rem; font-size: 10px; color: #FFFFFF">
              </b-spinner>
            </b-button>
          </b-col>

          <b-col v-if="no_more_posts" lg="12" class="mt-2 mb-0 text-center">
            <p style="font-size: 14px; text-align: center; color: #000000">{{ $t('no_more_posts') }}</p>
          </b-col>
        </div>

        <div v-else>
          <b-row>
            <b-col lg="3" class="mb-1 py-2 text-left"
              style="cursor: pointer; border-radius: 20px; background-color: #000000"
              @click="post_selected = false; img_loading = true">
              <span class="mr-3">
                <font-awesome-icon style="color: #FFFFFF; font-size: 17px" icon="chevron-left" />
              </span>
              <span style="color: #FFFFFF">{{ $t('go_back') }}</span>
            </b-col>
            <b-col lg="8" class="text-left"></b-col>
            <b-col lg="12" class="mb-3 p-2" style="border-radius: 20px; background-color: #000000">
              <b-row>
                <b-col lg="2" class="pt-2">
                  <img :src="element.imgkit_profile_picture + '?tr=n-small_thumb'" class="ml-2" height="50px"
                    style="object-fit: cover; aspect-ratio: 1; border-radius: 50%;">
                </b-col>
                <b-col lg="8" class="pt-2 text-left">
                  <p class="py-0 my-0" style="color: #FFFFFF"><strong>{{ element.username }}</strong></p>
                  <p class="py-0 my-0" style="font-size: 14px; color: #FFFFFF">{{
                      moment.utc(post.created_at).local().fromNow()
                  }}</p>
                </b-col>
                <b-col lg="2" class="pt-3 text-center">
                  <font-awesome-icon v-if="!progress" style="cursor: pointer; color: #FFFFFF; font-size: 24px"
                    icon="flag" @click="ChangeActivePost(post, post_index)" />
                  <b-spinner v-else style="width: 24px; height: 24px; font-size: 10px; color: #FFFFFF" />
                </b-col>

                <b-col lg="12" v-if="post.type == 1" class="text-center">
                  <b-spinner v-if="img_loading"
                    style="margin-top: 80px; margin-bottom: 70px; width: 24px; height: 24px; font-size: 10px; color: #6a71ec" />
                  <br v-if="img_loading">
                  <img :src="post.imgkit_url_picture" v-bind:class="{ mini: img_loading }" class="mt-2" width="90%"
                    @load="img_loading = false" />
                  <p class="py-0 mt-2 ml-2 text-left" style="color: #FFFFFF">{{ post.text }}</p>
                </b-col>
                <b-col lg="12" v-if="post.type == 2" class="text-center">
                  <b-spinner v-if="img_loading"
                    style="margin-top: 80px; margin-bottom: 70px; width: 24px; height: 24px; font-size: 10px; color: #6a71ec" />
                  <br v-if="img_loading">
                  <a :href="post.url_video" target="_blank">
                    <div style="position: relative;">
                      <img :src="post.imgkit_url_picture" v-bind:class="{ mini: img_loading }" class="mt-2" width="100%"
                        style="object-fit: cover; aspect-ratio: 1;" @load="img_loading = false">
                      <div v-if="!img_loading" style="position: absolute; top: 45%; left: 44%;">
                        <font-awesome-icon style="color: #FFFFFF; font-size: 60px" icon="play" />
                      </div>
                    </div>
                  </a>
                  <p class="py-0 mt-2 ml-2 text-left" style="color: #FFFFFF">{{ post.text }}</p>
                </b-col>
                <b-col lg="12" v-if="post.type == 3" class="text-left">
                  <p class="py-0 mt-2 ml-2" style="color: #FFFFFF">{{ post.text }}</p>
                </b-col>
                <b-col lg="12" v-if="post.type == 4" class="text-center">
                  <b-spinner v-if="img_loading"
                    style="margin-top: 80px; margin-bottom: 70px; width: 24px; height: 24px; font-size: 10px; color: #6a71ec" />
                  <br v-if="img_loading">
                  <img v-if="post.imgkit_url_picture" :src="post.imgkit_url_picture"
                    v-bind:class="{ mini: img_loading }" class="mt-2" width="90%" @load="img_loading = false" />
                  <img v-else src="../assets/logo5.png" v-bind:class="{ mini: img_loading }" class="mt-2" width="90%"
                    @load="img_loading = false" />
                  <p v-if="post.text" class="py-0 mt-2 ml-2 text-left" style="color: #FFFFFF">{{ post.text }}</p>

                  <b-row v-for="(datax, indexx) of post.questions" :key="datax.question + indexx" align-h="center"
                    class="mx-auto px-auto pt-2 pb-2 mb-1 text-left"
                    style="border-radius: 20px; background-color: #000000">
                    <b-col lg="12">
                      <b-row class="mx-auto px-auto mb-1">
                        <b-col lg="12" class="px-1 py-0 text-left">
                          <p class="py-0 mt-0 mb-1" style="color: #FFFFFF; font-size: 20px;">
                            {{ datax.question.question }}
                          </p>
                        </b-col>
                      </b-row>

                      <b-row v-for="(datay, indexy) of datax.answers" :key="indexy" class="mx-auto mb-2"
                        style="border-radius: 10px; border-style: solid; border-width: 1px; border-color: #6a71ec">
                        <b-col lg="10" class="py-0 pl-0 pr-2 mb-1 text-center" align-self="center">
                          <p class="my-0 py-0" style="color: #FFFFFF">{{ datay.answer.answer }}</p>
                        </b-col>
                        <b-col lg="2" class="m-0 p-0 pr-2 text-right" align-self="center">
                          <p class="my-0 py-0" style="color: #FFFFFF">{{ datay.percentage }}%</p>
                        </b-col>

                        <b-col lg="12" class="m-0 p-0">
                          <div
                            v-bind:style="{ borderRadius: (datay.percentage == 100 ? '0px 0px 8px 8px' : '0px 0px 0px 8px'), width: datay.percentage + '%' }"
                            style="height: 10px; border-radius: 0px 0px 8px 8px; background-color: #6a71ec;">
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>

                    <b-col lg="12" style="color: #FFFFFF">
                      <p v-if="post.ended == 0" class="my-0 py-0 textl-left">{{
                          format_num(datax.total_answers_for_question) + ' ' + $t('votes')
                      }} - {{ $t('in_progress') }}
                      </p>
                      <p v-else class="my-0 py-0 textl-left">{{ format_num(datax.total_answers_for_question) + ' ' +
                          $t('votes')
                      }} - {{ $t('already_ended') }}</p>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col lg="12" class="text-left mb-2">
                  <span class="mr-3">
                    <font-awesome-icon class="mt-2 ml-2 mr-2" style="color: #FFFFFF; font-size: 20px" icon="heart" />
                    <span style="color: #FFFFFF; font-size: 20px">{{ format_num(post.total_likes) }}</span>
                  </span>
                  <span>
                    <font-awesome-icon class="mt-2 mr-2" style="color: #FFFFFF; font-size: 20px" icon="comment" />
                    <span style="color: #FFFFFF; font-size: 20px">{{ format_num(post.total_comments) }}</span>
                  </span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-modal>
    















    <b-modal id="modal-subcriptions" ref="modal" title-class="text-font-black"
      :title="$t('user_suscription') + ' - @' + element.id" modal-class="model-content" hide-footer
      content-class="shadow" style="max-height: 400px; overflow-y: auto;" @show="ResetModal" @hidden="ResetModal">
      <b-container>
        <b-col md="12" class="my-1">
            <b-form-group label-for="filterInput" >
              <p class="text-left my-0" style="color: #000000; font-size: 14px;">{{ $t('name_suscription') + ':' }}</p>
              <b-input-group size="sm" style="box-shadow: 0px 10px 8px #B0B0B0;">
                <b-form-input id="filterInput" v-model="form_suscription.name" type="text"
                  :placeholder="$t('name_suscription')+'...'"
                  style="border-color: #000000; background-color: #E0E0E0; border-right: none; border-left: none;" />
              </b-input-group>
            <br>
              <b-form-group label-for="filterInput" >
                <p class="text-left my-0" style="color: #000000; font-size: 14px;">{{ $t('description_suscription') + ':' }}</p>
                <b-input-group size="sm" style="box-shadow: 0px 10px 8px #B0B0B0;">
                  <b-form-input id="filterInput" v-model="form_suscription.description" type="text"
                    :placeholder="$t('description_suscription')+'...'"
                    style="border-color: #000000; background-color: #E0E0E0; border-right: none; border-left: none;" />
                </b-input-group>
              </b-form-group>
              <b-form-group label-for="filterInput" >
                <p class="text-left my-0" style="color: #000000; font-size: 14px;">{{ $t('cost_suscription') + ':' }}</p>
                <b-input-group size="sm" style="box-shadow: 0px 10px 8px #B0B0B0;">
                  <b-form-input id="filterInput" v-model="form_suscription.cost" type="number"
                    :placeholder="$t('cost_suscription')+'...'"
                    style="border-color: #000000; background-color: #E0E0E0; border-right: none; border-left: none;" />
                </b-input-group>
              </b-form-group>
            </b-form-group>
              <br>
          </b-col>
        
          <div v-if="!mode_edit_suscription" class="row">
                <div class="col-md-12">
                  <b-button block pill  class="mb-3" @click="createSuscription()" type="submit" style="box-shadow: 0px 5px 15px #1E202D; color: #FFFFFF; font-size: 20px; border-color: rgb(106, 113, 236); background-color: rgb(106, 113, 236)" > 
                    <span v-show="!progress" style="color: #000000"><strong>{{ $t('save_suscription') }}</strong></span></b-button>
                </div>
            <div class="col-md-1"></div>
          </div>
          
          <div v-else class="row">
                <div class="col-md-12">
                  <b-button block pill  class="mb-3" @click="updateSuscription()" type="submit" style="box-shadow: 0px 5px 15px #1E202D; color: #FFFFFF; font-size: 20px; border-color: rgb(106, 113, 236); background-color: rgb(106, 113, 236)" > 
                    <span v-show="!progress" style="color: #000000"><strong>{{ $t('edit_suscription') }}</strong></span></b-button>
                </div>
            <div class="col-md-1"></div>
          </div>
          
          <b-row class="mt-3 px-3">
          <b-col sm="12"> 
            <div
              style="box-shadow: 0px 10px 8px #B0B0B0; border: 3px; border-color: #000000; background-color: #FFFFFF">
              <b-table id="my-table" show-empty small fixed stacked="md" sticky-header="true" hover thead-class="header"
                :primary-key="id" :items="suscriptions" :fields="fieldSuscription" :current-page="current_page" :per-page="0"
                :empty-html="emptyHTML" :empty-filtered-html="emptyFilteredHTML" :busy="isBusy">
                <template v-slot:cell(name)="row">
                  <span class="my-0 py-0 mx-1"> 
                    {{ row.item.name }}
                  </span>
                </template>

                <template v-slot:cell(description)="row">
                  <span class="my-0 py-0 mx-1"> 
                    {{ row.item.description }}
                  </span>
                </template>

                <template v-slot:cell(cost)="row">
                  <span class="my-0 py-0 mx-1"> 
                    {{ row.item.cost }}
                  </span>
                </template>

                <template v-slot:cell(actions)="row">
                  <b-button size="sm" variant="white"
                    :title="$t('edit')" @click="editSuscription(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="edit" />
                  </b-button>

                  <b-button v-if="row.item.active == 1 && row.item.id != id_user" size="sm" variant="white"
                    :title="$t('deactivate')" @click="ChangeActiveSubscription(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="check-square" />
                  </b-button>
                  <b-button v-if="row.item.active == 0 && row.item.id != id_user" size="sm" variant="white"
                    :title="$t('activate')" @click="ChangeActiveSubscription(row.item)">
                    <font-awesome-icon style="color: #000000; font-size: 17px" icon="minus-square" />
                  </b-button>

                

                  <!-- <b-button size="sm" variant="white"
                    :title="$t('deactivate')" @click="ChangeActive(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="trash" />
                  </b-button> -->
                  
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>


      </b-container>
    </b-modal>






    <b-modal id="modal-coins-operation" ref="modal" title-class="text-font-black"
      :title="$t('user_suscription') + ' - @' + element.id" modal-class="model-content" hide-footer
      content-class="shadow" style="max-height: 400px; overflow-y: auto;" @show="ResetModal" @hidden="ResetModal">
      <b-container>
        <b-col md="12" class="my-1">
              <b-form-group label-for="filterInput" >
                <p class="text-left my-0" style="color: #000000; font-size: 14px;">{{ $t('cost_suscription') + ':' }}</p>
                <b-input-group size="sm" style="box-shadow: 0px 10px 8px #B0B0B0;">
                  <b-form-input id="filterInput" v-model="form_suscription.cost" type="number"
                    :placeholder="$t('cost_suscription')+'...'"
                    style="border-color: #000000; background-color: #E0E0E0; border-right: none; border-left: none;" />
                </b-input-group>
              </b-form-group>
          </b-col>
        
          <div v-if="!mode_edit_suscription" class="row">
                <div class="col-md-12">
                  <b-button block pill  class="mb-3" @click="createSuscription()" type="submit" style="box-shadow: 0px 5px 15px #1E202D; color: #FFFFFF; font-size: 20px; border-color: rgb(106, 113, 236); background-color: rgb(106, 113, 236)" > 
                    <span v-show="!progress" style="color: #000000"><strong>{{ $t('save_suscription') }}</strong></span></b-button>
                </div>
            <div class="col-md-1"></div>
          </div>
          
          <div v-else class="row">
                <div class="col-md-12">
                  <b-button block pill  class="mb-3" @click="updateSuscription()" type="submit" style="box-shadow: 0px 5px 15px #1E202D; color: #FFFFFF; font-size: 20px; border-color: rgb(106, 113, 236); background-color: rgb(106, 113, 236)" > 
                    <span v-show="!progress" style="color: #000000"><strong>{{ $t('edit_suscription') }}</strong></span></b-button>
                </div>
            <div class="col-md-1"></div>
          </div>
          
          <b-row class="mt-3 px-3">
          <b-col sm="12"> 
            <div
              style="box-shadow: 0px 10px 8px #B0B0B0; border: 3px; border-color: #000000; background-color: #FFFFFF">
              <b-table id="my-table" show-empty small fixed stacked="md" sticky-header="true" hover thead-class="header"
                :primary-key="id" :items="suscriptions" :fields="fieldSuscription" :current-page="current_page" :per-page="0"
                :empty-html="emptyHTML" :empty-filtered-html="emptyFilteredHTML" :busy="isBusy">
                <template v-slot:cell(name)="row">
                  <span class="my-0 py-0 mx-1"> 
                    {{ row.item.name }}
                  </span>
                </template>

                <template v-slot:cell(description)="row">
                  <span class="my-0 py-0 mx-1"> 
                    {{ row.item.description }}
                  </span>
                </template>

                <template v-slot:cell(cost)="row">
                  <span class="my-0 py-0 mx-1"> 
                    {{ row.item.cost }}
                  </span>
                </template>

                <template v-slot:cell(actions)="row">
                  <b-button size="sm" variant="white"
                    :title="$t('edit')" @click="editSuscription(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="edit" />
                  </b-button>

                  <b-button v-if="row.item.active == 1 && row.item.id != id_user" size="sm" variant="white"
                    :title="$t('deactivate')" @click="ChangeActiveSubscription(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="check-square" />
                  </b-button>
                  <b-button v-if="row.item.active == 0 && row.item.id != id_user" size="sm" variant="white"
                    :title="$t('activate')" @click="ChangeActiveSubscription(row.item)">
                    <font-awesome-icon style="color: #000000; font-size: 17px" icon="minus-square" />
                  </b-button>

                

                  <!-- <b-button size="sm" variant="white"
                    :title="$t('deactivate')" @click="ChangeActive(row.item)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="trash" />
                  </b-button> -->
                  
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>


      </b-container>
    </b-modal>








  </div>
</template>

<script>

import $ from 'jquery'
import Swal from "sweetalert2"
import { mapActions } from "vuex"
import moment from "moment"
import Cookies from 'js-cookie'

export default {
  name: "Users",

  data() {
    return {
      img_loading: true,
      img_loaders: [],
      is_mobile: false,
      id_user: Cookies.get('id_user'),
      moment: moment,
      language: navigator.language.substring(0, 2),
      element: {},
      post: {},
      post_index: 0,
      posts: [],
      post_loading: false,
      post_selected: false,
      grid_view: true,
      new_posts_loading: false,
      no_more_posts: false,
      total_posts: 0,
      active_status_label: this.$i18n.t('all').toUpperCase(),
      active_status_number: 0,
      verified_status_label: this.$i18n.t('all').toUpperCase(),
      verified_status_number: 0,
      premium_status_label: this.$i18n.t('all').toUpperCase(),
      premium_status_number: 0,
      total: 0,
      isBusy: true,
      isBusy2: true,
      progress: false,
      mode_edit_suscription:0,
      emptyHTML: `<div class="mt-2 text-center"><h6>${this.$i18n.t('no_elements')}</h6></div>`,
      emptyFilteredHTML: `<div class="mt-2 text-center"><h6>${this.$i18n.t('no_elements_query')}</h6></div>`,
      id: "",
      active_status: "",
      active_statuses: [
        { active_status: this.$i18n.t('all').toUpperCase(), number: 0 },
        { active_status: this.$i18n.t('actives').toUpperCase(), number: 1 },
        { active_status: this.$i18n.t('inactives').toUpperCase(), number: 0 }
      ],
      verified_status: "",
      verified_statuses: [
        { verified_status: this.$i18n.t('all').toUpperCase(), number: 0 },
        { verified_status: this.$i18n.t('verified').toUpperCase(), number: 1 },
        { verified_status: this.$i18n.t('not_verified').toUpperCase(), number: 0 }
      ],
      premium_status: "",
      premium_statuses: [
        { premium_status: this.$i18n.t('all').toUpperCase(), number: 0 },
        { premium_status: 'PREMIUM', number: 3 },
        { premium_status: 'NO PREMIUM', number: 2 }
      ],
      field: [
        {
          key: "username",
          label: this.$i18n.t('username'),
          sortable: false,
          thStyle: { width: "30%" }
        },
        {
          key: "followers",
          label: this.$i18n.t('followers'),
          sortable: false,
        },
        {
          key: "following",
          label: this.$i18n.t('following'),
          sortable: false,
        },
        {
          key: "member_since",
          label: this.$i18n.t('member_since'),
          sortable: false,
        },
        {
          key: "actions",
          label: this.$i18n.t('actions'),
          sortable: false,
          thStyle: { width: "25%" }
        },
      ],
      fieldSuscription: [
        {
          key: "name",
          label: this.$i18n.t('name_suscription'),
          sortable: false,
          // thStyle: { width: "30%" }
        },
        {
          key: "description",
          label: this.$i18n.t('description_suscription'),
          sortable: false,
        },
        {
          key: "cost",
          label: this.$i18n.t('cost_suscription'),
          sortable: false,
        },
        {
          key: "actions",
          label: this.$i18n.t('actions'),
          sortable: false,
          thStyle: { width: "25%" }
        },
      ],
      form_suscription:{
        id_user:0,
        name:"",
        description:"",
        cost:0,
      },
      suscriptions:[],
      per_page: 20,
      current_page: 1,
      page_options: [10, 20, 50, 100],
      filter: null,
      users: [],
      usersTMP: [],
    };
  },
  computed: {
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    if ($(window).width() < 500) this.is_mobile = true

    this.moment.locale(this.language)
    this.AllUsers()
  },
  methods: {
    ...mapActions(["GetByID", "GetAll", "ChangeStatus", "Update","Save"]),
    img_is_loading(index) {
      return this.img_loaders[index]
    },
    format_num(num) {
      if (num >= 1000 && num < 1000000) {
        return (num / 1000).toFixed(2) + 'K'
      }
      else if (num >= 1000000) {
        return (num / 1000000).toFixed(2) + 'M'
      }
      else {
        return num
      }
    },
    ShowImg(index) {
      this.img_loaders[index] = false
    },
    ResetModal() {
      this.mode_edit_suscription=0;
      this.$nextTick(() => {
        this.$bvModal.hide("modal-details");
      });
    },
    ClearFilter() {
      this.filter = ""
      this.active_status_label = this.$i18n.t('all').toUpperCase()
      this.AllUsers()
    },
    SearchInput() {
      let filterTMP = this.filter
      this.active_status_label = this.$i18n.t('all').toUpperCase()

      if (filterTMP) {
        //Ask for Users by Query (first 10)
        this.isBusy = true

        let form = {
          element: 'user',
          query: {
            query: filterTMP,
            order_key: 'id',
            order_value: 'desc',
            limit: 10,
          },
          columns: ['dashboard'],
          params: {
            dashboard: 1
          }
        }

        if (this.active_status_label != this.$i18n.t('all').toUpperCase()) {
          form.query['active'] = this.active_status_number
        }

        if (this.premium_status_label != this.$i18n.t('all').toUpperCase()) {
          form.columns.push('id_role')
          form.params['id_role'] = this.premium_status_number
        }

        this.GetAll(form)
          .then((response) => {
            if (response.code == 200) {
              this.isBusy = false
              this.isBusy2 = false
              this.users = response.data
              this.usersTMP = response.data
              this.total = response.data.length
            }
            else {
              this.isBusy = false
              this.isBusy2 = false
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            }
          })
          .catch((error) => {
            console.log(error)
            this.isBusy = false
            this.isBusy2 = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          })
      }
      else {
        this.AllUsers()
      }

      return true
    },
    OnFiltered(filteredItems) {
      this.total = filteredItems.length
      this.current_page = 1
    },
    PageChanged() {
      this.AllUsers()
    },
    AllUsers() {
      this.isBusy = true

      let form = {
        element: 'user',
        query: {
          order_key: 'id',
          order_value: 'desc',
          limit: this.per_page,
          page: this.current_page,
        },
        columns: ['dashboard'],
        params: {
          dashboard: 1
        }
      }

      if (this.active_status_label != this.$i18n.t('all').toUpperCase()) {
        form.query['active'] = this.active_status_number
      }

      if (this.premium_status_label != this.$i18n.t('all').toUpperCase()) {
        form.columns.push('id_role')
        form.params['id_role'] = this.premium_status_number
      }

      if (this.verified_status_label != this.$i18n.t('all').toUpperCase()) {
        form.columns.push('verified')
        form.params['verified'] = this.verified_status_number
      }

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.isBusy = false
            this.isBusy2 = false
            this.users = response.data.data
            this.usersTMP = response.data.data
            this.total = response.data.meta.total
          }
          else {
            this.isBusy = false
            this.isBusy2 = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.isBusy = false
          this.isBusy2 = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        })
    },
    ActiveSelected(data) {
      this.active_status_label = data.active_status
      this.active_status_number = data.number
      this.AllUsers()
    },
    VerifiedSelected(data) {
      this.verified_status_label = data.verified_status
      this.verified_status_number = data.number
      this.AllUsers()
    },
    PremiumSelected(data) {
      this.premium_status_label = data.premium_status
      this.premium_status_number = data.number
      this.AllUsers()
    },
    ProfileTMP(data) {
      this.img_loading = true
      this.grid_view = true
      this.post_selected = false
      this.post_index = 0
      this.post = {}
      this.posts = []
      this.img_loaders = []
      this.total_posts = 0
      this.element = data

      if (this.element.link && this.element.link.indexOf('http') < 0) {
        this.element.link = 'http://' + this.element.link.toLowerCase()
      }

      this.posts_page = 1
      this.new_posts_loading = false
      this.no_more_posts = false

      let form = {
        element: 'post',
        query: {
          active: 1,
          order_key: 'id',
          order_value: 'desc',
          limit: 9,
          page: 1,
        },
        columns: ['id_user'],
        params: {
          id_user: data.id
        }
      }

      this.post_loading = true

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.post_loading = false
            this.posts = response.data.data
            this.total_posts = response.data.meta.total

            for (let i = 0; i < this.posts.length; i++) {
              this.img_loaders.push(true)
            }
          }
          else {
            this.post_loading = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.post_loading = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        })
    },
    SuscriptionTMP(data) {
      console.log("ejecutandose")
      console.log("data",data);
      this.form_suscription.id = data.id;
      this.element = data
      let form = {
        element: 'user_subscription',
        // query: {
        //   active: 1,
        //   order_key: 'id',
        //   order_value: 'desc',
        //   limit: 9,
        //   page: 1,
        // },
        columns: ['id_user'],
        params: {
          id_user: data.id
        }
      }

      // this.post_loading = true
      console.log("form", form)
      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.post_loading = false
            this.suscriptions = response.data
            // this.total_posts = response.data.meta.total

            // for (let i = 0; i < this.posts.length; i++) {
            //   this.img_loaders.push(true)
            // }
          }
          else {
            this.post_loading = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.post_loading = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        })
    },
    updateSuscription(){
      const validation =
        !this.form_suscription.id ||
        !this.form_suscription.cost ||
        !this.form_suscription.name ||
        !this.form_suscription.description
      if (validation) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_all_fields'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        })
        return
      }

    
      let form = {
      element: 'user_subscription',
      update: 'update',
      id: this.form_suscription.id,
      data: { 
        id_user: this.form_suscription.id_user,
        cost: this.form_suscription.cost,
        name: this.form_suscription.name,
        description: this.form_suscription.description,
       }
    }
      this.Update(form)
        .then((response) => {
          if (response.code == 200) {
            this.$nextTick(() => {
              this.$bvModal.hide("modal-subcriptions")
            })
            this.form_suscription.id = ""
            this.form_suscription.cost = 0
            this.form_suscription.name = ""
            this.form_suscription.description = ""
            this.mode_edit_suscription = 0
            // this.LoadCounters()
            // this.progress = false

            Swal.fire({
              title: this.$i18n.t('updated_suscription'),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          console.log(error)
          this.progress = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    createSuscription(){
      const validation =
        !this.form_suscription.id ||
        !this.form_suscription.cost ||
        !this.form_suscription.name ||
        !this.form_suscription.description
      if (validation) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_all_fields'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        })
        return
      }

      let form = {
        element: 'user_subscription',
        data: {
          id_user: this.form_suscription.id,
          cost: this.form_suscription.cost,
          name: this.form_suscription.name,
          description: this.form_suscription.description,
        }
      };
      this.Save(form)
        .then((response) => {
          if (response.code == 200) {
            this.$nextTick(() => {
              this.$bvModal.hide("modal-subcriptions")
            })
            this.form_suscription.id = ""
            this.form_suscription.cost = 0
            this.form_suscription.name = ""
            this.form_suscription.description = ""
            // this.LoadCounters()
            // this.progress = false

            Swal.fire({
              title: this.$i18n.t('created_suscription'),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          console.log(error)
          this.progress = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    createCoinOperation(){
      const validation =
        !this.form_suscription.cost
      if (validation) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_all_fields'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        })
        return
      }
      let form = {
        element: 'coin_operation',
        data: {
          id_creator: this.form_operation.id,
          id_coin: 1,
          id_type_operation: 1,
          id_user_subscribed: 0,
          id_subscription: 0,
          amount: this.form_operation.amount,
        }
      };
      this.Save(form)
        .then((response) => {
          if (response.code == 200) {
            this.$nextTick(() => {
              this.$bvModal.hide("modal-subcriptions")
            })
            this.form_suscription.id = ""
            this.form_suscription.cost = 0
            this.form_suscription.name = ""
            this.form_suscription.description = ""
            // this.LoadCounters()
            // this.progress = false

            Swal.fire({
              title: this.$i18n.t('created_suscription'),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          console.log(error)
          this.progress = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },

    




    editSuscription(data){
      console.log(data)
      this.form_suscription.id = data.id;
      this.form_suscription.cost = data.cost;
      this.form_suscription.name = data.name;
      this.form_suscription.description = data.description;
      this.mode_edit_suscription = true; 
    },
    PostSelected(data, index) {
      this.img_loading = true
      this.post_selected = true
      this.post = data
      this.post_index = index
    },
    NewPostPage() {
      this.posts_page = this.posts_page + 1

      let form = {
        element: 'post',
        query: {
          active: 1,
          order_key: 'id',
          order_value: 'desc',
          limit: 9,
          page: this.posts_page,
        },
        columns: ['id_user'],
        params: {
          id_user: this.element.id
        }
      }

      this.new_posts_loading = true

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            if (response.data.data.length > 0) {
              this.new_posts_loading = false
              for (let item of response.data.data) {
                this.img_loaders.push(true)
                this.posts.push(item)
              }
            }
            else {
              this.new_posts_loading = false
              this.no_more_posts = true
            }
          }
          else {
            this.new_posts_loading = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.new_posts_loading = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        })
    },
    MakePremium(data) {
      Swal.fire({
        title: this.$i18n.t('make_premium_title'),
        text: this.$i18n.t('make_premium_text'),
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: this.$i18n.t('accept'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = {
            element: 'user',
            update: 'update',
            id: data.id,
            data: { id_role: 3 }
          }

          this.Update(form)
            .then((response) => {
              if (response.code == 200) {
                data.id_role = 3

                Swal.fire({
                  title: this.$i18n.t('user') + " " + this.$i18n.t('updated').toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              else {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                })
              }
            })
            .catch((error) => {
              console.log(error)
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            })
        }
      })
    },
    RemovePremium(data) {
      Swal.fire({
        title: this.$i18n.t('remove_premium_title'),
        text: this.$i18n.t('remove_premium_text'),
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: this.$i18n.t('accept'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = {
            element: 'user',
            update: 'update',
            id: data.id,
            data: { id_role: 2 }
          }

          this.Update(form)
            .then((response) => {
              if (response.code == 200) {
                data.id_role = 2

                Swal.fire({
                  title: this.$i18n.t('user') + " " + this.$i18n.t('updated').toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              else {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                })
              }
            })
            .catch((error) => {
              console.log(error)
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            })
        }
      })
    },
    MakeVerify(data) {
      Swal.fire({
        title: this.$i18n.t('make_verified_title'),
        text: this.$i18n.t('make_verified_text'),
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: this.$i18n.t('accept'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = {
            element: 'user',
            update: 'update',
            id: data.id,
            data: { verified: 1 }
          }

          this.Update(form)
            .then((response) => {
              if (response.code == 200) {
                data.verified = 1

                Swal.fire({
                  title: this.$i18n.t('user') + " " + this.$i18n.t('updated').toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              else {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                })
              }
            })
            .catch((error) => {
              console.log(error)
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            })
        }
      })
    },
    RemoveVerify(data) {
      Swal.fire({
        title: this.$i18n.t('remove_verified_title'),
        text: this.$i18n.t('remove_verified_text'),
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: this.$i18n.t('accept'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = {
            element: 'user',
            update: 'update',
            id: data.id,
            data: { verified: '0' }
          }

          this.Update(form)
            .then((response) => {
              if (response.code == 200) {
                data.verified = 0

                Swal.fire({
                  title: this.$i18n.t('user') + " " + this.$i18n.t('updated').toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              else {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                })
              }
            })
            .catch((error) => {
              console.log(error)
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            })
        }
      })
    },
    MakeContentCreator(data) {
      Swal.fire({
        title: this.$i18n.t('make_content_creator_title'),
        text: this.$i18n.t('make_content_creator_text'),
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: this.$i18n.t('accept'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = {
            element: 'user',
            update: 'update',
            id: data.id,
            data: { content_creator: 1 }
          }

          this.Update(form)
            .then((response) => {
              if (response.code == 200) {
                data.content_creator = 1

                Swal.fire({
                  title: this.$i18n.t('user') + " " + this.$i18n.t('updated').toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              else {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                })
              }
            })
            .catch((error) => {
              console.log(error)
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            })
        }
      })
    },
    RemoveContentCreator(data) {
      Swal.fire({
        title: this.$i18n.t('remove_content_creator_title'),
        text: this.$i18n.t('remove_content_creator_text'),
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: this.$i18n.t('accept'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = {
            element: 'user',
            update: 'update',
            id: data.id,
            data: { content_creator: '0' }
          }

          this.Update(form)
            .then((response) => {
              if (response.code == 200) {
                data.content_creator = 0

                Swal.fire({
                  title: this.$i18n.t('user') + " " + this.$i18n.t('updated').toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              else {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                })
              }
            })
            .catch((error) => {
              console.log(error)
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            })
        }
      })
    },
    ChangeActive(data) {
      let active = data.active == 1 ? 0 : 1
      let title =
        data.active == 1
          ? "deactivate"
          : "activate"
      let title2 =
        data.active == 1
          ? "deactivated"
          : "activated"
      let text =
        data.active == 1
          ? "deactivate_question"
          : "activate_question"
      Swal.fire({
        title: this.$i18n.t(title) + ' ' + this.$i18n.t('user').toLowerCase(),
        text: this.$i18n.t(text) + ' ' + this.$i18n.t('user').toLowerCase() + '?',
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: active == 0 ? this.$i18n.t('deactivate') : this.$i18n.t('activate'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          this.progress = true

          let form = {
            element: 'user',
            id: data.id,
            status: active
          }

          this.ChangeStatus(form)
            .then((response) => {
              if (response.code == 200) {
                data.active = active
                this.progress = false

                Swal.fire({
                  title: this.$i18n.t('user') + " " + this.$i18n.t(title2).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              else {
                this.progress = false
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                })
              }
            })
            .catch((error) => {
              this.progress = false
              console.log(error)
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            })
        }
      })
    },
    ChangeActivePost(data, index) {
      let active = data.active == 1 ? 0 : 1
      let title =
        data.active == 1
          ? "deactivate"
          : "activate"
      let title2 =
        data.active == 1
          ? "deactivated2"
          : "activated2"
      let text =
        data.active == 1
          ? "deactivate_question2"
          : "activate_question2"
      Swal.fire({
        title: this.$i18n.t(title) + ' ' + this.$i18n.t('post').toLowerCase(),
        text: this.$i18n.t(text) + ' ' + this.$i18n.t('post').toLowerCase() + '?',
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: active == 0 ? this.$i18n.t('deactivate') : this.$i18n.t('activate'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          this.progress = true

          let form = {
            element: 'post',
            id: data.id,
            status: active
          }

          this.ChangeStatus(form)
            .then((response) => {
              if (response.code == 200) {
                data.active = active
                this.progress = false
                this.post = {}
                this.posts.splice(index, 1)
                this.img_loaders.splice(index, 1)
                this.post_selected = false

                Swal.fire({
                  title: this.$i18n.t('post') + " " + this.$i18n.t(title2).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              else {
                this.progress = false
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                })
              }
            })
            .catch((error) => {
              this.progress = false
              console.log(error)
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            })
        }
      })
    },
    ChangeActiveSubscription(data, index) {
      let active = data.active == 1 ? 0 : 1
      let title =
        data.active == 1
          ? "deactivate"
          : "activate"
      let title2 =
        data.active == 1
          ? "deactivated2"
          : "activated2"
      let text =
        data.active == 1
          ? "deactivate_question2"
          : "activate_question2"
      Swal.fire({
        title: this.$i18n.t(title) + ' ' + this.$i18n.t('suscription').toLowerCase(),
        text: this.$i18n.t(text) + ' ' + this.$i18n.t('suscription').toLowerCase() + '?',
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: active == 0 ? this.$i18n.t('deactivate') : this.$i18n.t('activate'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          this.progress = true

          let form = {
            element: 'user_subscription',
            id: data.id,
            status: active
          }

          this.ChangeStatus(form)
            .then((response) => {
              if (response.code == 200) {
                data.active = active
                this.progress = false
                this.post = {}
                this.posts.splice(index, 1)
                this.img_loaders.splice(index, 1)
                this.post_selected = false

                Swal.fire({
                  title: this.$i18n.t('suscription') + " " + this.$i18n.t(title2).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              else {
                this.progress = false
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                })
              }
            })
            .catch((error) => {
              this.progress = false
              console.log(error)
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            })
        }
      })
    },
  },
}

</script>

<style>
.mini {
  height: 1px;
}

.table thead th {
  cursor: pointer;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #6a71ec !important;
  border-color: #6a71ec !important;
}

.header,
.table thead th,
thead,
th {
  background-color: #6a71ec !important;
  color: #FFFFFF;
}

.dropdownx {
  border-radius: 24px !important;
  height: 100% !important;
  width: 100% !important;
  border: none !important;
  background-color: #6a71ec !important;
  color: #FFFFFF !important;
}

.my-class .dropdown-menu {
  max-height: 100px;
  overflow-y: auto;
}

.div-btn {
  width: 100%;
  color: #FFFFFF;
  font-size: 20px;
  border-color: #6a71ec;
  background-color: #6a71ec
}

.variable-width {
  width: 80%;
}

.variable-height-width {
  max-height: 350px;
  max-width: 400px;
}

@media (max-width: 550px) {
  .variable-width {
    width: 100%;
  }

  .variable-height-width {
    max-height: 290px;
    max-width: 290px;
  }

  .variable-width2 {
    width: 100%;
  }
}
</style>

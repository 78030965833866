<template>
  <div id="User">
    <br />
    <h1 class="text2" style="color: #000000; margin-left: 10px; text-align: left;"><strong>{{ $t('users').toUpperCase() }}</strong></h1>
    <div class="content">
      <b-card style="border: none; background-color: #F2F2F2">
        <b-row>
          <b-col md="3">
            <h4 class="my-1" style="color: #000000; text-align: left"><strong>TOTAL:</strong></h4>
          </b-col>
          <b-col md="6"></b-col>
          <b-col md="3">
            <h4 class="my-1" style="color: #000000; text-align: left"><strong>{{ $t('status').toUpperCase() }}:</strong></h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3" class="my-1">
            <div class="py-1"
              style="box-shadow: 0px 10px 8px #B0B0B0; border-radius: 24px; width=100%; color: #FFFFFF; border-color: #6a71ec; background-color: #6a71ec">
              <span v-show="!isBusy" style="font-size: 24px;">{{ format_num(total) }}</span> 
              <span v-show="isBusy" style="font-size: 24px;">--</span> 
            </div>
          </b-col>
          <b-col md="6"></b-col>
          <b-col md="3" class="my-1">
            <b-dropdown offset="15" toggle-class="dropdownx" class="py-1 dropdownx my-class" :text="verified_status_label" style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              <b-dropdown-item v-for="data of verified_statuses" :key="data.id" @click="VerifiedSelected(data)">{{ data.verified_status }}</b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="4" class="my-1">
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group
              label-for="per_pageSelect"
              class="mb-0"
            >
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('per_page') + ':' }}</p>
              <b-form-select
                id="per_pageSelect"
                v-model="per_page"
                size="sm"
                :options="page_options"
                @change="PageChanged"
                style="border-color: #000000; background-color: #E0E0E0;"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group
              label-for="allPages"
              class="mb-0"
            >
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('current_page') + ':' }}</p>
              <b-pagination
              id="allPages"
              v-model="current_page"
              :total-rows="total"
              :per-page="per_page"
              aria-controls="my-table"
              size="sm"
              align="fill"
              @input="PageChanged()"
              class="variable-width2"
              ></b-pagination>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3 px-3">
          <b-col sm="12">
            <div style="box-shadow: 0px 10px 8px #B0B0B0; border: 3px; border-color: #000000; background-color: #FFFFFF">
              <b-table
                id="my-table"
                show-empty
                small
                fixed
                stacked="md"
                sticky-header="true"
                hover
                thead-class="header"
                :primary-key="id"
                :items="usersTMP"
                :fields="field"
                :current-page="current_page"
                :per-page="0"
                :empty-html="emptyHTML"
                :empty-filtered-html="emptyFilteredHTML"
                :busy="isBusy"
              >
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle" style="color: #6a71ec;"></b-spinner>
                    <strong> {{ $t('loading') }}...</strong>
                  </div>
                </template>

                <template v-slot:cell(username)="row">
                  <img :src="row.item.user.imgkit_profile_picture + '?tr=n-xsmall_thumb'" alt="" height="20px" style="border-radius: 50%; aspect-ratio: 1; object-fit: cover;">
                  <span class="my-0 py-0">
                    {{ '@' + row.item.user.username }}{{ row.item.user.id == id_user ? ' ('+$t('you').toLowerCase()+')' : '' }}
                  </span>
                  <font-awesome-icon v-if="row.item.user.verified == 1" class="mr-1" icon="check-circle" style="color: #6a71ec; font-size: 17px" />
                  <font-awesome-icon v-if="row.item.user.id_role == 3" icon="star" style="color: #6a71ec; font-size: 17px" />                 
                </template>

                <template v-slot:cell(followers)="row">
                  <p class="my-0 py-0">
                    {{ format_num(row.item.user.followers) }}
                  </p>
                </template>

                <template v-slot:cell(status)="row">
                  <p class="my-0 py-0">
                    {{ VerificationStatuses(row.item.status) }}
                  </p>
                </template>

                <template v-slot:cell(member_since)="row">
                  <p class="my-0 py-0">
                    {{ language == 'es'
                      ? moment.utc(row.item.user.created_at).local().format('D MMM YYYY') 
                      : moment.utc(row.item.user.created_at).local().format('MMM D, YYYY')  }}
                  </p>
                </template>

                <template v-slot:cell(actions)="row">                  
                  <b-button
                    v-if="row.item.status == 1 && !is_mobile"
                    v-b-modal.modal-details
                    size="sm"
                    variant="white"
                    class="ml-1"
                    :title="$t('details')"
                    @click="ProfileTMP(row.item)"
                  >
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="user-check" />
                  </b-button>

                  <!--<b-button
                    v-if="row.item.user.id != id_user"
                    size="sm"
                    variant="white"
                    :title="$t('delete')"
                    @click="Delete(row)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="trash-alt" />
                  </b-button>-->
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <b-modal
      id="modal-details"
      ref="modal"
      title-class="text-font-black"
      :title="$t('user') + ' - @' + user.username"
      modal-class="model-content"
      hide-footer
      content-class="shadow"
      size="lg"
      style="max-height: 400px; overflow-y: auto;"
      @hidden="ResetModal"
    >
      <b-container>
        <b-row class="mt-1 mb-2" style="border-radius: 20px; background-color: #000000">
          <b-col class="text-center mx-0 px-0 pt-4" md="4">
            <p class="py-0 mb-0" style="font-size: 26px; color: #FFFFFF"><strong>{{ format_num(user.followers) }}</strong></p>
            <p class="py-0 my-0" style="font-size: 12px; color: #FFFFFF">{{ $t('followers') }}</p>
          </b-col>
          <b-col class="text-center mt-2 mx-0 px-0" md="4">
            <img :src="user.imgkit_profile_picture + '?tr=n-small_thumb'" height="90px" style="border-radius: 50%; aspect-ratio: 1; object-fit: cover;" >
          </b-col>
          <b-col class="text-center mx-0 px-0 pt-4" md="4">
            <p class="py-0 mb-0" style="font-size: 26px; color: #FFFFFF"><strong>{{ format_num(user.following) }}</strong></p>
            <p class="py-0 my-0" style="font-size: 12px; color: #FFFFFF">{{ $t('following') }}</p>
          </b-col>
          <b-col class="text-center mx-0 px-0" md="12">
            <span class="py-0 mb-0" style="color: #FFFFFF"><strong>{{ user.first_name + ' ' + user.last_name }}</strong></span>
            <span class="ml-1" v-if="user.verified == 1">
              <div class="py-0 px-1 mb-0" style="display: inline-block; border-radius: 20px; background-color: #6a71ec; font-size: 10px; color: #FFFFFF">
                <font-awesome-icon icon="check-circle" style="color: #FFFFFF; font-size: 10px" />
              </div>
            </span>
            <span class="ml-1" v-if="user.id_role == 3">
              <div class="py-0 px-1 mb-0" style="display: inline-block; border-radius: 20px; background-color: #6a71ec; font-size: 10px; color: #FFFFFF">
                <span><strong>PREMIUM</strong></span>
                <font-awesome-icon icon="star" class="ml-1" style="color: #FFFFFF; font-size: 10px" />
              </div>
            </span>
            <span class="ml-1" v-if="user.id_role == 1">
              <div class="py-0 px-1 mb-0" style="display: inline-block; border-radius: 20px; background-color: #6a71ec; font-size: 10px; color: #FFFFFF">
                <span><strong>ADMIN</strong></span>
                <font-awesome-icon icon="crown" class="ml-1" style="color: #FFFFFF; font-size: 10px" />
              </div>
            </span>
          </b-col>
          <b-col md="12" class="mx-0 px-3 mb-3">
            <p class="py-0 mt-0 mb-1" style="color: #FFFFFF"><strong>{{ user.username }}</strong></p>
          </b-col>
        </b-row>

        <b-row class="mt-1 mb-2" style="border-radius: 20px; background-color: #B0B0B0">
          <b-col lg="12" class="mt-2 mb-0 text-center">
            <h5 class="text-center" style="color: #000000">{{ $t('verification_reason') + ': ' + verification_reason[language+'_name'] }}</h5>
          </b-col>
        
          <b-col lg="12" class="mt-2 mb-0 text-center">
            <p class="text-center" style="color: #000000">{{ element.description }}</p>
          </b-col>
        </b-row>

        <b-row class="mt-1 mb-2">
          <b-col lg="6" class="mt-2 mb-0 text-center">
            <h3 class="text-center" style="color: #6a71ec">{{ type_photo_1[language+'_name'] }}</h3>
            
            <img :src="element.url_verification_photo_1" style="{ max-width: 100%; max-height: 300px; }" alt="Foto de verificación 1">
          </b-col>
        
          <b-col lg="6" class="mt-2 mb-0 text-center">
            <h3 class="text-center" style="color: #6a71ec">{{ type_photo_2[language+'_name'] }}</h3>
            
            <img :src="element.url_verification_photo_2" style="{ max-width: 100%; max-height: 300px; }" alt="Foto de verificación 2">
          </b-col>
        </b-row>
        
        <b-row>
          <b-col lg="6">
            <b-button block pill v-show="!progress" class="my-3"
              @click="MakeVerify(3)"
              style="color: #000000; font-size: 20px; border-color: #B0B0B0; background-color: #B0B0B0">
              {{ $t('cancel').toUpperCase() }}
            </b-button>
          </b-col>

          <b-col lg="6">
            <b-button block pill v-show="!progress" class="my-3"
              @click="MakeVerify(2)"
              style="color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('accept').toUpperCase() }}
            </b-button>
          </b-col>
          <b-col>
            <div v-show="progress" class="text-center my-3" style="color: #6a71ec">
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>

import $ from 'jquery'
import Swal from "sweetalert2"
import { mapActions } from "vuex"
import moment from "moment"
import Cookies from 'js-cookie'

export default {
  name: "Users",

  data() {
    return {
      img_loading: 0,
      is_mobile: false,
      id_user: Cookies.get('id_user'),
      moment: moment,
      language: navigator.language.substring(0,2),
      element: {},
      user: {},
      verification_reason: {},
      type_photo_1: {},
      type_photo_2: {},
      verified_status_label: this.$i18n.t('all').toUpperCase(),
      verified_status_number: 0,
      total: 0,
      isBusy: true,
      isBusy2: true,
      progress: false,
      emptyHTML: `<div class="mt-2 text-center"><h6>${this.$i18n.t('no_elements')}</h6></div>`,
      emptyFilteredHTML: `<div class="mt-2 text-center"><h6>${this.$i18n.t('no_elements_query')}</h6></div>`,
      id: "",
      verified_status: "",
      verified_statuses: [
        {verified_status: this.$i18n.t('all').toUpperCase(), number: 0},
        {verified_status: this.$i18n.t('pending').toUpperCase(), number: 1},
        {verified_status: this.$i18n.t('accepted2').toUpperCase(), number: 2},
        {verified_status: this.$i18n.t('rejected2').toUpperCase(), number: 3},
      ],
      field: [
        {
          key: "username",
          label: this.$i18n.t('username'),
          sortable: false,
          thStyle: { width: "30%" }
        },
        {
          key: "followers",
          label: this.$i18n.t('followers'),
          sortable: false,
        },
        {
          key: "status",
          label: this.$i18n.t('status'),
          sortable: false,
        },
        {
          key: "verification_reason." + navigator.language.substring(0,2) + "_name",
          label: this.$i18n.t('reason'),
          sortable: false,
        },
        {
          key: "member_since",
          label: this.$i18n.t('member_since'),
          sortable: false,
        },
        {
          key: "actions",
          label: this.$i18n.t('actions'),
          sortable: false,
        },
      ],
      per_page: 20,
      current_page: 1,
      page_options: [10, 20, 50, 100],
      filter: null,
      users: [],
      usersTMP: [],
    };
  },
  computed: {
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    if ($(window).width() < 500)    this.is_mobile = true

    this.moment.locale(this.language)
    this.AllUserVerifications()
  },
  methods: {
    ...mapActions(["GetByID", "GetAll", "ChangeStatus", "Update"]),
    format_num(num) {
      if (num >= 1000 && num < 1000000){
        return (num/1000).toFixed(2) + 'K'
      }
      else if(num >= 1000000){
        return (num/1000000).toFixed(2) + 'M'
      }
      else {
        return num
      }
    },
    VerificationStatuses(status) {
      if (status == 1)        return this.$i18n.t('pending')
      else if (status == 2)   return this.$i18n.t('accepted2')
      else                    return this.$i18n.t('rejected2')
    },
    ResetModal() {
      this.element = {}
      this.user = {}
      this.verification_reason = {}
      this.type_photo_1 = {}
      this.type_photo_2 = {}

      this.$nextTick(() => {
        this.$bvModal.hide("modal-details");
      });
    },
    ClearFilter() {
      this.filter = ""
      this.AllUserVerifications()
    },
    OnFiltered(filteredItems) {
      this.total = filteredItems.length
      this.current_page = 1
    },
    PageChanged() {
      this.AllUserVerifications()
    },
    AllUserVerifications() {
      this.isBusy = true

      let form = { 
        element: 'user_verification',
        query: {
          order_key: 'id',
          order_value: 'desc',
          limit: this.per_page,
          page: this.current_page,
        },
        columns: [],
        params: {}
      }

      if (this.verified_status_label != this.$i18n.t('all').toUpperCase()) {
        form.columns.push('status')
        form.params['status'] = this.verified_status_number
      }

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.isBusy = false
            this.isBusy2 = false
            this.users = response.data.data
            this.usersTMP = response.data.data
            this.total = response.data.meta.total
          }
          else {
            this.isBusy = false
            this.isBusy2 = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.isBusy = false
          this.isBusy2 = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        })
    },
    VerifiedSelected(data) {
      this.verified_status_label = data.verified_status
      this.verified_status_number = data.number
      this.AllUserVerifications()
    },
    ProfileTMP(data) {
      this.img_loading = 0
      this.element = data
      this.user = data.user
      this.verification_reason = data.verification_reason
      this.type_photo_1 = data.type_photo_1
      this.type_photo_2 = data.type_photo_2
    },
    MakeVerify(status) {
      let title = status == 2 ? 'accept_user_verification' : 'reject_user_verification'
      let text = status == 2 ? 'accept_user_verification_text' : 'reject_user_verification_text'
      
      Swal.fire({
        title: this.$i18n.t(title),
        text: this.$i18n.t(text),
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: this.$i18n.t('accept'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
        let form = {
          element: 'user_verification',
          update: 'update',
          id: this.element.id,
          data: { status: status }
        }

        this.Update(form)
          .then((response) => {
            if (response.code == 200) {
              this.ResetModal()
              this.AllUserVerifications()
              
              Swal.fire({
                title: this.$i18n.t('user_verification') + " " + this.$i18n.t(status == 2 ? 'accepted2' : 'rejected2').toLowerCase(),
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            }
          })
          .catch((error) => {
            console.log(error)
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          })
        }
      })
    },
    ChangeActive(data) {
      let active = data.active == 1 ? 0 : 1
      let title =
        data.active == 1
          ? "deactivate"
          : "activate"
      let title2 =
        data.active == 1
          ? "deactivated2"
          : "activated2"
      let text =
        data.active == 1
          ? "deactivate_question"
          : "activate_question"
      Swal.fire({
        title: this.$i18n.t(title) + ' ' + this.$i18n.t('user_verification').toLowerCase(),
        text: this.$i18n.t(text) + ' ' + this.$i18n.t('user_verification').toLowerCase() + '?',
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: active == 0 ? this.$i18n.t('deactivate') : this.$i18n.t('activate'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          this.progress = true

          let form = {
            element: 'user_verification',
            id: data.id,
            status: active
          }

          this.ChangeStatus(form)
            .then((response) => {
              if (response.code == 200) {
                data.active = active
                this.progress = false
                
                Swal.fire({
                  title: this.$i18n.t('user_verification') + " " + this.$i18n.t(title2).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              else {
                this.progress = false
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                })
              }
            })
            .catch((error) => {
              this.progress = false
              console.log(error)
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            })
        }
      })
    },
    Delete(row) {
      Swal.fire({
        title: this.$i18n.t('reject') + ' ' + this.$i18n.t('user_verification').toLowerCase(),
        text: this.$i18n.t('reject_question2') + ' ' + this.$i18n.t('user_verification').toLowerCase() + '? ' + this.$i18n.t('delete_questionx2'),
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: this.$i18n.t('reject'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = {
            element: 'user_verification',
            update: 'update',
            id: row.item.id,
            data: { status: 3 }
          }

          this.Update(form)
            .then((response) => {
              if (response.code == 200) {
                //this.AllElements()
                this.usersTMP.splice(row.index,1)
                this.total--

                Swal.fire({
                  title: this.$i18n.t('user_verification') + ' ' + this.$i18n.t('deleted2').toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              else {
                console.log(response);
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                })
              }
            })
            .catch((error) => {
              console.log(error);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            });
        }
      });
    },
  },
}

</script>

<style>

.mini {
  height: 1px;
}

.table thead th {
  cursor: pointer;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #6a71ec !important;
  border-color: #6a71ec !important;
}

.header, .table thead th, thead, th {
  background-color: #6a71ec !important;
  color: #FFFFFF;
}

.dropdownx {
  border-radius: 24px!important;
  height: 100%!important;
  width: 100%!important;
  border: none!important;
  background-color: #6a71ec!important;
  color: #FFFFFF!important;
}

.my-class .dropdown-menu {
  max-height: 100px;
  overflow-y: auto;
}

.div-btn {
  width: 100%;
  color: #FFFFFF;
  font-size: 20px;
  border-color: #6a71ec;
  background-color: #6a71ec
}

.variable-width {
  width: 80%;
}

.variable-height-width {
    max-height: 350px;
    max-width: 400px;
  }

@media (max-width: 550px) {
  .variable-width {
    width: 100%;
  }

  .variable-height-width {
    max-height: 290px;
    max-width: 290px;
  }

  .variable-width2 {
    width: 100%;
  }
}

</style>

<template>
  <div id="UserReport">
    <br />
    <h1 class="text2" style="margin-left: 10px; text-align: left;"><strong>{{ $t('user_reports').toUpperCase() }}</strong></h1>
    <div class="content">
      <b-card style="border: none; background-color: #F2F2F2">
        <b-row>
          <b-col md="9">
            <h4 class="my-1" style="text-align: left"><strong>TOTAL:</strong></h4>
          </b-col>
          <b-col md="3">
          </b-col>
          <!--<b-col md="3">
            <h4 class="my-1" style="text-align: left"><strong>{{ $t('actives').toUpperCase() }}:</strong></h4>
          </b-col>-->
        </b-row>
        <b-row>
          <b-col md="3" class="my-1">
            <div class="py-1"
              style="box-shadow: 0px 10px 8px #B0B0B0; border-radius: 24px; width=100%; color: #FFFFFF; border-color: #6a71ec; background-color: #6a71ec">
              <span v-show="!isBusy" style="font-size: 24px;">{{ format_num(total) }}</span> 
              <span v-show="isBusy" style="font-size: 24px;">--</span> 
            </div>
          </b-col>
          <b-col md="9" class="my-1">
          </b-col>
          <!--<b-col md="3" class="my-1">
            <b-dropdown offset="15" toggle-class="dropdownx" class="py-1 dropdownx my-class" :text="active_status_label" style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              <b-dropdown-item v-for="data of active_statuses" :key="data.id" @click="ActiveSelected(data)">{{ data.active_status }}</b-dropdown-item>
            </b-dropdown>
          </b-col>-->
        </b-row>
        <b-row class="mt-2">
          <b-col md="4" class="my-1">
            <b-form-group
              label-for="filterInput"
              class="mb-0"
            >
              <p class="text-left my-0 py-0" style="font-size: 14px;">{{ $t('searcher') + ':' }}</p>
              <b-input-group size="sm">
                <b-input-group-prepend>
                  <span class="input-group-text" style="border-color: #000000; background-color: #E0E0E0; border-right: none;">
                    <font-awesome-icon @click="SearchInput" style="cursor: pointer; color: #000000; font-size: 17px" icon="search" />  
                  </span>
                </b-input-group-prepend>
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  @keyup.enter="SearchInput"
                  type="text"
                  :placeholder="$t('search')+'...'"
                  style="border-color: #000000; background-color: #E0E0E0; border-right: none; border-left: none;"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="ClearFilter" class="input-group-text" style="border-color: #000000; background-color: #E0E0E0; border-left: none;">
                    <font-awesome-icon style="color: #000000; font-size: 17px" icon="times-circle" /> 
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group
              label-for="per_pageSelect"
              class="mb-0"
            >
              <p class="text-left my-0 py-0" style="font-size: 14px;">{{ $t('per_page') + ':' }}</p>
              <b-form-select
                id="per_pageSelect"
                v-model="per_page"
                size="sm"
                :options="page_options"
                @change="PageChanged"
                style="border-color: #000000; background-color: #E0E0E0;"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group
              label-for="allPages"
              class="mb-0"
            >
              <p class="text-left my-0 py-0" style="font-size: 14px;">{{ $t('current_page') + ':' }}</p>
              <b-pagination
              id="allPages"
              v-model="current_page"
              :total-rows="total"
              :per-page="per_page"
              aria-controls="my-table"
              size="sm"
              align="fill"
              @input="PageChanged()"
              class="variable-width2"
              ></b-pagination>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3 px-3">
          <b-col sm="12">
            <div style="box-shadow: 0px 10px 8px #B0B0B0; border: 3px; border-color: #000000; background-color: #FFFFFF">
              <b-table
                id="my-table"
                show-empty
                small
                fixed
                stacked="md"
                sticky-header="true"
                hover
                thead-class="header"
                :primary-key="id"
                :items="usersTMP"
                :fields="field"
                :current-page="current_page"
                :per-page="0"
                :empty-html="emptyHTML"
                :empty-filtered-html="emptyFilteredHTML"
                :busy="isBusy"
              >
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle" style="color: #6a71ec;"></b-spinner>
                    <strong> {{ $t('loading') }}...</strong>
                  </div>
                </template>

                <template v-slot:cell(username)="row">
                  <img :src="row.item.imgkit_profile_picture + '?tr=n-xsmall_thumb'" alt="" height="20px" style="border-radius: 50%; aspect-ratio: 1; object-fit: cover;">
                  <span class="my-0 py-0">
                    {{ '@' + row.item.username }}{{ row.item.id == id_user ? ' ('+$t('you').toLowerCase()+')' : '' }}
                  </span>
                </template>

                <template v-slot:cell(reports_quantity)="row">
                  <p class="my-0 py-0">
                    {{ format_num(row.item.reports_quantity) }}
                  </p>
                </template>

                <template v-slot:cell(followers)="row">
                  <p class="my-0 py-0">
                    {{ format_num(row.item.followers) }}
                  </p>
                </template>

                <template v-slot:cell(following)="row">
                  <p class="my-0 py-0">
                    {{ format_num(row.item.following) }}
                  </p>
                </template>

                <template v-slot:cell(member_since)="row">
                  <p class="my-0 py-0">
                    {{ language == 'es'
                      ? moment.utc(row.item.created_at).local().format('D MMM YYYY') 
                      : moment.utc(row.item.created_at).local().format('MMM D, YYYY')  }}
                  </p>
                </template>

                <template v-slot:cell(actions)="row">                  
                  <b-button
                    v-if="row.item.active == 1 && row.item.id != id_user"
                    size="sm"
                    variant="white"
                    :title="$t('deactivate')"
                    @click="ChangeActive(row.item, index)"
                    class="mr-1">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="check-square" />
                  </b-button>

                  <b-button
                    v-if="row.item.active == 1 && !is_mobile"
                    v-b-modal.modal-details
                    size="sm"
                    variant="white"
                    class="mr-1"
                    :title="$t('details')"
                    @click="ProfileTMP(row.item)"
                  >
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="user" />
                  </b-button>

                  <b-button
                    v-if="row.item.active == 1"
                    v-b-modal.modal-reports
                    size="sm"
                    variant="white"
                    class="mr-1"
                    :title="$t('reports')"
                    @click="ReportTMP(row.item, row.index)"
                  >
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="flag" />
                  </b-button>

                  <b-button
                    v-if="row.item.active == 1"
                    size="sm"
                    variant="white"
                    class="mr-1"
                    :title="$t('delete')"
                    @click="DeleteAll(row.item, row.index)"
                  >
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="trash-alt" />
                  </b-button>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <b-modal
      id="modal-details"
      ref="modal"
      title-class="text-font-black"
      :title="$t('user_profile') + ' - @' + element.username"
      modal-class="model-content"
      hide-footer
      content-class="shadow"
      style="max-height: 400px; overflow-y: auto;"
      @show="ResetModal"
      @hidden="ResetModal"
    >
      <b-container>
        <div v-if="!post_selected">
          <b-row class="mb-2" style="border-radius: 20px; background-color: #000000">
            <b-col class="text-center mx-0 px-0 pt-4" md="4">
              <p class="py-0 mb-0" style="font-size: 26px; color: #FFFFFF"><strong>{{ format_num(element.followers) }}</strong></p>
              <p class="py-0 my-0" style="font-size: 12px; color: #FFFFFF">{{ $t('followers') }}</p>
            </b-col>
            <b-col class="text-center mt-2 mx-0 px-0" md="4">
              <img :src="element.imgkit_profile_picture + '?tr=n-small_thumb'" height="90px" style="border-radius: 50%; aspect-ratio: 1; object-fit: cover;" >
            </b-col>
            <b-col class="text-center mx-0 px-0 pt-4" md="4">
              <p class="py-0 mb-0" style="font-size: 26px; color: #FFFFFF"><strong>{{ format_num(element.following) }}</strong></p>
              <p class="py-0 my-0" style="font-size: 12px; color: #FFFFFF">{{ $t('following') }}</p>
            </b-col>
            <b-col class="text-center mx-0 px-0" md="12">
              <p class="py-0 mb-0" style="color: #FFFFFF"><strong>{{ element.first_name + ' ' + element.last_name }}</strong></p>
            </b-col>
            <b-col md="12" class="mx-0 px-3 mb-3">
              <p class="py-0 mt-0 mb-1" style="color: #FFFFFF"><strong>{{ element.username }}</strong></p>
              <p v-if="element.biography" class="py-0 my-0" style="font-size: 14px; color: #FFFFFF">{{ element.biography }}</p>
              <a v-if="element.link" :href="element.link" target="_blank" class="font-size: 14px; py-0 my-0" style="color: #6a71ec">{{ element.link.substring(7) }}</a>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col v-if="grid_view" md="4" class="text-center" style="cursor: pointer; background-color: #6a71ec; border-radius: 20px;"
                @click="grid_view = true">
              <font-awesome-icon class="mt-3" style="color: #FFFFFF; font-size: 20px" icon="th-large" />  
            </b-col>
            <b-col v-else md="4" class="text-center" style="cursor: pointer; background-color: #000000; border-radius: 20px;"
                @click="grid_view = true">
              <font-awesome-icon class="mt-3" style="color: #FFFFFF; font-size: 20px" icon="th-large" />  
            </b-col>
            <b-col md="4" class="text-center">
              <p class="py-0 mb-0" style="font-size: 26px; color: #000000"><strong>{{ format_num(total_posts) }}</strong></p>
              <p class="py-0 my-0" style="font-size: 12px; color: #000000">{{ $t('posts') }}</p>
            </b-col>
            <b-col v-if="!grid_view" md="4" class="text-center" style="cursor: pointer; background-color: #6a71ec; border-radius: 20px;"
              @click="grid_view = false">
              <font-awesome-icon class="mt-3" style="color: #FFFFFF; font-size: 20px" icon="th-list" />  
            </b-col>
            <b-col v-else md="4" class="text-center" style="cursor: pointer; background-color: #000000; border-radius: 20px;"
              @click="grid_view = false">
              <font-awesome-icon class="mt-3" style="color: #FFFFFF; font-size: 20px" icon="th-list" />  
            </b-col>
          </b-row>

          <b-col v-if="post_loading" lg="12" class="mt-2 mb-0 text-center">
            <b-spinner
              style="width: 2rem; height: 2rem; font-size: 10px; color: #6a71ec">
            </b-spinner>
          </b-col>

          <b-col v-if="!post_loading && total_posts == 0" lg="12" class="mt-2 mb-0 text-center"> 
            <p style="font-size: 14px; text-align: center; color: #000000">{{ $t('user_has_no_posts') }}...</p>
          </b-col>

          <b-col v-if="grid_view && !post_loading && total_posts > 0" lg="12" class="text-center">
            <b-row>
              <b-col md="4" v-for="data of posts" v-bind:key="data.id" class="mb-1">
                <img v-if="data.type == 1" :src="data.imgkit_url_picture + '?tr=n-grid_thumb'" width="100%" style="cursor: pointer; object-fit: cover; aspect-ratio: 1;" @click="PostSelected(data)">
                <div v-else-if="data.type == 2" @click="PostSelected(data)" style="cursor: pointer; position: relative;">  
                  <img :src="data.imgkit_url_picture + '?tr=n-grid_thumb'" width="100%" style="object-fit: cover; aspect-ratio: 1;">
                  <div style="position: absolute; top: 45%; left: 45%;">
                    <font-awesome-icon style="color: #FFFFFF; font-size: 20px" icon="play" />  
                  </div>
                </div>
                <div v-else-if="data.type == 4" @click="PostSelected(data)" style="cursor: pointer; position: relative;">  
                  <img v-if="data.imgkit_url_picture" :src="data.imgkit_url_picture + '?tr=n-grid_thumb'" width="100%" style="object-fit: cover; aspect-ratio: 1;">
                  <img v-else src="../assets/logo2.png" width="100%" style="cursor: pointer; object-fit: cover; aspect-ratio: 1;" @click="PostSelected(data)">
                </div>
                <img v-else src="../assets/logo2.png" width="100%" style="cursor: pointer; object-fit: cover; aspect-ratio: 1;" @click="PostSelected(data)">
              </b-col>
            </b-row>
          </b-col>

          <b-col v-if="!grid_view && !post_loading && total_posts > 0" lg="12" class="text-center">
            <b-row v-for="(data, index) of posts" v-bind:key="data.id">
              <b-col lg="12" class="mb-3 p-2" style="border-radius: 20px; background-color: #000000">
                <b-row>
                  <b-col lg="2" class="pt-2">
                    <img :src="element.imgkit_profile_picture + '?tr=n-small_thumb'" class="ml-2" height="50px" style="object-fit: cover; aspect-ratio: 1; border-radius: 50%;">
                  </b-col>
                  <b-col lg="8" class="pt-2 text-left">
                    <p class="py-0 my-0" style="color: #FFFFFF"><strong>{{ element.username }}</strong></p>
                    <p class="py-0 my-0" style="font-size: 14px; color: #FFFFFF">{{ moment.utc(data.created_at).local().fromNow() }}</p>
                  </b-col>
                  <b-col lg="2" class="pt-3 text-center">
                    <!--<font-awesome-icon v-if="!progress" style="cursor: pointer; color: #FFFFFF; font-size: 24px" icon="flag" @click="ChangeActivePost(data, index)" />
                    <b-spinner v-else style="width: 24px; height: 24px; font-size: 10px; color: #FFFFFF" />-->
                  </b-col>
                  <b-col lg="12" v-if="data.type == 1" class="text-center">
                    <b-spinner v-if="img_is_loading[index]" style="margin-top: 80px; margin-bottom: 70px; width: 24px; height: 24px; font-size: 10px; color: #6a71ec" />
                    <br v-if="img_is_loading[index]">
                    <img :src="data.imgkit_url_picture" v-bind:class="{ mini: img_is_loading[index] }" class="mt-2" width="90%" @load="ShowImg(index)"/>
                    <p class="py-0 mt-2 ml-2 text-left" style="color: #FFFFFF">{{ data.text }}</p>
                  </b-col>
                  <b-col lg="12" v-if="data.type == 2" class="text-center">
                    <a :href="data.url_video" target="_blank">
                      <div style="position: relative;">
                        <b-spinner v-if="img_is_loading[index]" style="margin-top: 80px; margin-bottom: 70px; width: 24px; height: 24px; font-size: 10px; color: #6a71ec" />
                        <br v-if="img_is_loading[index]">
                        <img :src="data.imgkit_url_picture" v-bind:class="{ mini: img_is_loading[index] }" class="mt-2" width="100%" style="object-fit: cover; aspect-ratio: 1;" @load="ShowImg(index)">
                        <div v-if="!img_is_loading[index]" style="position: absolute; top: 45%; left: 44%;">
                          <font-awesome-icon style="color: #FFFFFF; font-size: 60px" icon="play" />  
                        </div>
                      </div>
                    </a>
                    <p class="py-0 mt-2 ml-2 text-left" style="color: #FFFFFF">{{ data.text }}</p>
                  </b-col>
                  <b-col lg="12" v-if="data.type == 3" class="text-left">
                    <p class="py-0 mt-2 ml-2" style="color: #FFFFFF">{{ data.text }}</p>
                  </b-col>
                  <b-col lg="12" v-if="data.type == 4" class="text-center">
                    <b-spinner v-if="img_is_loading[index]" style="margin-top: 80px; margin-bottom: 70px; width: 24px; height: 24px; font-size: 10px; color: #6a71ec" />
                    <br v-if="img_is_loading[index]">
                    <img v-if="data.imgkit_url_picture" :src="data.imgkit_url_picture" v-bind:class="{ mini: img_is_loading[index] }" class="mt-2" width="90%" @load="ShowImg(index)"/>
                    <img v-else src="../assets/logo5.png" v-bind:class="{ mini: img_is_loading[index] }" class="mt-2" width="90%" @load="ShowImg(index)"/>
                    <p v-if="data.text" class="py-0 mt-2 ml-2 text-left" style="color: #FFFFFF">{{ data.text }}</p>

                    <b-row v-for="(datax, indexx) of data.questions" :key="datax.question + indexx" align-h="center" class="mx-auto px-auto pt-2 pb-2 mb-1 text-left" style="border-radius: 20px; background-color: #000000">
                      <b-col lg="12">
                        <b-row class="mx-auto px-auto mb-1">
                          <b-col lg="12" class="px-1 py-0 text-left">
                            <p class="py-0 mt-0 mb-1" style="color: #FFFFFF; font-size: 20px;">
                              {{ datax.question.question }}
                            </p>
                          </b-col>
                        </b-row>

                        <b-row v-for="(datay, indexy) of datax.answers" :key="indexy"
                          class="mx-auto mb-2"
                          style="border-radius: 10px; border-style: solid; border-width: 1px; border-color: #6a71ec">
                          <b-col lg="10" class="py-0 pl-0 pr-2 mb-1 text-center" align-self="center">
                            <p class="my-0 py-0" style="color: #FFFFFF">{{ datay.answer.answer }}</p>
                          </b-col>
                          <b-col lg="2" class="m-0 p-0 pr-2 text-right" align-self="center">
                            <p class="my-0 py-0" style="color: #FFFFFF">{{ datay.percentage }}%</p>
                          </b-col>

                          <b-col lg="12" class="m-0 p-0" >
                            <div
                              v-bind:style="{ borderRadius: (datay.percentage == 100 ? '0px 0px 8px 8px' : '0px 0px 0px 8px') , width: datay.percentage + '%' }"
                              style="height: 10px; border-radius: 0px 0px 8px 8px; background-color: #6a71ec;">
                            </div>
                          </b-col>
                        </b-row>
                      </b-col>

                      <b-col lg="12" style="color: #FFFFFF">
                        <p v-if="data.ended == 0" class="my-0 py-0 textl-left">{{ format_num(datax.total_answers_for_question) + ' ' + $t('votes') }} - {{ $t('in_progress') }}</p>
                        <p v-else class="my-0 py-0 textl-left">{{ format_num(datax.total_answers_for_question) + ' ' + $t('votes') }} - {{ $t('already_ended') }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col lg="12" class="text-left mb-2">
                    <span class="mr-3">
                      <font-awesome-icon class="mt-2 ml-2 mr-2" style="color: #FFFFFF; font-size: 20px" icon="heart" />
                      <span style="color: #FFFFFF; font-size: 20px">{{ format_num(data.total_likes) }}</span>
                    </span>
                    <span>
                      <font-awesome-icon class="mt-2 mr-2" style="color: #FFFFFF; font-size: 20px" icon="comment" />
                      <span style="color: #FFFFFF; font-size: 20px">{{ format_num(data.total_comments) }}</span>
                    </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>

          <b-col v-if="!post_loading && total_posts > 0 && !no_more_posts" lg="12" class="mt-2 mb-0">
            <b-button 
              block pill
              :disable="new_posts_loading"
              class="my-1"
              @click="NewPostPage"
              style="color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              <span v-if="!new_posts_loading">
                {{ $t('load_more_posts') }}
              </span>
              <b-spinner
                v-else
                style="width: 2rem; height: 2rem; font-size: 10px; color: #FFFFFF">
              </b-spinner>
            </b-button>
          </b-col>

          <b-col v-if="no_more_posts" lg="12" class="mt-2 mb-0 text-center">
            <p style="font-size: 14px; text-align: center; color: #000000">{{ $t('no_more_posts') }}</p>
          </b-col>
        </div>

        <div v-else>
          <b-row>
            <b-col lg="3" class="mb-1 py-2 text-left"
              style="cursor: pointer; border-radius: 20px; background-color: #000000"
              @click="post_selected = false; img_loading = true">
              <span class="mr-3">
                <font-awesome-icon style="color: #FFFFFF; font-size: 17px" icon="chevron-left" />
              </span>
              <span style="color: #FFFFFF">{{ $t('go_back') }}</span>
            </b-col>
            <b-col lg="8" class="text-left"></b-col>
            <b-col lg="12" class="mb-3 p-2" style="border-radius: 20px; background-color: #000000">
              <b-row>
                <b-col lg="2" class="pt-2">
                  <img :src="element.imgkit_profile_picture + '?tr=n-small_thumb'" class="ml-2" height="50px" style="object-fit: cover; aspect-ratio: 1; border-radius: 50%;">
                </b-col>
                <b-col lg="8" class="pt-2 text-left">
                  <p class="py-0 my-0" style="color: #FFFFFF"><strong>{{ element.username }}</strong></p>
                  <p class="py-0 my-0" style="font-size: 14px; color: #FFFFFF">{{ moment.utc(post.created_at).local().fromNow() }}</p>
                </b-col>
                <b-col lg="2" class="pt-3 text-center">
                  <!--<font-awesome-icon v-if="!progress" style="cursor: pointer; color: #FFFFFF; font-size: 24px" icon="flag" @click="ChangeActivePost(post, post_index)" />
                  <b-spinner v-else style="width: 24px; height: 24px; font-size: 10px; color: #FFFFFF" />-->
                </b-col>

                <b-col lg="12" v-if="post.type == 1" class="text-center">
                  <b-spinner v-if="img_loading" style="margin-top: 80px; margin-bottom: 70px; width: 24px; height: 24px; font-size: 10px; color: #6a71ec" />
                  <br v-if="img_loading">
                  <img :src="post.imgkit_url_picture" v-bind:class="{ mini: img_loading }" class="mt-2" width="90%" @load="img_loading = false" />
                  <p class="py-0 mt-2 ml-2 text-left" style="color: #FFFFFF">{{ post.text }}</p>-->
                </b-col>
                <b-col lg="12" v-if="post.type == 2" class="text-center">
                  <b-spinner v-if="img_loading" style="margin-top: 80px; margin-bottom: 70px; width: 24px; height: 24px; font-size: 10px; color: #6a71ec" />
                  <br v-if="img_loading">
                  <a :href="post.url_video" target="_blank">
                    <div style="position: relative;">  
                      <img :src="post.imgkit_url_picture" v-bind:class="{ mini: img_loading }" class="mt-2" width="100%" style="object-fit: cover; aspect-ratio: 1;" @load="img_loading = false">
                      <div v-if="!img_loading" style="position: absolute; top: 45%; left: 44%;">
                        <font-awesome-icon style="color: #FFFFFF; font-size: 60px" icon="play" />  
                      </div>
                    </div>
                  </a>
                  <p class="py-0 mt-2 ml-2 text-left" style="color: #FFFFFF">{{ post.text }}</p>
                </b-col>
                <b-col lg="12" v-if="post.type == 3" class="text-left">
                  <p class="py-0 mt-2 ml-2" style="color: #FFFFFF">{{ post.text }}</p>
                </b-col>
                <b-col lg="12" v-if="post.type == 4" class="text-center">
                  <b-spinner v-if="img_loading" style="margin-top: 80px; margin-bottom: 70px; width: 24px; height: 24px; font-size: 10px; color: #6a71ec" />
                  <br v-if="img_loading">
                  <img v-if="post.imgkit_url_picture" :src="post.imgkit_url_picture" v-bind:class="{ mini: img_loading }" class="mt-2" width="90%" @load="img_loading = false"/>
                  <img v-else src="../assets/logo5.png" v-bind:class="{ mini: img_loading }" class="mt-2" width="90%" @load="img_loading = false"/>
                  <p v-if="post.text" class="py-0 mt-2 ml-2 text-left" style="color: #FFFFFF">{{ post.text }}</p>

                  <b-row v-for="(datax, indexx) of post.questions" :key="datax.question + indexx" align-h="center" class="mx-auto px-auto pt-2 pb-2 mb-1 text-left" style="border-radius: 20px; background-color: #000000">
                    <b-col lg="12">
                      <b-row class="mx-auto px-auto mb-1">
                        <b-col lg="12" class="px-1 py-0 text-left">
                          <p class="py-0 mt-0 mb-1" style="color: #FFFFFF; font-size: 20px;">
                            {{ datax.question.question }}
                          </p>
                        </b-col>
                      </b-row>

                      <b-row v-for="(datay, indexy) of datax.answers" :key="indexy"
                        class="mx-auto mb-2"
                        style="border-radius: 10px; border-style: solid; border-width: 1px; border-color: #6a71ec">
                        <b-col lg="10" class="py-0 pl-0 pr-2 mb-1 text-center" align-self="center">
                          <p class="my-0 py-0" style="color: #FFFFFF">{{ datay.answer.answer }}</p>
                        </b-col>
                        <b-col lg="2" class="m-0 p-0 pr-2 text-right" align-self="center">
                          <p class="my-0 py-0" style="color: #FFFFFF">{{ datay.percentage }}%</p>
                        </b-col>

                        <b-col lg="12" class="m-0 p-0" >
                          <div
                            v-bind:style="{ borderRadius: (datay.percentage == 100 ? '0px 0px 8px 8px' : '0px 0px 0px 8px') , width: datay.percentage + '%' }"
                            style="height: 10px; border-radius: 0px 0px 8px 8px; background-color: #6a71ec;">
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>

                    <b-col lg="12" style="color: #FFFFFF">
                      <p v-if="post.ended == 0" class="my-0 py-0 textl-left">{{ format_num(datax.total_answers_for_question) + ' ' + $t('votes') }} - {{ $t('in_progress') }}</p>
                      <p v-else class="my-0 py-0 textl-left">{{ format_num(datax.total_answers_for_question) + ' ' + $t('votes') }} - {{ $t('already_ended') }}</p>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col lg="12" class="text-left mb-2">
                  <span class="mr-3">
                    <font-awesome-icon class="mt-2 ml-2 mr-2" style="color: #FFFFFF; font-size: 20px" icon="heart" />
                    <span style="color: #FFFFFF; font-size: 20px">{{ format_num(post.total_likes) }}</span>
                  </span>
                  <span>
                    <font-awesome-icon class="mt-2 mr-2" style="color: #FFFFFF; font-size: 20px" icon="comment" />
                    <span style="color: #FFFFFF; font-size: 20px">{{ format_num(post.total_comments) }}</span>
                  </span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-modal>

    <b-modal
      id="modal-reports"
      ref="modal"
      title-class="text-font-black"
      :title="$t('user_reports') + ' (' + format_num(element.reports_quantity) +')' + ' - @' + element.username"
      modal-class="model-content"
      hide-footer
      content-class="shadow"
      style="max-height: 400px; overflow-y: auto;"
      @show="ResetModal"
      @hidden="ResetModal"
    >
      <b-container>
        <b-col v-if="report_loading" lg="12" class="mt-2 mb-0 text-center">
          <b-spinner
            style="width: 2rem; height: 2rem; font-size: 10px; color: #6a71ec">
          </b-spinner>
        </b-col>

        <b-col lg="12" v-if="!report_loading && total_reports > 0" class="mx-0 px-1 my-0 py-1 mb-2 text-center" style="background-color: #6a71ec; border-radius: 20px; color: #FFFFFF">
          <p v-for="data of counters" :key="data.id" class="my-auto py-auto"><strong>{{ data[language+"_name"] }}: </strong>{{ data.value + '%' }}</p>
        </b-col>

        <b-col v-if="!report_loading && total_reports > 0" lg="12" class="text-center">
          <b-row v-for="(data, index) of reports" v-bind:key="data.id">
            <b-col lg="12" class="mb-3 p-2" style="border-radius: 20px; background-color: #000000">
              <b-row>
                <b-col lg="1" class="pt-2" align-self="center">
                  <img :src="data.user_reporting.imgkit_profile_picture + '?tr=n-small_thumb'" class="ml-2" height="25px" style="object-fit: cover; aspect-ratio: 1; border-radius: 50%;">
                </b-col>
                <b-col lg="8" class="pt-2 text-left" align-self="center">
                  <span class="py-0 my-0" style="color: #FFFFFF">
                    <strong>{{ data.user_reporting.username }}</strong>
                  </span>
                  <span class="py-0 my-0 ml-3" style="font-size: 14px; color: #FFFFFF">{{ moment.utc(data.created_at).local().fromNow() }}</span>
                </b-col>
                <b-col lg="3" class="pt-3 text-center">
                  <font-awesome-icon v-if="data.id != id_report" style="cursor: pointer; color: #ffa500; font-size: 24px" icon="check-square" @click="UpdateReport(data, 1, index)" />
                  <font-awesome-icon v-if="data.id != id_report" class="ml-1" style="cursor: pointer; color: #6a71ec; font-size: 24px" icon="minus-square" @click="UpdateReport(data, 2, index)" />
                  <b-spinner v-if="progress && data.id == id_report" style="width: 24px; height: 24px; font-size: 10px; color: #FFFFFF" />
                </b-col>
                <b-col lg="12" class="text-left">
                  <p class="py-0 mt-2 mb-2 ml-2" style="font-size: 20px; color: #FFFFFF">
                    <strong>{{ data.reporting_reason.user_report_category[language+"_name"] + ' > ' + data.reporting_reason[language+"_name"] }}</strong>
                  </p>
                  <p v-if="data.comment" class="py-0 mt-0 mb-2 ml-2" style="color: #FFFFFF">
                    <strong>{{ $t('comment') }}: </strong>
                    {{ data.comment }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

        <b-col v-if="!report_loading && total_reports > 0 && !no_more_reports" lg="12" class="mt-2 mb-0">
          <b-button 
            block pill
            :disable="new_reports_loading"
            class="my-1"
            @click="NewReportPage"
            style="color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
            <span v-if="!new_reports_loading">
              {{ $t('load_more_reports') }}
            </span>
            <b-spinner
              v-else
              style="width: 2rem; height: 2rem; font-size: 10px; color: #FFFFFF">
            </b-spinner>
          </b-button>
        </b-col>

        <b-col v-if="no_more_reports" lg="12" class="mt-2 mb-0 text-center">
          <p style="font-size: 14px; text-align: center; color: #000000">{{ $t('no_more_reports') }}</p>
        </b-col>
      </b-container>
    </b-modal>
  </div>
</template>

<script>

import $ from 'jquery'
import Swal from "sweetalert2"
import { mapActions } from "vuex"
import moment from "moment"
import Cookies from 'js-cookie'

export default {
  name: "Users",

  data() {
    return {
      img_loading: true,
      img_loaders: [],
      is_mobile: false,
      id_user: Cookies.get('id_user'),
      moment: moment,
      language: navigator.language.substring(0,2),
      element: {},
      id_report: 0,
      post: {},
      post_index: 0,
      posts: [],
      post_loading: false,
      post_selected: false,
      grid_view: true,
      new_posts_loading: false,
      no_more_posts: false,
      total_posts: 0,
      reports: [],
      counters: [],
      report_loading: false,
      report_selected: false,
      new_reports_loading: false,
      no_more_reports: false,
      total_reports: 0,
      user_selected: 0,
      total: 0,
      isBusy: true,
      isBusy2: true,
      progress: false,
      emptyHTML: `<div class="mt-2 text-center"><h6>${this.$i18n.t('no_elements')}</h6></div>`,
      emptyFilteredHTML: `<div class="mt-2 text-center"><h6>${this.$i18n.t('no_elements_query')}</h6></div>`,
      id: "",
      field: [
        {
          key: "username",
          label: this.$i18n.t('username'),
          sortable: false,
          thStyle: { width: "25%" }
        },
        {
          key: "reports_quantity",
          label: this.$i18n.t('reports'),
          sortable: false,
        },
        {
          key: "followers",
          label: this.$i18n.t('followers'),
          sortable: false,
        },
        {
          key: "following",
          label: this.$i18n.t('following'),
          sortable: false,
        },
        {
          key: "member_since",
          label: this.$i18n.t('member_since'),
          sortable: false,
          thStyle: { width: "20%" }
        },
        {
          key: "actions",
          label: this.$i18n.t('actions'),
          sortable: false,
          thStyle: { width: "20%" }
        },
      ],
      per_page: 20,
      current_page: 1,
      page_options: [10, 20, 50, 100],
      filter: null,
      users: [],
      usersTMP: [],
    };
  },
  computed: {
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    if ($(window).width() < 500)    this.is_mobile = true

    this.moment.locale(this.language)
    this.AllUsers()
  },
  methods: {
    ...mapActions(["GetByID", "GetAll", "ChangeStatus", "Update"]),
    img_is_loading(index) {
      return this.img_loaders[index]
    },
    format_num(num) {
      if (num >= 1000 && num < 1000000){
        return (num/1000).toFixed(2) + 'K'
      }
      else if(num >= 1000000){
        return (num/1000000).toFixed(2) + 'M'
      }
      else {
        return num
      }
    },
    ShowImg(index) {
      this.img_loaders[index] = false
    },
    ResetModal() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-details");
      });
    },
    ClearFilter() {
      this.filter = ""
      this.active_status_label = this.$i18n.t('all').toUpperCase()
      this.AllUsers()
    },
    SearchInput() {
      let filterTMP = this.filter
      this.active_status_label = this.$i18n.t('all').toUpperCase()

      if (filterTMP) {
        //Ask for Users by Query (first 10)
        this.isBusy = true

        let form = { 
          element: 'user/reports',
          query: {
            query: filterTMP,
            order_key: 'reports_quantity',
            order_value: 'desc',
            limit: 10,
          }
        }

        this.GetAll(form)
          .then((response) => {
            if (response.code == 200) {
              this.isBusy = false
              this.isBusy2 = false
              this.users = response.data
              this.usersTMP = response.data
              this.total = response.data.length
            }
            else {
              this.isBusy = false
              this.isBusy2 = false
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            }
          })
          .catch((error) => {
            console.log(error)
            this.isBusy = false
            this.isBusy2 = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          })
      }
      else {
        this.AllUsers()
      }
      
      return true
    },
    OnFiltered(filteredItems) {
      this.total = filteredItems.length
      this.current_page = 1
    },
    PageChanged() {
      this.AllUsers()
    },
    AllUsers() {
      this.isBusy = true

      let form = { 
        element: 'user/reports',
        query: {
          order_key: 'reports_quantity',
          order_value: 'desc',
          limit: this.per_page,
          page: this.current_page,
        }
      }

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.isBusy = false
            this.isBusy2 = false
            this.users = response.data.data
            this.usersTMP = response.data.data
            this.total = response.data.meta.total
          }
          else {
            this.isBusy = false
            this.isBusy2 = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.isBusy = false
          this.isBusy2 = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        })
    },
    ProfileTMP(data) {
      this.img_loading = true
      this.grid_view = true
      this.post_selected = false
      this.post_index = 0
      this.post = {}
      this.posts = []
      this.img_loaders = []
      this.total_posts = 0
      this.element = data

      if (this.element.link && this.element.link.indexOf('http') < 0) {
        this.element.link = 'http://'+this.element.link.toLowerCase()
      }

      this.posts_page = 1
      this.new_posts_loading = false
      this.no_more_posts = false

      let form = { 
        element: 'post',
        query: {
          active: 1,
          order_key: 'id',
          order_value: 'desc',
          limit: 9,
          page: 1,
        },
        columns: ['id_user'],
        params: {
          id_user: data.id
        }
      }

      this.post_loading = true

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.post_loading = false
            this.posts = response.data.data
            this.total_posts = response.data.meta.total

            for (let i = 0; i < this.posts.length; i++) {
              this.img_loaders.push(true)
            }

            if (response.data.data.length > 0 && response.data.data.length < 9) {
              this.no_more_posts = true
            }
          }
          else {
            this.post_loading = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.post_loading = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        })
    },
    PostSelected(data, index) {
      this.img_loading = true
      this.post_selected = true
      this.post = data
      this.post_index = index
    },
    NewPostPage() {
      this.posts_page = this.posts_page + 1

      let form = { 
        element: 'post',
        query: {
          active: 1,
          order_key: 'id',
          order_value: 'desc',
          limit: 9,
          page: this.posts_page,
        },
        columns: ['id_user'],
        params: {
          id_user: this.element.id
        }
      }

      this.new_posts_loading = true

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            if (response.data.data.length > 0) {
              this.new_posts_loading = false
              for (let item of response.data.data) {
                this.img_loaders.push(true)
                this.posts.push(item)
              }
            }
            else {
              this.new_posts_loading = false
              this.no_more_posts = true
            }

            if (response.data.data.length < 9) {
              this.new_posts_loading = false
              this.no_more_posts = true
            }
          }
          else {
            this.new_posts_loading = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.new_posts_loading = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        })
    },
    ReportTMP(data, index) {
      this.user_selected = index
      this.reports = []
      this.total_reports = 0
      this.element = data
      this.progress = false

      this.reports_page = 1
      this.new_reports_loading = false
      this.no_more_reports = false

      let form = { 
        element: 'user_reported',
        query: {
          active: 1,
          order_key: 'id',
          order_value: 'desc',
          limit: 10,
          page: 1,
        },
        columns: ['id_user_reported','accepted'],
        params: {
          id_user_reported: this.element.id,
          accepted: '0'
        }
      }

      this.report_loading = true

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.report_loading = false
            this.reports = response.data.data
            this.counters = response.data.counters
            this.total_reports = response.data.meta.total

            if (response.data.data.length < 10) {
              this.no_more_reports = true
            }
          }
          else {
            this.report_loading = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.report_loading = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        })
    },
    NewReportPage() {
      this.reports_page = this.reports_page + 1

      let form = { 
        element: 'user_reported',
        query: {
          active: 1,
          order_key: 'id',
          order_value: 'desc',
          limit: 10,
          page: this.reports_page,
        },
        columns: ['id_user_reported','accepted'],
        params: {
          id_user_reported: this.element.id,
          accepted: '0'
        }
      }

      this.new_reports_loading = true

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            if (response.data.data.length > 0) {
              this.new_reports_loading = false
              for (let item of response.data.data) {
                this.reports.push(item)
              }
            }
            else {
              if (this.reports.length == 0) {
                this.usersTMP.splice(this.user_selected, 1)
                this.total--

                this.$nextTick(() => {
                  this.$bvModal.hide("modal-reports");
                })
              }
              else {
                this.new_reports_loading = false
                this.no_more_reports = true
              }
            }

            if (response.data.data.length < 10) {
              this.new_reports_loading = false
              this.no_more_reports = true
            }
          }
          else {
            this.new_reports_loading = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.new_reports_loading = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        })
    },
    ChangeActive(data, index) {
      let active = data.active == 1 ? 0 : 1
      let title =
        data.active == 1
          ? "deactivate"
          : "activate"
      let title2 =
        data.active == 1
          ? "deactivated"
          : "activated"
      let text =
        data.active == 1
          ? "deactivate_question"
          : "activate_question"
      Swal.fire({
        title: this.$i18n.t(title) + ' ' + this.$i18n.t('user').toLowerCase(),
        text: this.$i18n.t(text) + ' ' + this.$i18n.t('user').toLowerCase() + '?',
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: active == 0 ? this.$i18n.t('deactivate') : this.$i18n.t('activate'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = {
            element: 'user',
            id: data.id,
            status: active
          }

          this.ChangeStatus(form)
            .then((response) => {
              if (response.code == 200) {
                this.usersTMP.splice(index,1)
                this.total--

                Swal.fire({
                  title: this.$i18n.t('user') + " " + this.$i18n.t(title2).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              else {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                })
              }
            })
            .catch((error) => {
              console.log(error)
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            })
        }
      })
    },
    UpdateReport(data, accepted, index) {
      let title =
        accepted == 1
          ? "accept"
          : "reject"
      let title2 =
        accepted == 1
          ? "accepted"
          : "rejected"
      let text =
        accepted == 1
          ? "accept_question"
          : "reject_question"
      Swal.fire({
        title: this.$i18n.t(title) + ' ' + this.$i18n.t('user_report').toLowerCase(),
        text: this.$i18n.t(text) + ' ' + this.$i18n.t('user_report').toLowerCase() + '? ' + (accepted == 1 ? this.$i18n.t('accept_effect') : ''),
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: accepted == 1 ? this.$i18n.t('accept') : this.$i18n.t('reject'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          this.id_report = data.id
          this.progress = true

          let form = {
            element: 'user_reported',
            id: data.id,
            update: 'update',
            data: {
              accepted: accepted
            }
          }

          this.Update(form)
            .then((response) => {
              if (response.code == 200) {
                this.progress = false
                this.id_report = 0

                Swal.fire({
                  title: this.$i18n.t('user_report') + " " + this.$i18n.t(title2).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });

                if (accepted == 2) {
                  this.reports.splice(index, 1)

                  if (this.reports.length == 0) {
                    this.NewReportPage()
                  }
                }
                else {
                  this.usersTMP.splice(this.user_selected, 1)
                  this.total--
                  this.reports = []

                  this.$nextTick(() => {
                    this.$bvModal.hide("modal-reports");
                  })
                }
              }
              else {
                this.progress = false
                this.id_report = 0
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                })
              }
            })
            .catch((error) => {
              this.progress = false
              this.id_report = 0
              console.log(error)
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            })
        }
      })
    },
    DeleteAll(data, index) {
      Swal.fire({
        title: this.$i18n.t('delete') + ' ' + this.$i18n.t('user_reportsx').toLowerCase(),
        text: this.$i18n.t('delete_all_question') + ' ' + this.$i18n.t('this_user_reportsx').toLowerCase() + '?',
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: this.$i18n.t('delete'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = {
            element: 'user_reported',
            id: data.id,
            update: 'delete_all',
          }

          this.Update(form)
            .then((response) => {
              if (response.code == 200) {
                this.usersTMP.splice(index,1)
                this.total--

                Swal.fire({
                  title: this.$i18n.t('user_report') + " " + this.$i18n.t('deleted').toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              else {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                })
              }
            })
            .catch((error) => {
              console.log(error)
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            })
        }
      })
    },
  },
}

</script>

<style>

.mini {
  height: 1px;
}

.table thead th {
  cursor: pointer;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #6a71ec !important;
  border-color: #6a71ec !important;
}

.header, .table thead th, thead, th {
  background-color: #6a71ec !important;
  color: #FFFFFF;
}

.dropdownx {
  border-radius: 24px!important;
  height: 100%!important;
  width: 100%!important;
  border: none!important;
  background-color: #6a71ec!important;
  color: #FFFFFF!important;
}

.my-class .dropdown-menu {
  max-height: 100px;
  overflow-y: auto;
}

.div-btn {
  width: 100%;
  color: #FFFFFF;
  font-size: 20px;
  border-color: #6a71ec;
  background-color: #6a71ec
}

.variable-width {
  width: 80%;
}

.variable-height-width {
    max-height: 350px;
    max-width: 400px;
  }

@media (max-width: 550px) {
  .variable-width {
    width: 100%;
  }

  .variable-height-width {
    max-height: 290px;
    max-width: 290px;
  }

  .variable-width2 {
    width: 100%;
  }
}

</style>

<template>
  <div id="MasterCRUD">
    <br />
    <h1 class="text2" style="color: #000000; margin-left: 10px; text-align: left;"><strong>{{ title_plural_uppercase
    }}</strong></h1>
    <div class="content">
      <b-card style="border: none; background-color: #F2F2F2">
        <b-row>
          <b-col md="3">
            <h4 class="my-1" style="color: #000000; text-align: left"><strong>TOTAL:</strong></h4>
          </b-col>
          <b-col md="2"></b-col>
          <b-col md="4">
            <h4 v-if="element_url == 'reporting_reason'" class="my-1" style="color: #000000; text-align: left">
              <strong>{{
              $t('type').toUpperCase() }}:</strong>
            </h4>

            <h4 v-if="element_url == 'forbidden_word'" class="my-1" style="color: #000000; text-align: left">
              <strong>{{
              $t('country').toUpperCase() }}:</strong>
            </h4>
          </b-col>
          <b-col md="3"></b-col>
        </b-row>
        <b-row>
          <b-col md="3" class="my-1">
            <div class="py-1"
              style="box-shadow: 0px 10px 8px #B0B0B0; border-radius: 24px; width=100%; color: #FFFFFF; border-color: #6a71ec; background-color: #6a71ec">
              <span v-show="!isBusy" style="font-size: 24px;">{{ total }}</span>
              <span v-show="isBusy" style="font-size: 24px;">--</span>
            </div>
          </b-col>
          <b-col md="2" class="my-1">
          </b-col>
          <b-col md="4" class="my-1">
            <b-dropdown v-if="element_url == 'forbidden_word'" offset="15" toggle-class="dropdownx"
              class="py-1 dropdownx my-class" :text="country_label"
              style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              <b-dropdown-item v-for="data of countriesx" :key="data.value" @click="CountrySelected(data)">{{ data.text
              }}</b-dropdown-item>
            </b-dropdown>

            <b-dropdown v-if="element_url == 'reporting_reason'" offset="15" toggle-class="dropdownx"
              class="py-1 dropdownx my-class" :text="type_report_label"
              style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              <b-dropdown-item v-for="data of type_reportsx" :key="data.value" @click="TypeSelected(data)">{{ data.text
              }}</b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col md="3" class="my-1">
            <b-button block pill v-b-modal.modal-add
              style="box-shadow: 0px 10px 8px #B0B0B0; color: #FFFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('add').toUpperCase() }}
              <font-awesome-icon style="text-align: right; color: white; font-size: 20px" icon="plus-circle" />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="4" class="my-1">
            <b-form-group label-for="filterInput" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('searcher') + ':' }}</p>
              <b-input-group size="sm" style="box-shadow: 0px 10px 8px #B0B0B0;">
                <b-input-group-prepend>
                  <span class="input-group-text"
                    style="border-color: #000000; background-color: #E0E0E0; border-right: none;">
                    <font-awesome-icon style="color: #000000; font-size: 17px" icon="search" />
                  </span>
                </b-input-group-prepend>
                <b-form-input id="filterInput" v-model="filter" v-on:keyup="SearchInput" type="text"
                  :placeholder="$t('search')+'...'"
                  style="border-color: #000000; background-color: #E0E0E0; border-right: none; border-left: none;" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="ClearFilter" class="input-group-text"
                    style="border-color: #000000; background-color: #E0E0E0; border-left: none;">
                    <font-awesome-icon style="color: #000000; font-size: 17px" icon="times-circle" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group label-for="perPageSelect" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('per_page') + ':' }}</p>
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                style="box-shadow: 0px 10px 8px #B0B0B0; border-color: #000000; background-color: #E0E0E0;" />
            </b-form-group>
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group label-for="allPages" class="mb-0">
              <p class="text-left my-0 py-0" style="color: #000000; font-size: 14px;">{{ $t('current_page') + ':' }}</p>
              <b-pagination id="allPages" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                aria-controls="my-table" size="sm" align="fill" style="box-shadow: 0px 10px 8px #B0B0B0;">
              </b-pagination>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3 px-3">
          <b-col sm="12">
            <div
              style="box-shadow: 0px 10px 8px #B0B0B0; border: 3px; border-color: #000000; background-color: #FFFFFF">
              <b-table id="my-table" show-empty small fixed stacked="md" sticky-header="true" hover thead-class="header"
                :primary-key="id" :items="elementsTMP"
                :fields="element_url != 'reporting_reason' ? (element_url != 'forbidden_word' ? field : fieldxx) : fieldx"
                :filter="filter" :current-page="currentPage" :per-page="perPage" :filterIncludedFields="filterOn"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :empty-html="emptyHTML" :empty-filtered-html="emptyFilteredHTML" @filtered="OnFiltered" :busy="isBusy">
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle" style="color: #6a71ec;"></b-spinner>
                    <strong> {{ $t('loading') }}...</strong>
                  </div>
                </template>

                <template v-slot:cell(type_report)="row">
                  {{ row.item.type_report == 1 ? $t('user_report') : $t('post_report') }}
                </template>

                <template v-slot:cell(country)="row">
                  {{ row.item.country ? row.item.country[language+'_name'] : $t('all') }}
                </template>

                <template v-slot:cell(es_name)="row">
                  {{ row.item.type_report == 1 ? row.item.user_report_category.es_name + ' > ' + row.item.es_name :
                  row.item.es_name }}
                </template>

                <template v-slot:cell(en_name)="row">
                  {{ row.item.type_report == 1 ? row.item.user_report_category.en_name + ' > ' + row.item.en_name :
                  row.item.en_name }}
                </template>

                <template v-slot:cell(actions)="row">
                  <b-button v-if="row.item.active == 1" v-b-modal.modal-update size="sm" variant="white" class="mr-2"
                    :title="$t('edit')" @click="UpdateNameTMP(row.item, row.index)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="edit" />
                  </b-button>
                  <b-button v-if="row.item.active == 0" v-b-modal.modal-update size="sm" variant="white" class="mr-2"
                    :title="$t('edit')" @click="UpdateNameTMP(row.item, row.index)">
                    <font-awesome-icon style="color: #000000; font-size: 17px" icon="edit" />
                  </b-button>

                  <b-button v-if="row.item.active == 1" size="sm" variant="white" :title="$t('deactivate')"
                    @click="ChangeActive(row.item)" class="mr-2">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="check-square" />
                  </b-button>
                  <b-button v-if="row.item.active == 0" size="sm" variant="white" :title="$t('activate')"
                    @click="ChangeActive(row.item)" class="mr-2">
                    <font-awesome-icon style="color: #000000; font-size: 17px" icon="minus-square" />
                  </b-button>

                  <b-button v-if="row.item.active == 0" size="sm" variant="white" class="mr-2" :title="$t('delete')"
                    @click="Delete(row)">
                    <font-awesome-icon style="color: #000000; font-size: 17px" icon="trash-alt" />
                  </b-button>
                  <b-button v-if="row.item.active == 1" size="sm" variant="white" class="mr-2" :title="$t('delete')"
                    @click="Delete(row)">
                    <font-awesome-icon style="color: #6a71ec; font-size: 17px" icon="trash-alt" />
                  </b-button>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <b-modal id="modal-add" ref="modal" title-class="text-font-black" :title="$t('add') + ' ' + title_lowcase"
      modal-class="model-content" hide-footer content-class="shadow" @show="resetModal" @hidden="resetModal" size="lg">
      <b-container>
        <b-row align-v="center">
          <b-col v-if="element_url != 'reporting_reason' && element_url != 'forbidden_word'" lg="6">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('spanish') }}</h3>
            <b-form-input type="text" v-model="es_name_add"
              :placeholder="$t('name') + ' (' + $t('es').toUpperCase() + ')'"
              style="border-radius: 20px; color: #000000;" required
              @keyup.enter="SaveElement(es_name_add, en_name_add)">
            </b-form-input>
          </b-col>
          <b-col v-if="element_url != 'reporting_reason' && element_url != 'forbidden_word'" lg="6">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('english') }}</h3>
            <b-form-input type="text" v-model="en_name_add"
              :placeholder="$t('name') + ' (' + $t('en').toUpperCase() + ')'"
              style="border-radius: 20px; color: #000000;" required
              @keyup.enter="SaveElement(es_name_add, en_name_add)">
            </b-form-input>
          </b-col>

          <b-col v-if="element_url == 'forbidden_word'" class="mb-2" lg="4">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('country') }}</h3>
            <v-select class="selector-class" v-model="country_add" :options="countries" label="text"
              style="max-height: 100px; border-radius: 20px; color: #000000;">
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  {{ $t('no_results_for') }}: <em>{{ search }}</em>.
                </template>
              </template>
            </v-select>
          </b-col>
          <b-col v-if="element_url == 'forbidden_word'" lg="4">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('spanish') }}</h3>
            <b-form-input type="text" v-model="es_name_add"
              :placeholder="$t('name') + ' (' + $t('es').toUpperCase() + ')'"
              style="border-radius: 20px; color: #000000;" required
              @keyup.enter="SaveElement(es_name_add, en_name_add)">
            </b-form-input>
          </b-col>
          <b-col v-if="element_url == 'forbidden_word'" lg="4">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('english') }}</h3>
            <b-form-input type="text" v-model="en_name_add"
              :placeholder="$t('name') + ' (' + $t('en').toUpperCase() + ')'"
              style="border-radius: 20px; color: #000000;" required
              @keyup.enter="SaveElement(es_name_add, en_name_add)">
            </b-form-input>
          </b-col>

          <b-col v-if="element_url == 'reporting_reason'" class="mb-2" lg="6">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('type_report') }}</h3>
            <b-form-select v-model="type_report_add" :options="type_reports"
              style="border-radius: 20px; color: #000000;" required />
          </b-col>
          <b-col v-if="element_url == 'reporting_reason' && type_report_add == 1" class="mb-2" lg="6">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('category') }}</h3>
            <b-form-select v-model="id_user_report_category_add" :options="categories"
              style="border-radius: 20px; color: #000000;" required />
          </b-col>
          <b-col v-if="element_url == 'reporting_reason' && (type_report_add == 0 || type_report_add == 2)" class="mb-2"
            lg="6">
          </b-col>
          <b-col v-if="element_url == 'reporting_reason'" lg="6">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('spanish') }}</h3>
            <b-form-input type="text" v-model="es_name_add"
              :placeholder="$t('name') + ' (' + $t('es').toUpperCase() + ')'"
              style="border-radius: 20px; color: #000000;" required
              @keyup.enter="SaveElement(es_name_add, en_name_add)">
            </b-form-input>
          </b-col>
          <b-col v-if="element_url == 'reporting_reason'" lg="6">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('english') }}</h3>
            <b-form-input type="text" v-model="en_name_add"
              :placeholder="$t('name') + ' (' + $t('en').toUpperCase() + ')'"
              style="border-radius: 20px; color: #000000;" required
              @keyup.enter="SaveElement(es_name_add, en_name_add)">
            </b-form-input>
          </b-col>
          <br>
          <b-col lg="12">
            <b-button block pill v-show="!progress" class="my-3"
              :disabled="es_name_add == '' || en_name_add == '' || (element_url == 'reporting_reason' && type_report_add == 0)"
              @click="SaveElement(es_name_add, en_name_add)"
              style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('add').toUpperCase() }}
            </b-button>
          </b-col>
          <b-col>
            <div v-show="progress" class="text-center my-3" style="color: #6a71ec">
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal id="modal-update" ref="modal" title-class="text-font-black" :title="$t('update') + ' ' + title_lowcase"
      modal-class="model-content" hide-footer content-class="shadow" @show="resetModal" @hidden="resetModal" size="lg">
      <b-container>
        <b-row align-v="center">
          <b-col v-if="element_url != 'reporting_reason' && element_url != 'forbidden_word'" lg="6">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('spanish') }}</h3>
            <b-form-input type="text" v-model="es_name_update"
              :placeholder="$t('name') + ' (' + $t('es').toUpperCase() + ')'"
              style="border-radius: 20px; color: #000000;" required
              @keyup.enter="UpdateElement(id_update, es_name_update, en_name_update)">
            </b-form-input>
          </b-col>
          <b-col v-if="element_url != 'reporting_reason' && element_url != 'forbidden_word'" lg="6">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('english') }}</h3>
            <b-form-input type="text" v-model="en_name_update"
              :placeholder="$t('name') + ' (' + $t('en').toUpperCase() + ')'"
              style="border-radius: 20px; color: #000000;" required
              @keyup.enter="UpdateElement(id_update, es_name_update, en_name_update)">
            </b-form-input>
          </b-col>

          <b-col v-if="element_url == 'forbidden_word'" class="mb-2" lg="4">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('country') }}</h3>
            <v-select class="selector-class" v-model="country_update" :options="countries" label="text"
              style="max-height: 100px; border-radius: 20px; color: #000000;">
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  {{ $t('no_results_for') }}: <em>{{ search }}</em>.
                </template>
              </template>
            </v-select>
          </b-col>
          <b-col v-if="element_url == 'forbidden_word'" lg="4">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('spanish') }}</h3>
            <b-form-input type="text" v-model="es_name_update"
              :placeholder="$t('name') + ' (' + $t('es').toUpperCase() + ')'"
              style="border-radius: 20px; color: #000000;" required
              @keyup.enter="UpdateElement(id_update, es_name_update, en_name_update)">
            </b-form-input>
          </b-col>
          <b-col v-if="element_url == 'forbidden_word'" lg="4">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('english') }}</h3>
            <b-form-input type="text" v-model="en_name_update"
              :placeholder="$t('name') + ' (' + $t('en').toUpperCase() + ')'"
              style="border-radius: 20px; color: #000000;" required
              @keyup.enter="UpdateElement(id_update, es_name_update, en_name_update)">
            </b-form-input>
          </b-col>

          <b-col v-if="element_url == 'reporting_reason'" class="mb-2" lg="6">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('type_report') }}</h3>
            <b-form-select v-model="type_report_update" :options="type_reports"
              style="border-radius: 20px; color: #000000;" required />
          </b-col>
          <b-col v-if="element_url == 'reporting_reason' && type_report_update == 1" class="mb-2" lg="6">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('category') }}</h3>
            <b-form-select v-model="id_user_report_category_update" :options="categories"
              style="border-radius: 20px; color: #000000;" required />
          </b-col>
          <b-col v-if="element_url == 'reporting_reason' && (type_report_update == 0 || type_report_update == 2)"
            class="mb-2" lg="6">
          </b-col>
          <b-col v-if="element_url == 'reporting_reason'" lg="6">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('spanish') }}</h3>
            <b-form-input type="text" v-model="es_name_update"
              :placeholder="$t('name') + ' (' + $t('es').toUpperCase() + ')'"
              style="border-radius: 20px; color: #000000;" required
              @keyup.enter="UpdateElement(id_update, es_name_update, en_name_update)">
            </b-form-input>
          </b-col>
          <b-col v-if="element_url == 'reporting_reason'" lg="6">
            <h3 class="text-center" style="color: #6a71ec">{{ $t('english') }}</h3>
            <b-form-input type="text" v-model="en_name_update"
              :placeholder="$t('name') + ' (' + $t('en').toUpperCase() + ')'"
              style="border-radius: 20px; color: #000000;" required
              @keyup.enter="UpdateElement(id_update, es_name_update, en_name_update)">
            </b-form-input>
          </b-col>
          <br>
          <b-col lg="12">
            <b-button block pill v-show="!progress" class="my-3"
              :disabled="es_name_update == element.es_name && en_name_update == element.en_name && type_report_update == element.type_report && id_user_report_category_update == element.id_user_report_category && country_update.value == element.id_country"
              @click="UpdateElement(id_update, es_name_update, en_name_update)"
              style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('update').toUpperCase() }}
            </b-button>
          </b-col>
          <b-col>
            <div v-show="progress" class="text-center my-3" style="color: #6a71ec">
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>

function clearAccents(cadena) {
  let chars = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ñ: "n",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    È: "E",
    Ì: "I",
    Ò: "O",
    Ù: "U",
    Ñ: "N",
  }
  let expr = /[áàéèíìóòúùñ]/gi
  let res = cadena.replace(expr, function (e) {
    return chars[e];
  });
  return res;
}

import { mapActions } from "vuex"
import Swal from "sweetalert2"

export default {
  name: "MasterElements",

  data() {
    return {
      language: navigator.language.substring(0, 2),
      element: {},
      element_url: '',
      title_gender_letter: '',
      title_gender_letter2: '',
      es_name_add: "",
      en_name_add: "",
      id_user_report_category_add: 0,
      type_report_add: 0,
      country_add: 0,
      id_update: "",
      es_name_update: "",
      en_name_update: "",
      id_user_report_category_update: 0,
      type_report_update: 0,
      type_report_label: this.$i18n.t('all').toUpperCase(),
      type_reportx: 0,
      type_reportsx: [{ value: 0, text: this.$i18n.t('all').toUpperCase()}, { value: 1, text: this.$i18n.t('user_report').toUpperCase()},{ value: 2, text: this.$i18n.t('post_report').toUpperCase()}],
      type_reports: [{ value: 0, text: this.$i18n.t('select_one')}, { value: 1, text: this.$i18n.t('user_report')},{ value: 2, text: this.$i18n.t('post_report')}],
      country_update: 0,
      country_label: this.$i18n.t('all').toUpperCase(),
      countryx: 0,
      countriesx: [{ value: 0, text: this.$i18n.t('all').toUpperCase() }],
      countries: [{ value: 0, text: this.$i18n.t('all') }],
      categories: [{ value: 0, text: this.$i18n.t('select_one2')}],
      categoriesx: [],
      progress: false,
      total: 0,
      isBusy: false,
      emptyHTML: `<div class="mt-2 text-center"><h6>${this.$i18n.t('no_elements')}</h6></div>`,
      emptyFilteredHTML: `<div class="mt-2 text-center"><h6>${this.$i18n.t('no_elements_query')}</h6></div>`,
      id: "",
      field: [
        {
          key: "es_name",
          label: this.$i18n.t('name') + ' (' + this.$i18n.t('es').toUpperCase() + ')',
          sortable: true,
        },
        {
          key: "en_name",
          label: this.$i18n.t('name') + ' (' + this.$i18n.t('en').toUpperCase() + ')',
          sortable: true,
        },
        {
          key: "actions",
          label: this.$i18n.t('actions'),
          sortable: false,
        },
      ],
      fieldx: [
        {
          key: "type_report",
          label: this.$i18n.t('type_report'),
          sortable: false,
        },
        {
          key: "es_name",
          label: this.$i18n.t('name') + ' (' + this.$i18n.t('es').toUpperCase() + ')',
          sortable: true,
        },
        {
          key: "en_name",
          label: this.$i18n.t('name') + ' (' + this.$i18n.t('en').toUpperCase() + ')',
          sortable: true,
        },
        {
          key: "actions",
          label: this.$i18n.t('actions'),
          sortable: false,
        },
      ],
      fieldxx: [
        {
          key: "country",
          label: this.$i18n.t('country'),
          sortable: false,
        },
        {
          key: "es_name",
          label: this.$i18n.t('name') + ' (' + this.$i18n.t('es').toUpperCase() + ')',
          sortable: true,
        },
        {
          key: "en_name",
          label: this.$i18n.t('name') + ' (' + this.$i18n.t('en').toUpperCase() + ')',
          sortable: true,
        },
        {
          key: "actions",
          label: this.$i18n.t('actions'),
          sortable: false,
        },
      ],
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 20, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      elements: [],
      elementsTMP: [],
    };
  },
  computed: {
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.elementsTMP.length;
    },
    title_plural_uppercase() {
      return this.$i18n.t(this.element_url+'_plural').toUpperCase()
    },
    title_uppercase() {
      return this.$i18n.t(this.element_url).toUpperCase()
    },
    title_plural_midcase() {
      return this.$i18n.t(this.element_url+'_plural')
    },
    title_midcase() {
      return this.$i18n.t(this.element_url)
    },
    title_plural_lowcase() {
      return this.$i18n.t(this.element_url+'_plural').toLowerCase()
    },
    title_lowcase() {
      return this.$i18n.t(this.element_url).toLowerCase()
    }
  },
  watch: {
    $route(to, from) {
      if (to != from) {
        this.total = 0
        this.progress = false
        this.isBusy = false
        this.perPage = 20
        this.currentPage = 1
        this.totalRows = 1
        this.sortBy = ""
        this.sortDesc = false
        this.sortDirection = "asc"
        this.filter = null

        if (this.$route.params.element == 'titles') {
          this.element_url = 'title'
          this.title_gender_letter = ''
          this.title_gender_letter2 = ''
        }
        else if (this.$route.params.element == 'station_categories') {
          this.element_url = 'station_category'
          this.title_gender_letter = '2'
          this.title_gender_letter2 = '2'
        }
        else if (this.$route.params.element == 'reporting_reasons') {
          this.element_url = 'reporting_reason'
          this.title_gender_letter = ''
          this.title_gender_letter2 = ''
          this.AllCategories()
        }
        else if (this.$route.params.element == 'room_reporting_reasons') {
          this.element_url = 'user_room_report'
          this.title_gender_letter = ''
          this.title_gender_letter2 = ''
        }
        else if (this.$route.params.element == 'user_report_categories') {
          this.element_url = 'user_report_category'
          this.title_gender_letter = '2'
          this.title_gender_letter2 = '2'
        }
        else if (this.$route.params.element == 'forbidden_words') {
          this.element_url = 'forbidden_word'
          this.title_gender_letter = '2'
          this.title_gender_letter2 = '2'
          this.AllCountries()
        }
        else if (this.$route.params.element == 'public_room_rules') {
          this.element_url = 'public_room_rule'
          this.title_gender_letter = '2'
          this.title_gender_letter2 = '2'
        }
        else if (this.$route.params.element == 'verification_reasons') {
          this.element_url = 'verification_reason'
          this.title_gender_letter = ''
          this.title_gender_letter2 = ''
        }
        else if (this.$route.params.element == 'type_photos') {
          this.element_url = 'type_photo'
          this.title_gender_letter = ''
          this.title_gender_letter2 = ''
        }
        else if (this.$route.params.element == 'ad_banner_categories') {
          this.element_url = 'bannera_category'
          this.title_gender_letter = '2'
          this.title_gender_letter2 = '2'
        }

        this.AllElements()
      }
    }
  },
  mounted() {
    if (this.$route.params.element == 'titles') {
      this.element_url = 'title'
      this.title_gender_letter = ''
      this.title_gender_letter2 = ''
    }
    else if (this.$route.params.element == 'station_categories') {
      this.element_url = 'station_category'
      this.title_gender_letter = '2'
      this.title_gender_letter2 = '2'
    }
    else if (this.$route.params.element == 'reporting_reasons') {
      this.element_url = 'reporting_reason'
      this.title_gender_letter = ''
      this.title_gender_letter2 = ''
      this.AllCategories()
    }
    else if (this.$route.params.element == 'room_reporting_reasons') {
      this.element_url = 'user_room_report'
      this.title_gender_letter = ''
      this.title_gender_letter2 = ''
    }
    else if (this.$route.params.element == 'user_report_categories') {
      this.element_url = 'user_report_category'
      this.title_gender_letter = '2'
      this.title_gender_letter2 = '2'
    }
    else if (this.$route.params.element == 'forbidden_words') {
      this.element_url = 'forbidden_word'
      this.title_gender_letter = '2'
      this.title_gender_letter2 = '2'
      this.AllCountries()
    }
    else if (this.$route.params.element == 'public_room_rules') {
      this.element_url = 'public_room_rule'
      this.title_gender_letter = '2'
      this.title_gender_letter2 = '2'
    }
    else if (this.$route.params.element == 'verification_reasons') {
      this.element_url = 'verification_reason'
      this.title_gender_letter = ''
      this.title_gender_letter2 = ''
    }
    else if (this.$route.params.element == 'type_photos') {
      this.element_url = 'type_photo'
      this.title_gender_letter = ''
      this.title_gender_letter2 = ''
    }
    else if (this.$route.params.element == 'ad_banner_categories') {
      this.element_url = 'bannera_category'
      this.title_gender_letter = '2'
      this.title_gender_letter2 = '2'
    }

    this.AllElements();
  },
  methods: {
    ...mapActions(["GetAll", "ChangeStatus", "Save", "Update"]),
    resetModal() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-add");
      });
      this.type_report_add = 0
      this.country_add = { value: 0, text: this.$i18n.t('all') }
      this.es_name_add = ""
      this.en_name_add = ""
    },
    ClearFilter() {
      this.filter = ""
      return true
    },
    SearchInput() {
      let filterTMP = this.filter
      let elementsTmp = []

      if (filterTMP) {
        this.elements.filter(function (el, i) {
          let cadena = JSON.stringify(el.user)
          let cadena_test = clearAccents(cadena).toLowerCase().indexOf(filterTMP.toLowerCase(), i)
          
          if (cadena_test > -1) {
            elementsTmp.push(el);
          }
        });
        this.elementsTMP = elementsTmp;
      }
      else {
        this.elementsTMP = this.elements;
      }
      
      return true;
    },
    OnFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    TypeSelected(data) {
      this.type_report_label = data.text
      this.type_reportx = data.value
      this.AllElements()
    },
    CountrySelected(data) {
      this.country_label = data.text
      this.countryx = data.value
      this.AllElements()
    },
    AllCategories() {
      let form = {
        element: 'master/user_report_category',
        query: {
          active: 1,
          order_key: navigator.language.substring(0,2) + '_name',
          order_value: 'asc'
        }
      }

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.categoriesx = response.data
            this.categories = [{ value: 0, text: this.$i18n.t('select_one2')}]

            for (let item of response.data) {
              this.categories.push({ value: item.id, text: item[navigator.language.substring(0, 2)+"_name"] })
            }
          }
          else {
            console.log(response);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          }
        })
        .catch((error) => {
          console.log(error)
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        });
    },
    AllCountries() {
      let form = {
        element: 'master/country',
        query: {
          active: 1,
          order_key: navigator.language.substring(0, 2) + '_name',
          order_value: 'asc'
        }
      }

      this.GetAll(form)
        .then((response) => {
          if (response.code == 200) {
            this.countriesx = [{ value: 0, text: this.$i18n.t('all') }]
            this.countries = [{ value: 0, text: this.$i18n.t('all') }]

            for (let item of response.data) {
              this.countriesx.push({ value: item.id, text: item[navigator.language.substring(0, 2) + "_name"] })
              this.countries.push({ value: item.id, text: item[navigator.language.substring(0, 2) + "_name"] })
            }
          }
          else {
            console.log(response);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          }
        })
        .catch((error) => {
          console.log(error)
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        });
    },
    AllElements() {
      this.isBusy = true;
      let form = {
        element: 'master/' + this.element_url,
        query: {
          order_key: 'id',
          order_value: 'desc'
        },
        columns: [],
        params: {}
      }

      if (this.type_reportx > 0) {
        form.columns.push('type_report')
        form.params["type_report"] = this.type_reportx
      }

      if (this.countryx > 0) {
        form.columns.push('id_country')
        form.params["id_country"] = this.countryx
      }

      this.GetAll(form)
        .then((response) => {

          if (response.code == 200) {
            this.isBusy = false;
            this.elements = response.data;
            this.elementsTMP = response.data;
            this.total = this.elements.length;
          }
          else {
            console.log(response);
            this.isBusy = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            })
          }
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        });
    },
    SaveElement(es_name, en_name) {
      if (!es_name || es_name.length == 0) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('please_insert_name') + ' ' + this.$i18n.t('spanish').toLowerCase(),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return
      }

      if (!en_name || en_name.length == 0) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('please_insert_name') + ' ' + this.$i18n.t('english').toLowerCase(),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return
      }
      
      if (this.element_url == 'reporting_reason' && this.type_report_add == 0) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('please_select_type'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return
      }

      if (this.element_url == 'reporting_reason' && this.type_report_add == 1 && this.id_user_report_category_add == 0) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('please_select_category'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return
      }
      
      this.progress = true
      let form

      if (this.element_url == 'reporting_reason')       form = { element: 'master/' + this.element_url, data: { id_user_report_category: this.id_user_report_category_add, type_report: this.type_report_add, es_name: es_name, en_name: en_name } }
      else if (this.element_url == 'forbidden_word')    form = { element: 'master/' + this.element_url, data: { id_country: this.country_add.value, es_name: es_name, en_name: en_name } }
      else                                              form = { element: 'master/' + this.element_url, data: { es_name: es_name, en_name: en_name } }

      this.Save(form)
        .then((response) => {

          if (response.code == 200) {
            this.$nextTick(() => {
              this.$bvModal.hide("modal-add")
            })

            this.progress = false
            //this.AllElements()
            response.data.active = 1
            response.data.type_report = this.type_report_add
            response.data.id_user_report_category = this.id_user_report_category_add
            
            response.data.id_country = this.country_add.value

            if (response.data.country) {
              response.data.country = {
                id: this.country_add.value,
                es_name: this.country_add.text,
                en_name: this.country_add.text
              }
            }
            else {
              response.data = {
                ...response.data,
                country: {
                  id: this.country_add.value,
                  es_name: this.country_add.text,
                  en_name: this.country_add.text
                }
              }
            }

            let user_category = {}

            for (let item of this.categoriesx) {
              if (item.id == this.id_user_report_category_add) {
                user_category = item
              }
            }

            response.data.user_report_category = user_category

            let elementsTMP2 = [response.data]

            for (let i = this.elementsTMP.length - 1; i >= 0; i--) {
              elementsTMP2.push(this.elementsTMP[i])
            }

            this.elementsTMP = elementsTMP2
            this.total++

            Swal.fire({
              title: this.title_midcase + ' ' + this.$i18n.t('added'+this.title_gender_letter).toLowerCase(),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          else {
            this.progress = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title: this.$i18n.t('register_exists'),
            })
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        });
    },
    UpdateNameTMP(data, index) {
      this.index = index
      this.element = data
      this.id_update = data.id
      this.es_name_update = data.es_name
      this.en_name_update = data.en_name

      if (this.element_url == 'reporting_reason') {
        this.type_report_update = data.type_report
        this.id_user_report_category_update = data.id_user_report_category
        
        this.element.id_country = 0
        this.country_update = 0
      }
      else if (this.element_url == 'forbidden_word') {
        this.country_update = { value: String(data.id_country), text: this.countriesx.find(el => el.value == data.id_country).text }
        
        this.element.type_report = 0
        this.type_report_update = 0
        this.element.id_user_report_category = 0
        this.id_user_report_category_update = 0
      }
      else {
        this.element.id_country = 0
        this.country_update = 0
        this.element.type_report = 0
        this.type_report_update = 0
        this.element.id_user_report_category = 0
        this.id_user_report_category_update = 0
      }
    },
    UpdateElement(id, es_name, en_name) {
      if (es_name == this.element.es_name && en_name == this.element.en_name) {
        if (this.element_url == 'reporting_reason' && this.type_report_update == this.element.type_report && this.id_user_report_category_update == this.element.id_user_report_category) {
          return
        }
        if (this.element_url == 'forbidden_word' && this.country_update == this.element.id_country) {
          return
        }
      }

      if (!es_name || es_name.length == 0) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('please_insert_name') + ' ' + this.$i18n.t('spanish').toLowerCase(),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return
      }

      if (!en_name || en_name.length == 0) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('please_insert_name') + ' ' + this.$i18n.t('english').toLowerCase(),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return
      }

      if (this.element_url == 'reporting_reason' && this.type_report_update == 0) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('please_select_type'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return
      }

      if (this.element_url == 'reporting_reason' && this.type_report_update == 1 && this.id_user_report_category_update == 0) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('please_select_category'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return
      }
      
      this.progress = true
      let form

      if (this.element_url == 'reporting_reason')     form = { element: 'master/' + this.element_url, id: id, update: 'update', data: { id_user_report_category: this.id_user_report_category_update, type_report: this.type_report_update, es_name: es_name, en_name: en_name } }
      else if (this.element_url == 'forbidden_word')  form = { element: 'master/' + this.element_url, id: id, update: 'update', data: { id_country: this.country_update.value, es_name: es_name, en_name: en_name } }
      else                                            form = { element: 'master/' + this.element_url, id: id, update: 'update', data: { es_name: es_name, en_name: en_name } }

      this.Update(form)
        .then((response) => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-update");
          });

          if (response.code == 200) {
            this.progress = false
            //this.AllElements()

            if (this.element_url == 'reporting_reason') {
              this.elementsTMP[this.index].type_report = this.type_report_update
              this.elementsTMP[this.index].id_user_report_category = this.id_user_report_category_update
              this.elementsTMP[this.index].es_name = es_name
              this.elementsTMP[this.index].en_name = en_name

              let user_category = {}

              for (let item of this.categoriesx) {
                if (item.id == this.id_user_report_category_update) {
                  user_category = item
                }
              }

              this.elementsTMP[this.index].user_report_category = user_category
            }
            else if (this.element_url == 'forbidden_word') {
              this.elementsTMP[this.index].es_name = es_name
              this.elementsTMP[this.index].en_name = en_name

              this.elementsTMP[this.index].id_country = this.country_update.value

              if (this.elementsTMP[this.index].country) {
                this.elementsTMP[this.index].country = {
                  id: this.country_update.value,
                  es_name: this.country_update.text,
                  en_name: this.country_update.text
                }
              }
              else {
                this.elementsTMP[this.index] = {
                  ...this.elementsTMP[this.index],
                  country: {
                    id: this.country_update.value,
                    es_name: this.country_update.text,
                    en_name: this.country_update.text
                  }
                }
              }
            }
            else {
              this.elementsTMP[this.index].es_name = es_name
              this.elementsTMP[this.index].en_name = en_name
            }
            
            Swal.fire({
              title: this.title_midcase + ' ' + this.$i18n.t('updated'+this.title_gender_letter).toLowerCase(),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          else {
            this.progress = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "warning",
              title: this.$i18n.t('register_exists'),
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.progress = false
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          })
        });
    },
    ChangeActive(data) {
      let active = data.active == 1 ? 0 : 1;
      let title =
        data.active == 1
          ? "deactivate"
          : "activate"
      let title2 =
        data.active == 1
          ? "deactivated" + this.title_gender_letter
          : "activated" + this.title_gender_letter
      let text =
        data.active == 1
          ? "deactivate_question" + this.title_gender_letter2
          : "activate_question" + this.title_gender_letter2
      Swal.fire({
        title: this.$i18n.t(title) + ' ' + this.title_lowcase,
        text: this.$i18n.t(text) + ' ' + this.title_lowcase + '?',
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: active == 0 ? this.$i18n.t('deactivate') : this.$i18n.t('activate'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = { element: 'master/' + this.element_url, id: data.id, status: active }

          this.ChangeStatus(form)
            .then((response) => {
              if (response.code == 200) {
                data.active = active
                Swal.fire({
                  title: this.title_midcase + " " + this.$i18n.t(title2).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              else {
                console.log(response);
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                })
              }
            })
            .catch((error) => {
              console.log(error);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            });
        }
      });
    },
    Delete(row) {
      Swal.fire({
        title: this.$i18n.t('delete') + ' ' + this.title_lowcase,
        text: this.$i18n.t('delete_question'+this.title_gender_letter) + '? ' + this.$i18n.t('delete_questionx'+this.title_gender_letter),
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#6a71ec",
        cancelButtonColor: "#000000",
        confirmButtonText: this.$i18n.t('delete'),
        cancelButtonText: this.$i18n.t('cancel'),
      }).then((result) => {
        if (result.value) {
          let form = { element: 'master/' + this.element_url, id: row.item.id, status: 2 }

          this.ChangeStatus(form)
            .then((response) => {
              if (response.code == 200) {
                //this.AllElements()
                this.elementsTMP.splice(row.index,1)
                this.total--

                Swal.fire({
                  title: this.title_midcase + ' ' + this.$i18n.t('deleted'+this.title_gender_letter).toLowerCase(),
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              else {
                console.log(response);
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                });
                Toast.fire({
                  icon: "error",
                  title: this.$i18n.t('an_error_has_occurred'),
                })
              }
            })
            .catch((error) => {
              console.log(error);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              });
              Toast.fire({
                icon: "error",
                title: this.$i18n.t('an_error_has_occurred'),
              })
            });
        }
      });
    },
  },
}

</script>

<style scoped>

.table thead th {
  cursor: pointer;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #6a71ec !important;
  border-color: #6a71ec !important;
}

.header, .table thead th, thead, th {
  background-color: #6a71ec !important;
  color: #FFFFFF;
}

</style>

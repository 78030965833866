<template>
  <div id="JeviConfig">
    <br />
    <h1 class="text2" style="color: #000000; margin-left: 10px; text-align: left;">
      <strong>{{ title_uppercase }}</strong>
    </h1>
    <div class="content">
      <b-card style="border: none; background-color: #F2F2F2">
        <b-row v-if="isBusy2" class="mt-3" align-h="center">
          <b-spinner class="m-1" style="width: 3rem; height: 3rem; font-size: 20px; color: #6a71ec">
          </b-spinner>
        </b-row>

        <b-row v-else align-v="center">
          <b-col lg="12" class="mb-2">
            <h3 class="text-left">{{ $t('min_reports') }}</h3>
          </b-col>

          <b-col lg="6" class="mb-2 text-left">
            <b-form-group :label="$t('for_users') + ':'" class="mb-0">
              <b-form-input type="number" v-model="element.user_min_reports" :placeholder="$t('for_users')"
                style="border-radius: 20px; color: #000000;" required @keyup.enter="UpdateElement()">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="6" class="mb-2 text-left">
            <b-form-group :label="$t('for_posts') + ':'" class="mb-0">
              <b-form-input type="number" v-model="element.post_min_reports" :placeholder="$t('for_posts')"
                style="border-radius: 20px; color: #000000;" required @keyup.enter="UpdateElement()">
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="12" class="mb-2">
            <h3 class="text-left">{{ $t('verification_config') }}</h3>
          </b-col>

          <b-col lg="12" class="mb-2 text-left">
            <b-form-group :label="$t('min_followers_to_request_verification') + ':'" class="mb-0">
              <b-form-input type="number" v-model="element.min_verification_followers"
                :placeholder="$t('min_followers_to_request_verification')" style="border-radius: 20px; color: #000000;"
                required @keyup.enter="UpdateElement()">
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="12" class="mb-2">
            <h3 class="text-left">{{ $t('trending_posts_config') }}</h3>
          </b-col>

          <b-col lg="6" class="mb-2 text-left">
            <b-form-group :label="$t('min_post_views_trending') + ':'" class="mb-0">
              <b-form-input type="number" v-model="element.min_post_views_trending"
                :placeholder="$t('min_post_views_trending')" style="border-radius: 20px; color: #000000;" required
                @keyup.enter="UpdateElement()">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="6" class="mb-2 text-left">
            <b-form-group :label="$t('min_post_likes_trending') + ':'" class="mb-0">
              <b-form-input type="number" v-model="element.min_post_likes_trending"
                :placeholder="$t('min_post_likes_trending')" style="border-radius: 20px; color: #000000;" required
                @keyup.enter="UpdateElement()">
              </b-form-input>
            </b-form-group>
          </b-col>

          <!--<b-col lg="12" class="mb-2">
            <h3 class="text-left">{{ $t('public_rooms') }}</h3>
          </b-col>
          <b-col lg="6" class="mb-2 text-left">
            <b-form-group :label="$t('near_me_max_km')+':'" class="mb-0">
              <b-form-input type="number" v-model="element.near_me_max_km" :placeholder="$t('near_me_max_km')"
                style="border-radius: 20px; color: #000000;" required @keyup.enter="UpdateElement()">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="6" class="mb-2 text-left">
            <b-form-group :label="$t('banning_percentage')+':'" class="mb-0">
              <b-form-input type="number" v-model="element.public_room_banning_percentage"
                :placeholder="$t('banning_percentage')" style="border-radius: 20px; color: #000000;" required
                @keyup.enter="UpdateElement()">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="6" class="mb-2 text-left"></b-col>-->
          <br />
          <b-col lg="8"></b-col>
          <b-col lg="4" v-show="!progress">
            <b-button block pill class="my-3" @click="UpdateElement()"
              style="color: #FFFFF; font-size: 20px; border-color: #6a71ec; background-color: #6a71ec">
              {{ $t('update').toUpperCase() }}
            </b-button>
          </b-col>
          <b-col lg="4" v-show="progress">
            <div class="text-center my-3" style="color: #6a71ec">
              <b-spinner small class="align-middle"></b-spinner>
              <strong class="ml-2">{{ $t('loading') }}...</strong>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>

import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "MasterElements",

  data() {
    return {
      element: {},
      element_url: "jevi_config",
      title_gender_letter: "2",
      title_gender_letter2: "2",
      element_update: {},
      progress: false,
      isBusy: false,
      isBusy2: false,
    };
  },
  computed: {
    title_plural_uppercase() {
      return this.$i18n.t(this.element_url + '_plural').toUpperCase()
    },
    title_uppercase() {
      return this.$i18n.t(this.element_url).toUpperCase()
    },
    title_plural_midcase() {
      return this.$i18n.t(this.element_url + '_plural')
    },
    title_midcase() {
      return this.$i18n.t(this.element_url)
    },
    title_plural_lowcase() {
      return this.$i18n.t(this.element_url + '_plural').toLowerCase()
    },
    title_lowcase() {
      return this.$i18n.t(this.element_url).toLowerCase()
    }
  },
  mounted() {
    this.GetElement();
  },
  methods: {
    ...mapActions(["GetByID", "Update"]),
    format_num(num) {
      if (num >= 1000 && num < 1000000) {
        return (num / 1000).toFixed(2) + 'K'
      }
      else if (num >= 1000000) {
        return (num / 1000000).toFixed(2) + 'M'
      }
      else {
        return num
      }
    },
    GetElement() {
      this.isBusy2 = true

      let form = {
        element: this.element_url,
        id: 1
      };

      this.GetByID(form)
        .then((response) => {
          if (response.code == 200) {
            this.isBusy = false;
            this.isBusy2 = false;
            this.element = response.data;
          } else {
            console.log(response);
            this.isBusy = false;
            this.isBusy2 = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          this.isBusy2 = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
    UpdateElement() {
      const validation =
        this.element.user_min_reports <= 0 ||
        this.element.post_min_reports <= 0 ||
        this.element.min_verification_followers <= 0 ||
        this.element.min_post_views_trending <= 0 ||
        this.element.min_post_likes_trending <= 0 ||
        //this.element.near_me_max_km <= 0 ||
        this.element.public_room_banning_percentage <= 0

      if (validation) {
        Swal.fire({
          title: this.$i18n.t('fill_fields'),
          text: this.$i18n.t('fill_all_fields'),
          confirmButtonColor: "#6a71ec",
          icon: "warning",
        });
        return;
      }

      this.progress = true;

      let form = {
        element: this.element_url,
        id: this.element.id,
        update: "update",
        data: this.element,
      };

      this.Update(form)
        .then((response) => {
          if (response.code == 200) {
            this.progress = false;

            Swal.fire({
              title:
                this.title_midcase + ' ' + this.$i18n.t('updated' + this.title_gender_letter).toLowerCase(),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.progress = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              icon: "error",
              title: this.$i18n.t('an_error_has_occurred'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.progress = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            icon: "error",
            title: this.$i18n.t('an_error_has_occurred'),
          });
        });
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #6a71ec !important;
  border-color: #6a71ec !important;
}

.header,
.table thead th,
thead,
th {
  background-color: #6a71ec !important;
  color: #ffffff;
}

.selector-class .vs__search::placeholder,
.selector-class .vs__dropdown-toggle {
  border-radius: 20px;
  height: 40px;
}

.selector-class .vs__dropdown-menu {
  border-color: #000000;
  border-radius: 0px;
  max-height: 200px;
  overflow-y: auto;
}

.selector-class .vs__dropdown-option--highlight {
  background-color: #6a71ec;
}
</style>
